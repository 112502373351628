import React from 'react';
import HTML2React from 'html2react'
import { romanize } from '../../../helpers/formatters';

export const AdditionalInformation = props => {
    const { customRecord, customBasePath, translate, counter = 1 } = props;
    const record = customRecord;
    const basePath = customBasePath;

    return(
        <div className="Section2">
            <div className="content-area">
                <h4>{romanize(counter)}. {translate('printForm.project_info.additional_info')}</h4>
                {HTML2React(customRecord.additional_info)}
            </div>
        </div>
    )
};
