import React from 'react';
import HTML2React from 'html2react'
import { Table } from 'reactstrap';
import { romanize } from '../../../../helpers/formatters';

const StakeHolders = props => {
    const { customRecord, translate, subCounter } = props;
    const { options_appraisals } = customRecord;

    if (!options_appraisals) {
        return null
    }
    //stakeholder_evaluation
    return (
    <div className="Section2">
    <div className="content-area">
            <h4>{romanize(subCounter || 6)}.  {translate('printForm.options.stakeholder_evaluation.title')}</h4>
            {
                options_appraisals && options_appraisals
                    .filter((item) => item.is_shortlisted || item.is_best)
                    .map((option, idx) => {
                        return option.stakeholder_evaluation && option.stakeholder_evaluation.length === 0
                            ? 
                            <div>
                                <p><strong>{`${translate('printForm.options.stakeholder_evaluation.analysis')} ${option.title}:`}</strong></p>
                                <p>{translate('printForm.options.stakeholder_evaluation.empty')}</p>
                            </div>
                            : <Table responsive bordered>
                                <thead>
                                    <tr className="filled-row">
                                        <th>{translate('printForm.options.stakeholder_evaluation.number')}</th>
                                        <th>{translate('printForm.options.stakeholder_evaluation.name')}</th>
                                        <th>{translate('printForm.options.stakeholder_evaluation.impact_sign')}</th>
                                        <th>{translate('printForm.options.stakeholder_evaluation.beneficiary')}</th>
                                        <th>{translate('printForm.options.stakeholder_evaluation.description')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        option.stakeholder_evaluation &&
                                        option.stakeholder_evaluation.map((item, idx) =>
                                            <tr>
                                                <td>{romanize(idx + 1)}</td>
                                                <td>{item.title}</td>
                                                <td>{item.impact_sign}</td>
                                                <td>{item.beneficiary}</td>
                                                <td>{HTML2React(item.description)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                    })
                }
        </div>
        </div>
    )
};

export default StakeHolders;