import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    number,
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';

export const FundCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText={props.translate('tooltips.resources.funds.fields.code')}>
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.funds.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const FundEdit = props => (
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText={props.translate('tooltips.resources.funds.fields.code')}>
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.funds.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const FundShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const FundShow = ({ translate, ...props }) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.funds.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<FundShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="code" />
                    <TextField source="name" />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

export const FundList = props => {
    return (
        <List {...props} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default FundList;
