import React, { Fragment, Component } from 'react';
import lodash from 'lodash';
import dataProvider from '../../providers/dataProvider';

class ImplementingAgencies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            references: {
                votes: [],
                sectors: []
            }
        }
    }

    componentDidMount() {
        dataProvider('GET_MANY', 'sectors', { ids: [] })
            .then((response) => {
                this.setState({ references: {
                    ...this.state.references,
                    'sectors': response.data
                }})
            });
        dataProvider('GET_MANY', 'votes', { ids: [] })
            .then((response) => {
                this.setState({ references: {
                    ...this.state.references,
                    'votes': response.data,
                }})
            })
    }

    getSelectedItem = (items, elementId) => {
        const selected = lodash.find(items, (item) => 
            item.id === elementId);
        
        return selected ? `${selected.code} - ${selected.name}` : null; 
    }

    render () {
        const { implementing_agencies } = this.props.projectDetail;
        const { sectors, votes } = this.state.references;
        
        return (
                <Fragment>
                    <div>
                        {
                            implementing_agencies.map((item, idx) => {
                                return (
                                    <Fragment>
                                        <p>{this.getSelectedItem(sectors, item.sector_id)}</p>
                                        <p>{this.getSelectedItem(votes, item.vote_id)}</p>
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                </Fragment>
            );
    }
};

export default ImplementingAgencies;
