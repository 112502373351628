import {
    BulkDeleteButton,
    Button,
    CardActions,
    Create,
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    Filter,
    FilterButton,
    FunctionField,
    List,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    Responsive,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleList,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import { PAP_DEPARTMENT_USERS, PROJECT_STATUS, USER_ROLES, DEFAULT_SORTING } from '../../constants/common';
import ProjectDetails, { ProjectCreate, ProjectEdit } from './ProjectDetails';
import React, {Component, Fragment} from 'react';
import { dateFormatter, getCurrentPhaseId } from './helpers';

import CustomEditButton from './ToolBar/CustomEditButton';
import CustomInput from '../../components/CustomInput';
import CustomPrintButton from './ToolBar/CustomPrintButton'
import CustomShowButton from './ToolBar/CustomShowButton';
import { Link } from 'react-router-dom';
import RevertButton from './ToolBar/RevertButton';
import TimeLineButton from './ToolBar/TimeLineButton'
import WorkflowActions from './ToolBar/WorkFlowActions'
import { linkToRecord } from 'ra-core';
import lodash from 'lodash';

const ProjectsFilter = props => (
    <Filter {...props}>
        <TextInput label="Project Number" source="project_no" alwaysOn />
        <TextInput label="Project Title" source="title" alwaysOn />
       
        <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="sector_id" reference="sectors" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="department_id" reference="departments" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="vote_id" reference="votes" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="phase_id" reference="phases" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" choices={[
                { id: 'draft', name: 'Draft' },
                { id: 'submitted', name: 'Submitted' },
                { id: 'revised', name: 'Revised' },
                { id: 'approved', name: 'Approved' },
                { id: 'rejected', name: 'Rejected' },
            ]}
        />
    </Filter>
);

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const checkUserEditRules = (permissions, data) => {
    const role = permissions && JSON.parse(permissions.role);
    const user_id = permissions && JSON.parse(permissions.user_id);

    return (user_id && Number(user_id) === Number(data.created_by)) || 
        PAP_DEPARTMENT_USERS.includes(lodash.first(lodash.keys(role)))
}

const userPermissionCheck = (permissions, data) => {
    const role = permissions && JSON.parse(permissions.role);

    return !lodash.isEmpty(data) && 
        (data && (data.status !== 'rejected' && data.phase_id <= 5) && 
            (data && data.workflow && (
                    data.workflow.source_role === lodash.first(lodash.keys(role)) || 
                        (lodash.first(lodash.keys(role)) === USER_ROLES.ADMIN) //remove approvals for admin if need
                )
            ))
}

const revertPermissionCheck = (permissions, data) => {
    const role = permissions && JSON.parse(permissions.role);
    return (!lodash.isEmpty(data) && data.status === 'rejected') && 
        (lodash.first(lodash.keys(role)) === 'admin')
}

const lastPhaseActiveChecking = (data) => {
    if (!lodash.isEmpty(data)) {
        const phaseFromPath = Number(window.location.href[window.location.href.length - 1]);
        if (phaseFromPath === getCurrentPhaseId(data)) {
            return true;
        }
    }

    return false;
}

const ProjectShowActions = ({ permissions, data, translate, location, ...rest}) => {
    const role = permissions && JSON.parse(permissions.role);
    const user_id = permissions && JSON.parse(permissions.user_id);
    const completed = data && data.status === PROJECT_STATUS.STATUS_IS_COMPLETED;
    const currentTabId = location && location.pathname[location.pathname.length - 1];
    
    if (
        !lodash.isEmpty(data) && completed
    ) {
        return (
            <CardActions style={cardActionStyle}>
                <CustomPrintButton 
                    printId="docx" 
                    record={data} 
                    isEditUser={checkUserEditRules(permissions, data)}
                    phase={translate(`resources.phases.phase_${currentTabId}`)}
                />
                <TimeLineButton record={data} {...rest} />
            </CardActions>
        );
    }

    if (
        !lodash.isEmpty(data) && !lastPhaseActiveChecking(data)
    ) {
        return (
            <CardActions style={cardActionStyle}>
                <CustomPrintButton 
                    printId="docx" 
                    record={data} 
                    isEditUser={checkUserEditRules(permissions, data)}
                    phase={translate(`resources.phases.phase_${currentTabId}`)}
                />
                <TimeLineButton record={data} {...rest} />
            </CardActions>
        );
    }

    return (
        <CardActions style={cardActionStyle}>        
        {
            !lodash.isEmpty(data) && !lodash.isEmpty(role) &&                 
                lodash.first(lodash.keys(role)) !== USER_ROLES.ADMIN &&
                    (data.status === "draft" || data.status === "revised") &&
                         (user_id && Number(user_id) === Number(data.created_by)) &&
                    <CustomEditButton basePath="/project-details" id={data.project_detail_id}/>
        }
        {
            !lodash.isEmpty(data) &&
                <CustomPrintButton 
                    printId="docx" 
                    record={data} 
                    isEditUser={checkUserEditRules(permissions, data)}
                    phase={translate(`resources.phases.phase_${currentTabId}`)}
                />
        }
        {
           userPermissionCheck(permissions, data) && 
                <WorkflowActions record={data} {...rest}/>
        }
        {
           revertPermissionCheck(permissions, data) && 
                <RevertButton record={data} {...rest}/>
        }
        
        <TimeLineButton record={data} {...rest}/>
        </CardActions>
    );
};

const ProjectListActions = ({ permissions, basePath, data, resource, showFilter, filters, displayedFilters, filterValues, exporter }) => {
    const role = permissions && JSON.parse(permissions.role);
    const roleType = lodash.first(lodash.keys(role));
    
    return (
        <CardActions>
            {
                role && (roleType === USER_ROLES.SU || roleType === USER_ROLES.ADMIN) &&
                <CreateButton basePath="project-details" />
            }
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            }) }
        </CardActions>
    );
};

export const ProjectShow = ({ permissions, ...props }) => {
    return (
        <Show {...props} actions={<ProjectShowActions {...props} permissions={permissions}/>}>
            <ReferenceManyField
                addLabel={false}
                reference="project-details"
                target="project_id"
            >
                <ProjectDetails {...props} />
            </ReferenceManyField>
        </Show>
    );
};

export const ProjectList = ({ permissions, ...props }) => {
    const role = permissions && JSON.parse(permissions.role);
    const roleType = lodash.first(lodash.keys(role));

    const renderStatus = (record) => {
        let workflowStatus = record.workflow.status;
        
        if ( getCurrentPhaseId(record) !== record.phase_id){
            workflowStatus = `${props.translate(`timeline.hidden_phase_status`)} ${props.translate(`resources.phases.phase_${getCurrentPhaseId(record)}`)}`;
        }

        if (record.workflow.in_pap && !PAP_DEPARTMENT_USERS.includes(roleType)) {
            workflowStatus = props.translate(`timeline.not_pap_status`) 
        }
            
        return record.status === 'rejected' || record.status === 'completed' 
            ? props.translate(`timeline.${record.status}`) 
            : workflowStatus
    }

    return (
        <List
            {...props}
            actions={<ProjectListActions permissions={permissions}/>}
            filters={<ProjectsFilter />}
            bulkActions={false}
        >
            <Responsive
                small={
                    <SimpleList
                        linkType={false}
                        primaryText={record => 
                            <Link
                                to={`${linkToRecord('projects', record.id)}/show/${record.phase_id}`}
                            >
                                <b>{record.title}</b>
                            </Link>}
                        secondaryText={record => {
                            return (
                                <span className="small-list-item">
                                    <span>{props.translate(`resources.projects.fields.project_no`)}: {`${record.project_no}`}</span>
                                    <span>{props.translate(`resources.projects.fields.created_at`)}: {`${dateFormatter(record.created_at)}`}</span>
                                    <span>{props.translate(`resources.projects.fields.phase_id`)}: {props.translate(`resources.phases.phase_${record.phase_id}`)}</span>
                                    <span>{props.translate(`resources.projects.fields.status`)}: {props.translate(`timeline.${record.status}`)}</span>
                                    <span><b>{renderStatus(record)}</b></span>
                                </span>
                            )
                        }}
                    />
                }
                medium={
                    <Datagrid>
                        <TextField source="project_no" />
                        <TextField source="title" />
                        <FunctionField
                            source="created_at"
                            label={props.translate(
                                `resources.projects.fields.created_at`,
                            )}
                            render={record => dateFormatter(record.created_at)}
                        />
                        <FunctionField
                            source="status"
                            label={props.translate(
                                `resources.projects.fields.status`,
                            )}
                            render={record =>
                                props.translate(`timeline.${record.status}`)
                            }
                        />
                        <ReferenceField
                            addLabel={false}
                            source="phase_id"
                            reference="phases"
                            linkType={null}
                        >
                            <FunctionField
                                label={props.translate(
                                    `resources.projects.fields.phase_id`,
                                )}
                                render={record =>
                                    props.translate(
                                        `resources.phases.phase_${record.id}`,
                                    )
                                }
                            />
                        </ReferenceField>
                        <FunctionField
                            source="current_step"
                            label={props.translate(
                                `resources.projects.fields.workflow.status`,
                            )}
                            render={record =>
                                renderStatus(record)
                            }
                        />
                        <CustomShowButton basePath="/projects" />
                    </Datagrid>
                }
            />
        </List>
    )
}

export {
    ProjectCreate,
    ProjectEdit,
};
export default ProjectList;
