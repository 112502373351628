import React from 'react';
import { Table } from 'reactstrap';
import HTML2React from 'html2react'
import { romanize } from '../../../../helpers/formatters';

const RiskManagement = props => {
    const { customRecord, translate, subCounter } = props;
        const { options_appraisals } = customRecord;

        if (!options_appraisals) {
            return null
        }

        return(
        <div className="Section2">
            <div className="content-area">
                <h4>{romanize(subCounter || 7)}.  {translate('printForm.options.risk_evaluation.title')}</h4>
                {
                    options_appraisals && options_appraisals
                        .filter((item) => item.is_shortlisted || item.is_best)
                        .map((option, idx) => {
                            return (
                                <div>
                                    <p><strong>{`${translate('printForm.options.risk_evaluation.analysis')} ${option.title}:`}</strong></p>
                                    {
                                        !option.risk_evaluation || (option.risk_evaluation && option.risk_evaluation.length === 0)
                                            ? <p>{translate('printForm.options.risk_evaluation.empty')}</p>
                                            : <Table responsive bordered>
                                                <thead>
                                                    <tr className="filled-row">
                                                        <th>{translate('printForm.options.risk_evaluation.description')}</th>
                                                        <th>{translate('printForm.options.risk_evaluation.occurrence')}</th>
                                                        <th>{translate('printForm.options.risk_evaluation.impact')}</th>
                                                        <th>{translate('printForm.options.risk_evaluation.mitigation_plan')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        option.risk_evaluation &&
                                                        option.risk_evaluation.map((riskItem) =>
                                                            <tr>
                                                                <td>{riskItem.description}</td>
                                                                <td>{riskItem.occurrence}</td>
                                                                <td>{riskItem.impact}</td>
                                                                <td>{riskItem.mitigation_plan}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                        )})
                }
            </div>
            </div>
        )
};

export default RiskManagement;
