import { Button } from 'react-admin';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { getCurrentPhaseId } from '../helpers';
import { linkToRecord } from 'ra-core';

const stopPropagation = e => e.stopPropagation();

const CustomShowButton = ({
    basePath = '',
    label = 'ra.action.show',
    record = {},
    icon = <ImageEye />,
    id = '',
    ...rest
}) => {
    let phaseLink = getCurrentPhaseId(record)

    if (!phaseLink || phaseLink <= 0) {
        phaseLink = record.phase_id;
    }
    
    return (
        <Button
            component={Link}
            to={linkToRecord(basePath, record.id) + "/show/" + phaseLink  }
            label={label}
            onClick={stopPropagation}
            {...rest}
        >
            {icon}
        </Button>
    )
};

CustomShowButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
    id: PropTypes.number,
};

export default CustomShowButton;
