import { Card, CardHeader } from 'reactstrap';
import {
    CardActions,
    ChipField,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    FunctionField,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    SelectArrayInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    email,
    number,
    required,
    downloadCSV,
    
} from 'react-admin';

import { DONORS_FUNDS_IDS, USER_ROLES, DEFAULT_SORTING } from '../../constants/common';
import React, { Fragment } from 'react';
import { minLength, regex } from 'ra-core';
import { unregisterField } from 'redux-form';
import CustomInput from '../../components/CustomInput';
import UserManagmentShowActions from './ShowActions'
import { connect } from 'react-redux';
import dataProvider from '../../providers/dataProvider';
import lodash from 'lodash';
import { optionRendererCodeName } from '../../helpers/formatters';
import { exporter } from './helper';
import DeleteButtonWithConfirmation from '../../components/deleteWithConfirmation';

const usernameValidation = [
    minLength(3), 
    regex(/^\S*$/, 'No spaces!'), 
    required()];

const mapStateToProps = state => ({
    isLoading: state.admin.loading,
    possibleValues: state.admin.references.possibleValues
});
    
export const UserManagmentCreate = connect(mapStateToProps, {unregisterField})(props => {
    const { permissions, translate } = props;

    const optionRenderer = choice =>
        choice ? translate(`resources.roles.${choice.name}`) : null;

    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <Fragment>
                    <DisabledInput source="id" />
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.username',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.username')}
                            source="username"
                            validate={usernameValidation}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.email',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.email')}
                            source="email"
                            validate={[required(), email()]}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.fullname',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.fullname')}
                            source="fullname"
                            validate={[required()]}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.phone',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.phone')}
                            source="phone"
                            validate={[required(), number()]}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.role',
                        )}
                    >
                        <ReferenceInput
                            perPage={-1}
                            source="role"
                            reference="roles"
                            validate={required()}
                            sort={DEFAULT_SORTING}
                        >
                            <SelectInput
                                label={translate('resources.users.fields.role')}
                                options={{ fullWidth: 'true' }}
                                optionText={optionRenderer}
                            />
                        </ReferenceInput>
                    </CustomInput>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData, formData, ...rest }) => {
                            const deskOfficerUserFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.sector_ids',
                                    )}
                                >
                                    <ReferenceArrayInput
                                        label={translate(
                                            'resources.users.fields.sector_ids',
                                        )}
                                        perPage={-1}
                                        source="sector_ids"
                                        reference="sectors"
                                        sort={DEFAULT_SORTING}
                                        validate={required()}
                                    >
                                        <SelectArrayInput
                                            optionText="name"
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </ReferenceArrayInput>
                                </CustomInput>,
                            ];
                            const donorUserFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.donor_id',
                                    )}
                                >
                                    <ReferenceInput
                                        filter={{ fund_id: DONORS_FUNDS_IDS }}
                                        label={translate(
                                            'resources.users.fields.donor_id',
                                        )}
                                        perPage={-1}
                                        source="donor_id"
                                        sort={DEFAULT_SORTING}
                                        reference="fund-sources"
                                        validate={required()}
                                    >
                                        <SelectInput
                                            optionText={optionRendererCodeName}
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0  }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                            ];
                            const othersFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.sector_id',
                                    )}
                                >
                                    <ReferenceInput
                                        label={translate(
                                            'resources.users.fields.sector_id',
                                        )}
                                        perPage={-1}
                                        source="sector_id"
                                        sort={DEFAULT_SORTING}
                                        reference="sectors"
                                        validate={required()}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.vote_id',
                                    )}
                                >
                                    <ReferenceInput
                                        perPage={-1}
                                        source="vote_id"
                                        sort={DEFAULT_SORTING}
                                        reference="votes"
                                        resource="sectors"
                                        validate={required()}
                                        filter={{
                                            sector_id:
                                                (formData &&
                                                    formData.sector_id) ||
                                                null,
                                        }}
                                        label={translate(
                                            'resources.users.fields.vote_id',
                                        )}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0  }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.department_id',
                                    )}
                                >
                                    <ReferenceInput
                                        perPage={-1}
                                        source="department_id"
                                        reference="departments"
                                        resource="votes"
                                        sort={DEFAULT_SORTING}
                                        validate={required()}
                                        filter={{
                                            vote_id:
                                                (formData &&
                                                    formData.vote_id) ||
                                                null,
                                        }}
                                        label={translate(
                                            'resources.users.fields.department_id',
                                        )}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0  }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                            ];
                            
                            if (formData && props.possibleValues['sectors@vote_id'] && 
                                    !props.possibleValues['sectors@vote_id'].includes(formData.vote_id)){
                                delete formData.vote_id;
                                delete formData.department_id;
                            }
                            if (formData && props.possibleValues['votes@department_id'] && 
                                    !props.possibleValues['votes@department_id'].includes(formData.department_id)){
                                delete formData.department_id;
                            }

                            if (
                                formData &&
                                formData.role !== USER_ROLES.DOU &&
                                formData.role !== USER_ROLES.DU
                            ) {
                                formData.sector_ids = null;
                                return <Fragment>{othersFields}</Fragment>;
                            }

                            return (
                                <Fragment>
                                    {formData &&
                                    formData.role === USER_ROLES.DOU
                                        ? deskOfficerUserFields
                                        : donorUserFields}
                                </Fragment>
                            );
                        }}
                    </FormDataConsumer>
                </Fragment>
            </SimpleForm>
        </Create>
    );
});

export const UserManagmentEdit = connect(mapStateToProps, null)(props => {
    const { permissions, translate } = props;
    const role = permissions && JSON.parse(permissions.role);
    const userId = permissions && JSON.parse(permissions.user_id);
    const roleType = role && lodash.first(lodash.keys(role));
    const optionRenderer = choice =>
        choice ? translate(`resources.roles.${choice.name}`) : null;

    return (
        <Edit {...props} actions={false}>
            <SimpleForm redirect="show">
                <Fragment>
                    <DisabledInput source="id" />
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.username',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.username')}
                            source="username"
                            validate={usernameValidation}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.email',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.email')}
                            source="email"
                            validate={[required(), email()]}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.fullname',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.fullname')}
                            source="fullname"
                            validate={[required()]}
                        />
                    </CustomInput>
                    <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.phone',
                        )}
                    >
                        <TextInput
                            label={translate('resources.users.fields.phone')}
                            source="phone"
                            validate={[required(), number()]}
                        />
                    </CustomInput>
                    {/* <CustomInput
                        tooltipText={translate(
                            'tooltips.resources.users.fields.role',
                        )}
                    >
                        <ReferenceInput
                            perPage={-1}
                            source="role"
                            reference="roles"
                            validate={required()}
                        >
                            <SelectInput
                                label={translate('resources.users.fields.role')}
                                options={{ fullWidth: 'true' }}
                                optionText={optionRenderer}
                            />
                        </ReferenceInput>
                    </CustomInput> */}
                    <FormDataConsumer>
                        {({ getSource, scopedFormData, formData, ...rest }) => {
                            const deskOfficerUserFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.sector_ids',
                                    )}
                                >
                                    <ReferenceArrayInput
                                        label={translate(
                                            'resources.users.fields.sector_ids',
                                        )}
                                        perPage={-1}
                                        source="sector_ids"
                                        reference="sectors"
                                        validate={required()}
                                        sort={DEFAULT_SORTING}
                                    >
                                        <SelectArrayInput
                                            optionText="name"
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </ReferenceArrayInput>
                                </CustomInput>,
                            ];
                            const donorUserFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.donor_id',
                                    )}
                                >
                                    <ReferenceInput
                                        filter={{ fund_id: DONORS_FUNDS_IDS }}
                                        label={translate(
                                            'resources.users.fields.donor_id',
                                        )}
                                        perPage={-1}
                                        sort={DEFAULT_SORTING}
                                        source="donor_id"
                                        reference="fund-sources"
                                        validate={required()}
                                    >
                                        <SelectInput
                                            optionText={optionRendererCodeName}
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0 }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                            ];
                            const othersFields = [
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.sector_id',
                                    )}
                                >
                                    <ReferenceInput
                                        label={translate(
                                            'resources.users.fields.sector_id',
                                        )}
                                        perPage={-1}
                                        sort={DEFAULT_SORTING}
                                        source="sector_id"
                                        reference="sectors"
                                        validate={required()}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.vote_id',
                                    )}
                                >
                                    <ReferenceInput
                                        perPage={-1}
                                        source="vote_id"
                                        sort={DEFAULT_SORTING}
                                        reference="votes"
                                        resource="sectors"
                                        validate={required()}
                                        filter={{
                                            sector_id:
                                                (formData &&
                                                    formData.sector_id) ||
                                                null,
                                        }}
                                        label={translate(
                                            'resources.users.fields.vote_id',
                                        )}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0 }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                                <CustomInput
                                    tooltipText={translate(
                                        'tooltips.resources.users.fields.department_id',
                                    )}
                                >
                                    <ReferenceInput
                                        perPage={-1}
                                        sort={DEFAULT_SORTING}
                                        source="department_id"
                                        reference="departments"
                                        resource="votes"
                                        validate={required()}
                                        filter={{
                                            vote_id:
                                                (formData &&
                                                    formData.vote_id) ||
                                                null,
                                        }}
                                        label={translate(
                                            'resources.users.fields.department_id',
                                        )}
                                    >
                                        <SelectInput
                                            optionText="name"
                                            options={{ fullWidth: 'true', disabled: props.isLoading !== 0 }}
                                        />
                                    </ReferenceInput>
                                </CustomInput>,
                            ];
                           
                            if (formData && props.possibleValues['sectors@vote_id'] && 
                                    !props.possibleValues['sectors@vote_id'].includes(formData.vote_id)){
                                delete formData.vote_id;
                                delete formData.department_id;
                            }
                            if (formData && props.possibleValues['votes@department_id'] && 
                                    !props.possibleValues['votes@department_id'].includes(formData.department_id)){
                                delete formData.department_id;
                            }

                            if (
                                formData &&
                                formData.role !== USER_ROLES.DOU &&
                                formData.role !== USER_ROLES.DU
                            ) {
                                formData.sector_ids = null;
                                formData.donor_id = null;
                                return <Fragment>{othersFields}</Fragment>;
                            } else {
                                if (formData) {
                                    if (formData.sector_ids && (formData.sector_ids.length === 0 || !lodash.first(formData.sector_ids))) {
                                        formData.sector_ids = null;
                                    }
                                }
                            }

                            return (
                                <Fragment>
                                    {formData &&
                                    formData.role === USER_ROLES.DOU
                                        ? deskOfficerUserFields
                                        : donorUserFields}
                                </Fragment>
                            );
                        }}
                    </FormDataConsumer>
                    {
                        Number(props.id) === Number(userId) &&   
                            <CustomInput
                                tooltipText={translate(
                                    'tooltips.resources.users.fields.password_hash',
                                )}
                            >
                                <TextInput
                                    label={translate(
                                        'resources.users.fields.password_hash',
                                    )}
                                    source="password_hash"
                                    type="password"
                                />
                            </CustomInput>
                    }
                </Fragment>
            </SimpleForm>
        </Edit>
    );
});

export const UserManagmentShow = (props) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{props.translate('users.name')}</strong>
            </CardHeader>
            <Show actions={<UserManagmentShowActions {...props}/>} {...props}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="username" />
                    <TextField source="email" />
                    <DateField source="created_at" />
                    <DateField source="updated_at" />
                    <TextField source="fullname" />
                    <TextField source="phone" />
                    <FunctionField
                        label={props.translate(
                            'resources.users.fields.vote_id',
                        )}
                        render={record =>
                            record && record.vote_id && record.vote
                                ? `${record.vote.code} - ${record.vote.name}`
                                : '-'
                        }
                    />
                    <FunctionField
                        label={props.translate(
                            'resources.users.fields.department_id',
                        )}
                        render={record =>
                            record && record.department_id && record.department
                                ? `${record.department.code} - ${
                                      record.department.name
                                  }`
                                : '-'
                        }
                    />
                    <FunctionField
                        label={props.translate(
                            'resources.users.fields.donor_id',
                        )}
                        render={record =>
                            record && record.donor_id && record.donor
                                ? `${record.donor.code} - ${record.donor.name}`
                                : '-'
                        }
                    />

                    <FunctionField
                        label={props.translate('resources.users.fields.sector_id')}
                        render={record => {
                            if (record && record.sector_ids && record.sector_ids.length !== 0) {
                                if (record.sector_ids[0]) {
                                    return record && record.sectors && 
                                        record.sectors
                                            .map((item) => `${item.code} - ${item.name}`)
                                            .join(', ')
                                }
                               
                            }
                            if (record && record.sector_id) {
                                return record.sector && `${record.sector.code}-${record.sector.name}`
                            }
                        }
                        }
                    />

                    <FunctionField
                        label={props.translate('resources.users.fields.role')}
                        render={record =>
                            record &&
                                props.translate(`resources.roles.${record.role}`)
                        }
                    />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

const UserManagmentsFilter = props => (
    <Filter {...props}>
        <TextInput source="username" />
        <ReferenceInput
            perPage={-1}
            sort={DEFAULT_SORTING}
            source="vote_id"
            reference="votes"
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            perPage={-1}
            sort={DEFAULT_SORTING}
            source="department_id"
            reference="departments"
            allowEmpty
        >
            <SelectInput options={{ fullWidth: true }} optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            perPage={-1}
            sort={DEFAULT_SORTING}
            source="sector_id"
            reference="sectors"
            allowEmpty
        >
            <SelectInput options={{ fullWidth: true }} optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const UserManagmentList = props => {
    return (
        <List {...props} filters={<UserManagmentsFilter />} bulkActions={false} exporter={exporter(props.translate)}>
            <Datagrid>
                <TextField source="username" />
                <FunctionField
                    label={props.translate('resources.users.fields.vote_id')}
                    render={record =>
                        record && record.vote_id && record.vote
                            ? `${record.vote.code} - ${record.vote.name}`
                            : '-'
                    }
                />
                <FunctionField
                    label={props.translate(
                        'resources.users.fields.department_id',
                    )}
                    render={record =>
                        record && record.department_id && record.department
                            ? `${record.department.code} - ${
                                  record.department.name
                              }`
                            : '-'
                    }
                />
                <FunctionField
                    label={props.translate('resources.users.fields.role')}
                    render={record =>
                        record &&
                            props.translate(`resources.roles.${record.role}`)
                    }
                />
                <ShowButton />
                <EditButton />
                <DeleteButtonWithConfirmation undoable={true} />
            </Datagrid>
        </List>
    );
};

export default UserManagmentList;
