import React, { Component, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Notification, setSidebarVisibility, WithPermissions } from 'react-admin';
import AdminAppBar from './AdminAppBar';
import AdminTheme from './AdminTheme';
import {
    AppBreadcrumb,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react';
import routes from '../../routes';
import navigation from '../../_nav';
import lodash from 'lodash';

const titleStyle = {
    position: 'absolute',
    top: 0,
    left: '100px',
    margin: '12px',
    maxWidth: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: theme.spacing.unit * 3,
        marginTop: '4em',
        paddingLeft: 5,
    },
});

class AdminLayout extends Component {
    componentWillMount() {
        this.props.setSidebarVisibility(true);
    }

    loading = () => (
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );

    getFilteredMenu = (menuItems, userRole) => {
        const resultMenu = {};
       
        resultMenu.items = menuItems.items.filter((item) => item.access.includes(userRole));
        
        return resultMenu.items ? resultMenu : menuItems;
    }

    render() {
        const { children, classes, logout, open, title, permissions, formValues } = this.props;
        const routeProps = {
            location: {
                pathname: '',
            },
        };
        let projectTitle = formValues && formValues.title;
        
        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AdminAppBar title={title} open={open} logout={logout} />
                    <div className="app-body">
                        <AppSidebar fixed display="lg">
                            <AppSidebarHeader />
                            <AppSidebarForm />
                            <Suspense>
                                <WithPermissions
                                    render={({ permissions }) => {
                                        const role = permissions && JSON.parse(permissions.role);
                                        if ( role ) {
                                            return (
                                                <AppSidebarNav
                                                    navConfig={this.getFilteredMenu(navigation, lodash.keys(role)[0])}
                                                    {...routeProps}
                                                />
                                            )
                                        }

                                        return null;
                                    }}
                                />
                            </Suspense>
                            <AppSidebarFooter />
                            <AppSidebarMinimizer />
                        </AppSidebar>
                        <main className="main">
                            <div style={{ position: 'relative' }}>
                                <AppBreadcrumb appRoutes={routes} />
                                {
                                    projectTitle &&
                                    <h5 style={titleStyle}>{projectTitle}</h5>
                                }
                            </div>
                            <Container fluid>
                                <MuiThemeProvider theme={AdminTheme}>
                                    <Suspense fallback={this.loading()}>
                                        <Switch>{children}</Switch>
                                    </Suspense>
                                </MuiThemeProvider>
                            </Container>
                        </main>
                    </div>
                    <Notification />
                </div>
            </div>
        );
    }
}

AdminLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    setSidebarVisibility: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ 
    isLoading: state.admin.loading > 0,
    formValues: getFormValues('record-form')(state),
});
export default connect(
    mapStateToProps,
    { setSidebarVisibility },
)(withStyles(styles)(AdminLayout));
