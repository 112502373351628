import { EXPORT_TYPES } from "../../constants/common";
import dataProvider from "../../providers/dataProvider";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
    downloadCSV
} from 'react-admin';
import lodash from 'lodash';

export const exporter = (records, fetchRelatedRecords) => {
    const exportedRecords = lodash.cloneDeep(records);
    const data = exportedRecords.map((item) => {
        item.department = item.department ? `${item.department.code} - ${item.department.name}` : item.department;
        item.workflow = item.workflow ? item.workflow.status : '-';
         
        return item;
    })
    
    const csv = convertToCSV({
        data,
        fields: ['id', 'project_no', 'title', 'phase', 'created_by', 'created_at',
            'status', 'revised_by_role', 'department', 'workflow'
        ],
    });

    downloadCSV(csv, 'projects');
};

export function downloadCSVfile(csv, filename) {
    let csvFile;
    let downloadLink;

    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

export function exportTableToCSV(filename, tableId) {
    let csv = [];
    let rows = document.querySelectorAll(`#${tableId} tr`);
    
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        
        for (var j = 0; j < cols.length; j++) 
            row.push(cols[j].innerText);
        
        csv.push(row.join(";"));        
    }

    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
}

export function exportTable(filename, tableId, type) {
    const content = document.getElementById(tableId);
    const query = { 
        title: filename,
        content: content 
            ? `<div class="Section2"><div class="landscapeSection">${content.outerHTML}</div></div>` 
            : '',
    };

    if (type === EXPORT_TYPES.PDF) {
        query.format = EXPORT_TYPES.PDF;
    }
    
    dataProvider('EXPORT', 'reports', { query })
        .then((response) => {
            let printName = filename;
            let a = document.createElement("a");
            a.download = `${printName}.${type}`

            if (type === EXPORT_TYPES.PDF) {
                a.href = "data:application/octet-stream;base64," + response.data;
            } else {
                var blob = new Blob([response.data], { type:"text/plain" });
                var url = window.URL.createObjectURL(blob);
                a.href = url;
            }
           
            a.click();
            a.remove();
        })
        .catch((err) => {
            console.error(err);
        })
}