import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    number
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';
import { DEFAULT_SORTING } from '../../constants/common';

export const VoteCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.code')}>
                <TextInput source="code" validate={[required(), number()]}/>
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.name')}>
                <TextInput source="name" validate={required()}/>
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.sector_id')}>
                <ReferenceInput
                    sort={DEFAULT_SORTING}
                    perPage={-1}
                    source="sector_id"
                    reference="sectors"
                    validate={required()}
                >
                    <SelectInput optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceInput>
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const VoteEdit = props => (
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.code')}>
                <TextInput source="code" validate={[required(), number()]}/>
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.name')}>
                <TextInput source="name" validate={required()}/>
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.votes.fields.sector_id')}>
                <ReferenceInput
                    sort={DEFAULT_SORTING}
                    perPage={-1}
                    source="sector_id"
                    reference="sectors"
                    validate={required()}
                >
                    <SelectInput optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceInput>
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const VoteShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const VoteShow = ({ translate, ...props }) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.votes.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<VoteShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="code" />
                    <TextField source="name" />
                    <ReferenceField
                        source="sector_id"
                        reference="sectors"
                        linkType="show"
                    >
                        <FunctionField
                            label="Name"
                            render={record => `${record.code} - ${record.name}`}
                        />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

const VotesFilter = props => (
    <Filter {...props}>
        <TextInput label="Code" source="code" />
        <TextInput label="Name" source="name" />
        <ReferenceInput
            sort={DEFAULT_SORTING}
            perPage={-1}
            source="sector_id"
            reference="sectors"
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const VoteList = props => {
    return (
        <List {...props} filters={<VotesFilter />} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <ReferenceField
                    source="sector_id"
                    reference="sectors"
                    linkType="show"
                >
                    <FunctionField
                        label="Name"
                        render={record => `${record.code} - ${record.name}`}
                    />
                </ReferenceField>
                <ShowButton />
                <EditButton />
                {/* <DeleteButton  /> */}
            </Datagrid>
        </List>
    );
};

export default VoteList;
