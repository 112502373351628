// in src/comments/ApproveButton.js
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification, translate } from 'react-admin';
import { push } from 'react-router-redux';
import { Button } from 'react-admin';
import lodash from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowForward from '@material-ui/icons/ArrowForward';

import dataProvider from '../../../providers/dataProvider';
import { VALIDATION_FIELDS } from '../../../constants/common';

class RevertButton extends Component {
    state={
        isFetching: false,
        data: {},
        validationErrors: []
    }

    handleClick = () => {
        const { push, record, showNotification, action, data } = this.props;

        this.handleWorkFlowStep(action, record)
    }

    handleRefresh = () => {
        window.location.reload();
    }

    handleWorkFlowStep = (action, params) => {
        const requestParams = {
            data: {
                action: 'revert'
            },
            id: params.id,
        };

        dataProvider('UPDATE', 'projects', requestParams)
            .then((response) => {
                this.props.showNotification(this.props.translate('workflow.messages.status_change'));
                this.setState({ isFetching: false, showDialog: false });
                this.handleRefresh();
            })
            .catch((err) => {
                this.props.showNotification(this.props.translate('workflow.messages.status_not_change'), 'warning');
                this.setState({ isFetching: false, showDialog: false });
            });
    }

    render() {
        const text = this.state.isFetching 
            ?   <Fragment>
                    <CircularProgress size={25} thickness={2} />
                    <span style={{ marginLeft: '10px'}} >{"REVERT"}</span>    
                </Fragment>
            : "REVERT"
            const content = this.state.isFetching 
                ?   <CircularProgress size={25} thickness={2} />
                : <ArrowForward />
        return <Button label={"REVERT"} onClick={this.handleClick}>{content}</Button>;
    }
}

RevertButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default translate(connect(null, {
    showNotification,
    push,
})(RevertButton));