import React, { Fragment, Component }  from 'react';
import { 
    FormDataConsumer, 
    Labeled, 
    translate, 
    
} from 'react-admin';

import CustomInput from '../../../components/CustomInput';
import Button from '@material-ui/core/Button';
import CustomFileLoader from '../../../components/customFileLoader';
import CustomTextArea from '../../../components/customTextArea';

class AdditionalInfoForm extends Component {
    state = {
        attachments: 0
    }

    componentDidMount() {
        const attachments = this.props.record.files
            .filter((item) => item.related_field.indexOf("attachments") > -1)

        this.setState({
            attachments: attachments.length
        })
    }

    handleAddAttachment = () => {
        this.setState({
            attachments: this.state.attachments + 1
        })
    }

    handleDeleteAttachments = () => {
        this.setState({
            attachments: this.state.attachments - 1
        })
    }

    renderAdditionalAttachments = (formData) => {
        const attachments = [];

        for (let index = 0; index < this.state.attachments; index++) {
            attachments.push(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomFileLoader
                        relatedField={`attachments_${index}`}
                        entityType="project_detail"
                        entityId={formData && formData.id}
                        fileTypeId={0}
                        formData={formData}
                        onDeleteAttacments={this.handleDeleteAttachments}
                    />
                    <Button variant="contained" component="span" onClick={this.handleDeleteAttachments} > 
                        {this.props.translate('resources.project-details.fields.attachments.remove')}
                    </Button>
                </div>
            )
        }
        return attachments;
    }

    render(){
        return (
            <FormDataConsumer>
                {({ getSource, scopedFormData, formData, ...rest }) => {
                    return (
                        <Fragment>       
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.additional_info')} textArea>
                                <Labeled label={this.props.translate('resources.project-details.fields.additional_info')} />
                                <CustomTextArea  source="additional_info" {...this.props} />
                            </CustomInput>
                            <div>
                                <h5>{this.props.translate('resources.project-details.fields.attachments.title')}</h5>
                                {
                                    formData && formData.file_types &&
                                    formData.file_types.map((item) => 
                                        <div>
                                            <Labeled label={`${item.name} ${ item.is_required === 'yes' ? '*' : ''}`} />
                                            <CustomFileLoader
                                                relatedField={item.name}
                                                entityType="project_detail"
                                                entityId={formData && formData.id}
                                                fileTypeId={item.id}
                                                formData={formData}
                                                extensions={item.extensions}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <hr/>
                            <Button variant="contained" component="span" onClick={this.handleAddAttachment} > 
                                {this.props.translate('resources.project-details.fields.attachments.add')}
                            </Button>
                            <br/>
                            <br/>
                            {
                                this.state.attachments
                                    ?   this.renderAdditionalAttachments(formData)
                                    :   null
                            }
                        </Fragment>
                    );
                }}
            </FormDataConsumer>
        );
    }
    
};

export default translate(AdditionalInfoForm);
