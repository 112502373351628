import React, { Fragment, Component } from 'react';
import {
    ArrayInput,
    FormDataConsumer,
    SimpleFormIterator,
    Labeled,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    translate,
    SelectInput,
    Datagrid,
    TextField,
    required,
    minLength,
    number,
} from 'react-admin';
import lodash from 'lodash';
import ManageIndicatorsButton from './ManageIndicatorsButton';
import CustomInput from '../../../../components/CustomInput';
import CustomTextArea from '../../../../components/customTextArea';
import { IndicatorList } from '../../ProjectDetails';
// import { outputsValidation } from '../../validation';

import ManageInvestmentsButton from './ManageInvestmentsButton';
import Investments from '../../InvestmentList';

import dataProvider from '../../../../providers/dataProvider';

const optionRenderer = (choice) => {
    return choice
        ? `${
              choice.name.length > 100
                  ? choice.name.slice(0, 100) + '...'
                  : choice.name
          }`
        : null;
};
const getSelectedVotesForProject = (formData) => {
    const votesList =
        formData.vote &&
        lodash
            .concat(
                { id: formData.vote.id, name: formData.vote.name },
                formData.implementing_agencies &&
                    formData.implementing_agencies.map(
                        (element) => element.vote,
                    ),
            )
            .map((item) => {
                return { id: item.id, name: item.name };
            });
    return votesList || [];
};

class OutputsForm extends Component {
    state = {
        references: {},
    };

    componentDidMount() {
        dataProvider('GET_MANY', 'currency-rates', { ids: [] }).then(
            (response) => {
                this.setState({
                    references: {
                        ...this.state.references,
                        'currency-rates': lodash.sortBy(response.data, 'code'),
                    },
                });
            },
        );
    }

    handleShowIndicators = (visible) => {
        this.props.onShowPopup('outputIndicators', visible);
    }

    handleShowInvestments= (visible) => {
        this.props.onShowPopup('outputInvestments', visible);
    }

    render() {
        const { references } = this.state;
        const { translate } = this.props;

        return (
            <FormDataConsumer>
                {({ getSource, scopedFormData, formData, ...rest }) => {
                    return (
                        <Fragment>
                            <ArrayInput source="outputs" label={false}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({
                                            getSource,
                                            scopedFormData,
                                            formData,
                                            ...rest
                                        }) => (
                                            <Fragment>
                                                <div className="row">
                                                    <div className="col-sm-6 col-m-6">
                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.title',
                                                            )}
                                                        >
                                                            <TextInput
                                                                source={getSource(
                                                                    'title',
                                                                )}
                                                                label={translate(
                                                                    'resources.outputs.fields.title',
                                                                )}
                                                            />
                                                        </CustomInput>
                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.output_value',
                                                            )}
                                                        >
                                                            <TextInput
                                                                source={getSource(
                                                                    'output_value',
                                                                )}
                                                                label={translate(
                                                                    'resources.outputs.fields.output_value',
                                                                )}
                                                                validate={number()}
                                                            />
                                                        </CustomInput>
                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.output_unit',
                                                            )}
                                                        >
                                                            <TextInput
                                                                source={getSource(
                                                                    'output_unit',
                                                                )}
                                                                label={translate(
                                                                    'resources.outputs.fields.output_unit',
                                                                )}
                                                            />
                                                        </CustomInput>

                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.outcome_ids',
                                                            )}
                                                        >
                                                            <SelectArrayInput
                                                                options={{
                                                                    fullWidth:
                                                                        'true',
                                                                }}
                                                                label={translate(
                                                                    'resources.outputs.fields.outcome_ids',
                                                                )}
                                                                source={getSource(
                                                                    'outcome_ids',
                                                                )}
                                                                optionText={
                                                                    optionRenderer
                                                                }
                                                                choices={
                                                                    formData &&
                                                                    formData.outcomes
                                                                        ? formData.outcomes.map(
                                                                              (
                                                                                  item,
                                                                              ) => {
                                                                                  return {
                                                                                      id:
                                                                                          item.id,
                                                                                      name:
                                                                                          item.title,
                                                                                  };
                                                                              },
                                                                          )
                                                                        : []
                                                                }
                                                            />
                                                        </CustomInput>

                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.vote_id',
                                                            )}
                                                            fullWidth
                                                        >
                                                            <SelectInput
                                                                options={{
                                                                    fullWidth:
                                                                        'true',
                                                                }}
                                                                label={translate(
                                                                    'resources.outputs.fields.vote_id',
                                                                )}
                                                                source={getSource(
                                                                    'vote_id',
                                                                )}
                                                                choices={
                                                                    (formData &&
                                                                        getSelectedVotesForProject(
                                                                            formData,
                                                                        )) ||
                                                                    []
                                                                }
                                                            />
                                                        </CustomInput>
                                                    </div>
                                                    <div className="col-sm-6 col-m-6">
                                                        <CustomInput
                                                            tooltipText={translate(
                                                                'tooltips.resources.outputs.fields.description',
                                                            )}
                                                            textArea
                                                        >
                                                            <Labeled
                                                                label={translate(
                                                                    'resources.outputs.fields.description',
                                                                )}
                                                            />
                                                            <CustomTextArea
                                                                source={getSource(
                                                                    'description',
                                                                )}
                                                                {...this.props}
                                                            />
                                                        </CustomInput>
                                                    </div>
                                                    <br />
                                                    {formData &&
                                                    formData.phase_id === 1
                                                        ? null
                                                        : scopedFormData && (
                                                              <div>
                                                                  <ManageIndicatorsButton
                                                                      onShow={
                                                                          this
                                                                              .handleShowIndicators
                                                                      }
                                                                      show={
                                                                          this
                                                                              .props
                                                                              .popups
                                                                              .outputIndicators
                                                                      }
                                                                      source={getSource(
                                                                          'indicators',
                                                                      )}
                                                                      title={translate(
                                                                          'resources.outputs.actions.edit',
                                                                      )}
                                                                      targets={
                                                                          scopedFormData.targets
                                                                      }
                                                                      {...this
                                                                          .props}
                                                                  />
                                                                  {scopedFormData && (
                                                                      <IndicatorList
                                                                          indicators={
                                                                              scopedFormData.indicators
                                                                          }
                                                                          baseline={
                                                                              scopedFormData.baseline
                                                                          }
                                                                          targets={
                                                                              scopedFormData.targets
                                                                          }
                                                                      />
                                                                  )}
                                                              </div>
                                                          )}
                                                    {formData &&
                                                    formData.phase_id === 1 ? (
                                                        <div>
                                                            <ManageInvestmentsButton
                                                                onShow={
                                                                    this
                                                                        .handleShowInvestments
                                                                }
                                                                show={
                                                                    this
                                                                        .props
                                                                        .popups
                                                                        .outputInvestments
                                                                }
                                                                source={getSource(
                                                                    'investments',
                                                                )}
                                                                title={translate(
                                                                    'resources.outputs.fields.investments.actions.edit',
                                                                )}
                                                                references={
                                                                    references
                                                                }
                                                                years={
                                                                    (formData.investment_years &&
                                                                        formData.investment_years) ||
                                                                    []
                                                                }
                                                                formData={
                                                                    scopedFormData
                                                                }
                                                                type="outputs"
                                                                {...this.props}
                                                            />
                                                            <br />
                                                            <br />
                                                            {scopedFormData &&
                                                                scopedFormData.investments && (
                                                                    <Investments
                                                                        type="outputs"
                                                                        references={
                                                                            references
                                                                        }
                                                                        formData={
                                                                            formData
                                                                        }
                                                                        years={
                                                                            (formData.investment_years &&
                                                                                formData.investment_years) ||
                                                                            []
                                                                        }
                                                                        investments={
                                                                            scopedFormData.investments
                                                                        }
                                                                        translate={
                                                                            translate
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <br />
                                            </Fragment>
                                        )}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Fragment>
                    );
                }}
            </FormDataConsumer>
        );
    }
}

export default translate(OutputsForm);
