import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    BooleanInput,
    BooleanField,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    SelectInput,
    ReferenceInput,
    ReferenceField,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    ReferenceManyField,
    translate
} from 'react-admin';

import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';
import { DEFAULT_SORTING } from '../../constants/common';

export const FileTypesCreate = ({ translate, ...props })  => 
    <Create {...props}>
        <SimpleForm redirect="list">
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.name')}>
                <TextInput source="name" validate={required()}  />
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.is_required')}>
                <SelectInput 
                    source="is_required"
                    choices={[
                        { id: 'yes', name: 'Yes'},
                        { id: 'no', name: 'No'},
                    ]} 
                    options={{ fullWidth: 'true' }}
                />
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.phase_ids')}>
                <ReferenceArrayInput
                    label={translate('resources.file-types.fields.phase_ids')}
                    source="phase_ids"
                    sort={DEFAULT_SORTING}
                    reference="phases"
                    validate={required()}
                >
                    <SelectArrayInput  optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceArrayInput>
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.extensions')}>
                <SelectInput 
                    source="extensions"
                    choices={[
                        { id: '.xls, xlsx', name: 'Microsoft Excel'},
                        { id: '.doc, .docx', name: 'Microsoft Word'},
                        { id: '.csv', name: 'Comma Separated File'},
                        { id: '.pdf', name: 'Adobe Acrobat PDF'},
                        { id: '*', name: 'ALL'},
                    ]} 
                    options={{ fullWidth: 'true' }}

                />
            </CustomInput>
        </SimpleForm>
    </Create>


export const FileTypesEdit = ({ translate, ...props })  => 
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.is_required')}>
                <SelectInput 
                    source="is_required"
                    choices={[
                        { id: 'yes', name: 'Yes'},
                        { id: 'no', name: 'No'},
                    ]} 
                    options={{ fullWidth: 'true' }}
                />
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.phase_ids')}>
                <ReferenceArrayInput
                    label={translate('resources.file-types.fields.phase_ids')}
                    source="phase_ids"
                    sort={DEFAULT_SORTING}
                    reference="phases"
                    linkType="show"
                    validate={required()}
                >
                    <SelectArrayInput  optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceArrayInput>
            </CustomInput>
            <CustomInput tooltipText={translate('tooltips.resources.file-types.fields.extensions')}>
                <SelectInput 
                    source="extensions"
                    choices={[
                        { id: '.xls, .xlsx', name: 'Microsoft Excel'},
                        { id: '.doc, .docx', name: 'Microsoft Word'},
                        { id: '.csv', name: 'Comma Separated File'},
                        { id: '.pdf', name: 'Adobe Acrobat PDF'},
                        { id: '*', name: 'ALL'},
                    ]} 
                    options={{ fullWidth: 'true' }}
                />
            </CustomInput>
        </SimpleForm>
    </Edit>


const FileTypesShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const FileTypesShow = ({ translate, ...props })  =>
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.file-types.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<FileTypesShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceArrayField 
                        reference="phases" source="phase_ids"
                    >
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField >
                    <TextField source="extensions" />
                </SimpleShowLayout>
            </Show>
        </Card>

export const FileTypesList = ({ translate, ...props }) =>
    <List {...props} bulkActions={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceArrayField reference="phases" source="phase_ids">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField >
            <TextField source="extensions" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>

export default FileTypesList;
