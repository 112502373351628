import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';

import {
    ArrayInput,
    FormDataConsumer,
    SimpleFormIterator,
    Labeled,
    TextInput,
    DateInput,
    SelectArrayInput,
    SelectInput,
    ArrayField,
    translate,
} from 'react-admin';
import {
    getFiscalYears,
    getFiscalYearsRange,
} from '../../../../helpers/formatters';

import CustomInput from '../../../../components/CustomInput';
import CustomTextArea from '../../../../components/customTextArea';
import ManageInvestmentsButton from './ManageInvestmentsButton';

import dataProvider from '../../../../providers/dataProvider';
import Investments from '../../InvestmentList';

class ActivitiesForm extends Component {
    state = {
        references: {},
    };

    componentDidMount() {
        dataProvider('GET_MANY', 'fund-sources', { ids: [] }).then(
            (response) => {
                this.setState({
                    references: {
                        ...this.state.references,
                        'fund-sources': lodash.sortBy(response.data, 'code'),
                    },
                });
            },
        );
        dataProvider('GET_MANY', 'cost-classifications', { ids: [] }).then(
            (response) => {
                this.setState({
                    references: {
                        ...this.state.references,
                        'cost-classifications': lodash.sortBy(
                            response.data,
                            'code',
                        ),
                    },
                });
            },
        );
        dataProvider('GET_MANY', 'currency-rates', { ids: [] }).then(
            (response) => {
                this.setState({
                    references: {
                        ...this.state.references,
                        'currency-rates': lodash.sortBy(response.data, 'code'),
                    },
                });
            },
        );
    }

    handleShowInvestments = (visible) => {
        this.props.onShowPopup('activityInvestments', visible);
    };

    render() {
        const { references } = this.state;
        const { translate, record } = this.props;
        return (
            <Fragment>
                <ArrayInput source="activities" label={false}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({
                                getSource,
                                scopedFormData,
                                formData,
                                ...rest
                            }) => {
                                return (
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <CustomInput
                                                    tooltipText={translate(
                                                        'tooltips.resources.activities.fields.title',
                                                    )}
                                                    fullWidth
                                                >
                                                    <TextInput
                                                        source={getSource(
                                                            'title',
                                                        )}
                                                        label={translate(
                                                            'resources.activities.fields.title',
                                                        )}
                                                    />
                                                </CustomInput>
                                                <CustomInput
                                                    tooltipText={translate(
                                                        'tooltips.resources.activities.fields.start_date',
                                                    )}
                                                    fullWidth
                                                >
                                                    <SelectInput
                                                        options={{
                                                            fullWidth: 'true',
                                                        }}
                                                        label={translate(
                                                            'resources.activities.fields.start_date',
                                                        )}
                                                        source={getSource(
                                                            'start_date',
                                                        )}
                                                        choices={getFiscalYearsRange(
                                                            record.start_date,
                                                            record.end_date,
                                                        )}
                                                    />
                                                </CustomInput>
                                                <CustomInput
                                                    tooltipText={translate(
                                                        'tooltips.resources.activities.fields.end_date',
                                                    )}
                                                    fullWidth
                                                >
                                                    <SelectInput
                                                        options={{
                                                            fullWidth: 'true',
                                                        }}
                                                        label={translate(
                                                            'resources.activities.fields.end_date',
                                                        )}
                                                        source={getSource(
                                                            'end_date',
                                                        )}
                                                        choices={getFiscalYearsRange(
                                                            record.start_date,
                                                            record.end_date,
                                                        )}
                                                    />
                                                </CustomInput>

                                                <CustomInput
                                                    tooltipText={translate(
                                                        'tooltips.resources.activities.fields.output_ids',
                                                    )}
                                                    fullWidth
                                                >
                                                    <SelectArrayInput
                                                        options={{
                                                            fullWidth: 'true',
                                                        }}
                                                        label={translate(
                                                            'resources.activities.fields.output_ids',
                                                        )}
                                                        source={getSource(
                                                            'output_ids',
                                                        )}
                                                        choices={
                                                            formData.outputs &&
                                                            formData.outputs.map(
                                                                (item) => {
                                                                    return {
                                                                        id:
                                                                            item.id,
                                                                        name:
                                                                            item.title,
                                                                    };
                                                                },
                                                            )
                                                        }
                                                    />
                                                </CustomInput>
                                            </div>
                                            <div className="col-sm-6">
                                                <CustomInput
                                                    tooltipText={translate(
                                                        'tooltips.resources.activities.fields.description',
                                                    )}
                                                    textArea
                                                >
                                                    <Labeled
                                                        label={translate(
                                                            'resources.activities.fields.description',
                                                        )}
                                                    />
                                                    <CustomTextArea
                                                        source={getSource(
                                                            'description',
                                                        )}
                                                        {...this.props}
                                                    />
                                                </CustomInput>
                                            </div>
                                        </div>
                                        <br />
                                        <ManageInvestmentsButton
                                            onShow={
                                                this.handleShowInvestments
                                            }
                                            show={
                                                this.props.popups
                                                    .activityInvestments
                                            }
                                            source={getSource('investments')}
                                            title={translate(
                                                'resources.activities.fields.investments.actions.edit',
                                            )}
                                            references={references}
                                            years={
                                                (scopedFormData &&
                                                    scopedFormData.investment_years) ||
                                                []
                                            }
                                            formData={scopedFormData}
                                            type="activities"
                                            {...this.props}
                                        />
                                        <br />
                                        <br />
                                        {scopedFormData &&
                                            scopedFormData.investments &&
                                            scopedFormData.investments
                                                .length !== 0 && (
                                                <Investments
                                                    references={references}
                                                    type="activities"
                                                    formData={formData}
                                                    years={
                                                        (scopedFormData.investment_years &&
                                                            scopedFormData.investment_years) ||
                                                        []
                                                    }
                                                    investments={
                                                        scopedFormData.investments
                                                    }
                                                    translate={translate}
                                                />
                                            )}
                                        <br />
                                    </Fragment>
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Fragment>
        );
    }
}

export default translate(ActivitiesForm);
