import lodash from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export const getInvestmentYears = (activities) => {
    const resultYears = activities.map((item) => item.investment_years);
    return lodash.uniq(lodash.flatten(resultYears));
}

export const getInvestmentValue = (activity, year) => {
    const summByYear = lodash.sumBy(activity.investments, (item) => parseFloat(item[year]));
    return lodash.isNaN(summByYear) ? 0 : summByYear;
}

export const getTotalForInvestment = (activity, investment) => {
    let total = 0;
    activity.investment_years.forEach((year) => {
        total += investment[year] 
            ? parseFloat(investment[year])
            : 0;
    });
    return lodash.isNaN(total) ? 0 : total;
}

export const getTotalValueForActivity = (activities, year) => {
    let summByYear = 0;
    let totalByYear = 0;
    activities.forEach((activity) => {
        activity.investments.forEach((investment) => {
            totalByYear += investment[year] 
                ? parseFloat(investment[year])
                : 0;
        })
    })
    
    return lodash.isNaN(totalByYear) ? 0 : totalByYear;
}

export const getTotalProjectCost = (record) => {
    let totalOutput = 0;
    record.outputs.map((output) => {
        output.activities.map((activity) => {
            let activityTotal = 0;
            record.investment_years.map((year) => {
                let investmentSum = lodash.sumBy(activity.investments, (item) => 
                    typeof item[year] !== 'undefined' 
                        ? parseFloat(item[year]) : 0)
                activityTotal += investmentSum ? investmentSum : 0;
                totalOutput += investmentSum ? investmentSum : 0;
            })
        })

    })

    return lodash.isNaN(totalOutput) ? 0 : totalOutput;
}

export const getTotalProjectOutputsCost = (record) => {
    let totalOutput = 0;
    record.outputs.map((output) => {
        record.investment_years.map((year) => {
            let investmentSum = lodash.sumBy(output.investments, (item) => 
                item[year] ? parseFloat(item[year]) : 0)
            totalOutput += investmentSum ? investmentSum : 0;
        })
    })

    return lodash.isNaN(totalOutput) ? 0 : totalOutput;
}

export const costSumFormatter = (value) => {
    if (!value) {
        return '-';
    }
    return typeof value !== 'undefined' && value !== 0
        ? numeral(value).format('0,0.00')
        : '-'
}

export const costSumFormatterReports = (value) => {
    if (!value) {
        return '-';
    }
    return typeof value !== 'undefined' && value !== 0
        ? numeral(value).format('0,0.00a')
        : '-'
}

export const billionsFormatter = (value, onlyNumbers) => {
    if (!value) {
        return '0';
    }
    return typeof value !== 'undefined' && value !== 0
        ? numeral(value).format('0,0.00')
        : '0';
};


export const getFiscalYears = (startDate, endDate) => {
    const startDateYear = moment(startDate).startOf('year');
    const startDateFiscalEnd = startDateYear.clone().add(1, 'years');

    const endDateYear = moment(endDate).startOf('year');
    const endDateFiscalEnd = endDateYear.clone().add(1, 'years');

    const dateDiff = endDateYear.diff(startDateYear, 'years');
    // const endDateFiscalEnd = endDateYear.clone().add(1, 'years');

    return `Start Date: FY${startDateYear.format('YYYY')}/${startDateFiscalEnd.format('YY')}, 
    End Date: FY${endDateYear.format('YYYY')}/${endDateFiscalEnd.format('YY')}, 
    Duration years: ${dateDiff + 1} years`
}