import { AUTH_LOGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { ROLE, TOKEN, USER_ID, CONFIG } from '../constants/auth';
import { API_URL } from '../constants/common';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(`${API_URL}/site/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status === 401) {
                    throw new Error('Incorrect user name and password.');
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, user_id, role, fullname, sector_id, vote_id, department_id  }) => {
                const config = {
                    sector_id: sector_id,
                    vote_id: vote_id,
                    department_id: department_id,
                    fullname: fullname
                }
                sessionStorage.setItem(TOKEN, token);
                sessionStorage.setItem(ROLE, JSON.stringify(role));
                sessionStorage.setItem(USER_ID, user_id);
                sessionStorage.setItem(CONFIG, JSON.stringify(config));
            });
    }
    if (type === AUTH_LOGOUT) {
        removeAuthTokens();
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            removeAuthTokens();
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return sessionStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/no-access' });
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = sessionStorage.getItem(ROLE);
        const user_id = sessionStorage.getItem(USER_ID);
        const config = sessionStorage.getItem(CONFIG);
        return role ? Promise.resolve({ user_id, role, config }) : Promise.reject();
    }

    return Promise.resolve();
};

const removeAuthTokens = () => {
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(ROLE);
    sessionStorage.removeItem(USER_ID);
    sessionStorage.removeItem(CONFIG);
};
