import React, { Fragment, Component } from 'react';
import { FormDataConsumer, translate, LongTextInput, required, TextInput, SelectInput } from 'react-admin';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { getFormValues, isInvalid } from 'redux-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutcomesForm from './ResultMatrixForms/OutcomesForm';
import OutputsForm from './ResultMatrixForms/OutputsForm';
import GoalForm from './ResultMatrixForms/GoalForm';
import ActivitiesForm from './ResultMatrixForms/ActivitiesForm';
import ManageIndicatorsButton from './ResultMatrixForms/ManageIndicatorsButton';
import CustomInput from '../../../components/CustomInput';
import { GoalIndicatorList } from '../ProjectDetails';
// import SelectInput from '@material-ui/core/Select/SelectInput';
import { getFiscalYears } from '../../../helpers/formatters';

const iconStyle = {
    marginRight: '10px'
}

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

class ResultMatrixForm extends Component {
    state = {
        activeTab: 0,
        tempStep: 0
    };

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.isSaving === false) {
    //         // this.setState({ activeTab: this.state.tempStep });
    //         // this.props.onStepChange(this.state.tempStep)
    //     }
    // }

    handleChange = (event, newValue) => {
        this.props.save(this.props.formValues, false);
        this.props.onChangeStep(newValue)
            // this.setState({ tempStep: newValue });
    };

    renderTabContent(value) {
        switch (value) {
            case 0:
                return <GoalForm {...this.props} />
            case 1:
                return <OutcomesForm {...this.props}/>;
            case 2:
                return <OutputsForm {...this.props}/>;
            case 3:
                return <ActivitiesForm {...this.props} />;
            default:
                return <GoalForm {...this.props} />
        }
    }

    renderActiveTabLoading = (tabId) => {
        const { tempStep } = this.state;
        const iconLoading = this.props.isSaving === false 
            ? null 
            : <CircularProgress style={iconStyle} size={25} thickness={2} />;

        if (tabId === tempStep) {
            return iconLoading;
        }

        return null;
    }

    render() {
        return (
            <FormDataConsumer>
                {({ getSource, scopedFormData, formData, ...rest }) => {
                    return (
                        <Fragment>
                            {
                                formData && formData.phase_id > 1 && (
                                    <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.outcome_base')}>
                                        {/* <TextInput
                                            source="outcome_base"
                                            label={this.props.translate(
                                                'resources.project-details.fields.outcome_base',
                                            )}
                                        /> */}
                                        <SelectInput 
                                            options={{ fullWidth: 'true'}}
                                            label={this.props.translate(
                                                'resources.project-details.fields.outcome_base',
                                            )}
                                            source="outcome_base"
                                            choices={getFiscalYears()}
                                        />
                                    </CustomInput>
                                )
                            }
                            <br/>
                            <Tabs
                                value={this.props.matrixStep}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant={null}
                            >
                                <Tab label={<span>{this.renderActiveTabLoading(0)}{this.props.translate(
                                        'resources.project-details.fields.goal',
                                        { smart_count: 2 },
                                    )}</span>} />
                                <Tab
                                    label={<span>{this.renderActiveTabLoading(1)}{this.props.translate(
                                        'resources.outcomes.name',
                                        { smart_count: 2 },
                                    )}</span>}
                                />
                                <Tab
                                    label={<span>{this.renderActiveTabLoading(2)}{this.props.translate(
                                        'resources.outputs.name',
                                        { smart_count: 2 },
                                    )}</span>}
                                />
                                {
                                    formData && formData.phase_id >= 2 &&
                                        <Tab
                                            label={<span>{this.renderActiveTabLoading(3)}{this.props.translate(
                                                'resources.activities.name',
                                                { smart_count: 2 },
                                            )}</span>}
                                        />
                                }
                                
                            </Tabs>
                            <TabContainer>
                                {this.renderTabContent(this.props.matrixStep)}
                            </TabContainer>
                        </Fragment>
                    );
                }}
            </FormDataConsumer>
        );
    }
}

const mapStateToProps = state => ({
    formValues: getFormValues('record-form')(state),
    isInvalid: isInvalid('record-form')(state),
    isSaving: state.admin.saving
});

export default translate(
    connect(
        mapStateToProps,
        null,
    )(ResultMatrixForm),
);
