import React from 'react';
import { authProvider, dataProvider, mockDataProvider, uploaderWrapper } from './providers';
import {
    Admin,
    Resource,
    ShowGuesser,
    ListGuesser,
    EditGuesser,
    translate
} from 'react-admin';

import {
    DepartmentCreate,
    DepartmentEdit,
    DepartmentList,
    DepartmentShow,
    FundCreate,
    FundEdit,
    FundShow,
    FundList,
    FundSourceCreate,
    FundSourceEdit,
    FundSourceShow,
    FundSourceList,
    OrganizationCreate,
    OrganizationEdit,
    OrganizationList,
    OrganizationShow,
    PhaseCreate,
    PhaseEdit,
    PhaseList,
    PhaseShow,
    // ProjectCategoriesList,
    // ProjectCategoryShow,
    // ProjectCategoryEdit,
    // ProjectCategoryCreate,
    FileTypesList,
    FileTypesShow,
    FileTypesEdit,
    FileTypesCreate,
    CostCategoriesList,
    CostCategoriesShow,
    CostCategoriesEdit,
    CostCategoriesCreate,
    CostClassificationsList,
    CostClassificationsShow,
    CostClassificationsEdit,
    CostClassificationsCreate,
    ProjectCreate,
    ProjectEdit,
    ProjectList,
    ProjectShow,
    SectorCreate,
    SectorEdit,
    SectorList,
    SectorShow,
    VoteFunctionList,
    VoteFunctionEdit,
    VoteFunctionCreate,
    VoteFunctionShow,
    VoteList,
    VoteEdit,
    VoteCreate,
    VoteShow,
    UserManagmentList,
    UserManagmentEdit,
    UserManagmentCreate,
    UserManagmentShow,
    ReportsList,
    Dashboard,
    CurrenciesList,
    CurrencyCreate,
    CurrencyEdit,
    CurrencyShow,
    CurrencyRatesList,
    CurrencyRatesCreate,
    CurrencyRatesEdit,
    CurrencyRatesShow,
    NotificationsShow,
    NotificationsList,
} from './views';
import { AdminLayout } from './containers';
import englishMessages from './i18n/en';
import './App.scss';

import { Login } from 'react-admin';

// const LoginPage = () => <Login backgroundImage="/empty.png" />;

import MyLoginPage from './components/CustomLoginPage';
import routes from './routes';

const DataProvider = uploaderWrapper(dataProvider);
const i18nProvider = locale => {
    return englishMessages;
};

const App = () => (
    <Admin
        locale="en"
        appLayout={AdminLayout}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        // dataProvider={mockDataProvider}
        dataProvider={DataProvider}
        loginPage={MyLoginPage}
        customRoutes={routes}
    >
    {permissions => [
        <Resource
            list={translate(ProjectList)}
            name="projects"
            show={translate(ProjectShow)}
            edit={translate(ProjectEdit)}
        />,
        <Resource
            create={translate(DepartmentCreate)}
            edit={translate(DepartmentEdit)}
            list={translate(DepartmentList)}
            name="departments"
            show={translate(DepartmentShow)}
        />,
        <Resource
            create={translate(FundCreate)}
            edit={translate(FundEdit)}
            list={translate(FundList)}
            name="funds"
            show={translate(FundShow)}
        />,
        <Resource
            create={translate(FundSourceCreate)}
            edit={translate(FundSourceEdit)}
            list={translate(FundSourceList)}
            name="fund-sources"
            show={translate(FundSourceShow)}
        />,
        <Resource
            create={translate(OrganizationCreate)}
            edit={translate(OrganizationEdit)}
            list={translate(OrganizationList)}
            name="organizations"
            show={translate(OrganizationShow)}
        />,
        <Resource
            create={translate(PhaseCreate)}
            edit={translate(PhaseEdit)}
            list={translate(PhaseList)}
            name="phases"
            show={translate(PhaseShow)}
        />,
        
        <Resource
            list={translate(UserManagmentList)}
            name="users"
            show={translate(UserManagmentShow)}
            edit={translate(UserManagmentEdit)}
            create={translate(UserManagmentCreate)}
        />,
        <Resource
            list={translate(FileTypesList)}
            name="file-types"
            show={translate(FileTypesShow)}
            edit={translate(FileTypesEdit)}
            create={translate(FileTypesCreate)}
        />,
        <Resource
            list={translate(CostClassificationsList)}
            name="cost-classifications"
            show={translate(CostClassificationsShow)}
            edit={translate(CostClassificationsEdit)}
            create={translate(CostClassificationsCreate)}
        />,
        <Resource
            list={translate(CostCategoriesList)}
            name="cost-categories"
            show={translate(CostCategoriesShow)}
            edit={translate(CostCategoriesEdit)}
            create={translate(CostCategoriesCreate)}
        />,
        <Resource 
            list={translate(ListGuesser)} 
            name="options-appraisals" 
        />,
        <Resource
            list={translate(ListGuesser)}
            name="outcomes"
        />,
        <Resource
            list={translate(ListGuesser)}
            name="outputs"
        />,
        <Resource
            list={translate(ListGuesser)}
            name="activities"
        />,
        <Resource 
            list={translate(ListGuesser)} 
            name="indicators" 
        />,
        <Resource 
            list={translate(ListGuesser)} 
            name="targets" 
        />,
        <Resource 
            list={translate(ListGuesser)} 
            name="roles" 
        />,
        <Resource
            list={translate(ListGuesser)}
            name="project-details"
            show={translate(ShowGuesser)}
            edit={translate(ProjectEdit)}
            create={translate(ProjectCreate)}
        />,
        <Resource
            create={translate(SectorCreate)}
            edit={translate(SectorEdit)}
            list={translate(SectorList)}
            name="sectors"
            show={translate(SectorShow)}
        />,
        <Resource
            create={translate(VoteCreate)}
            edit={translate(VoteEdit)}
            list={translate(VoteList)}
            name="votes"
            show={translate(VoteShow)}
        />,
        <Resource
            create={translate(VoteFunctionCreate)}
            edit={translate(VoteFunctionEdit)}
            list={translate(VoteFunctionList)}
            name="vote-functions"
            show={translate(VoteFunctionShow)}
        />,
        <Resource
            list={translate(ReportsList)}
            name="reports"
        />,
        <Resource
            list={translate(Dashboard)}
            name="dashboard"
        />,
        <Resource 
            create={translate(CurrencyCreate)}
            edit={translate(CurrencyEdit)}
            list={translate(CurrenciesList)}
            show={translate(CurrencyShow)}
            name="currencies" 
        />,
        <Resource 
            create={translate(CurrencyRatesCreate)}
            edit={translate(CurrencyRatesEdit)}
            list={translate(CurrencyRatesList)}
            show={translate(CurrencyRatesShow)}
            name="currency-rates" 
        />,
        <Resource 
            list={translate(NotificationsList)} 
            show={translate(NotificationsShow)}
            name="notifications" 
        />,
    ]}
    </Admin>
);
export default App;
