import React, { Fragment, Component } from 'react';
import { Table } from 'reactstrap';
import { translate, ReferenceField, FunctionField } from 'react-admin';
import './styles.scss';
import InvestmentList from '../Projects/InvestmentList';
import HTML2React from 'html2react';
import lodash from 'lodash';
import {
    getInvestmentYears,
    getTotalProjectCost,
    costSumFormatter,
    getTotalProjectOutputsCost,
    getInvestmentValue,
    getTotalForInvestment,
    getTotalValueForActivity,
} from './helpers';
import dataProvider from '../../providers/dataProvider';
import moment from 'moment';
import logo from '../../assets/img/logo.jpeg';
import { ProjectFramework } from './components/ProjectFramework';
import { ProjectBackGround } from './components/ProjectBackGround';
import { ProjectInformation } from './components/ProjectInformation';
import { GovernmentAgencies } from './components/GovernmentAgencies';
import { OutputInvestments } from './components/OutputInvestments';
import { ImplementingAgencies } from './components/ImplementingAgencies';
import { OptionsAppraisal } from './components/OptionsAppraisal';
import { OptionsAnalysis } from './components/OptionsAnalysis';
import { ActivitiesInvestments } from './components/ActivitiesInvestments';
import { Attacments } from './components/Attacments';
import { ProposedFunding } from './components/ProposedFunding';
import { ResultMatrix } from './components/ResultMatrix';
import { Introduction } from './components/Introduction';
import { ExecutiveSummary } from './components/ExecutiveSummary';
import { AdditionalInformation } from './components/AdditionalInformation';
import { OMCosts } from './components/OMCosts';
import GantChart from './components/GantChart';
import { Alert } from 'reactstrap';

import './styles.scss';

class ProjectDetailShow extends Component {
    state = {
        references: {},
        showMap: false,
    };

    handleShowMap = () => {
        this.setState({ showMap: !this.state.showMap });
    };

    componentDidMount() {
        dataProvider('GET_MANY', 'fund-sources', { ids: [] }).then(response => {
            this.setState({
                references: {
                    ...this.state.references,
                    'fund-sources': response.data,
                },
            });
        });
        dataProvider('GET_MANY', 'cost-classifications', { ids: [] }).then(
            response => {
                this.setState({
                    references: {
                        ...this.state.references,
                        'cost-classifications': response.data,
                    },
                });
            },
        );
    }

    renderPCN() {
        return (
            <div>
                <ProjectBackGround
                    counter="1"
                    showMap={this.state.showMap}
                    onShowMap={this.handleShowMap}
                    {...this.props}
                />
                <br className="pageBreak" />

                <ProjectFramework counter="2" {...this.props} />
                <br className="pageBreak" />

                <GovernmentAgencies counter="2.4" {...this.props} />
                <br className="pageBreak" />

                <OutputInvestments counter="3" {...this.props} />
                <br className="pageBreak" />

                <ImplementingAgencies counter="3.1" {...this.props} />
                <br className="pageBreak" />

                <AdditionalInformation counter="4" {...this.props} />
                <br className="pageBreak" />

                <Attacments counter="5" {...this.props} />
            </div>
        );
    }

    renderPP() {
        return (
            <div>
                <ProjectBackGround
                    counter="1"
                    showMap={this.state.showMap}
                    onShowMap={this.handleShowMap}
                    {...this.props}
                />
                <br className="pageBreak" />

                <ProjectFramework counter="2" {...this.props} />
                <br className="pageBreak" />

                <ResultMatrix counter="3" {...this.props} />
                <br className="pageBreak" />

                <GantChart counter="3.1" record={this.props.customRecord} />
                <br className="pageBreak" />

                <GovernmentAgencies counter="3.2" {...this.props} />
                <br className="pageBreak" />

                <ActivitiesInvestments counter="4" {...this.props} />
                <br className="pageBreak" />

                <ImplementingAgencies counter="4.1" {...this.props} />
                <br className="pageBreak" />

                <AdditionalInformation counter="5" {...this.props} />
                <br className="pageBreak" />

                <Attacments counter="6" {...this.props} />
            </div>
        );
    }

    renderPFS() {
        const { customRecord, customBasePath, translate } = this.props;

        return (
            <div>
                <ExecutiveSummary counter="1" {...this.props} />
                <br className="pageBreak" />

                <Introduction counter="2" {...this.props} />
                <br className="pageBreak" />

                <ProjectBackGround
                    counter="3"
                    showMap={this.state.showMap}
                    onShowMap={this.handleShowMap}
                    {...this.props}
                />
                <br className="pageBreak" />

                <OptionsAnalysis counter="4" {...this.props} />
                <br className="pageBreak" />

                <ResultMatrix counter="10" {...this.props} />
                <br className="pageBreak" />

                <GantChart counter="10.1" record={this.props.customRecord} />
                <br className="pageBreak" />

                <GovernmentAgencies counter="10.2" {...this.props} />
                <br className="pageBreak" />

                <ActivitiesInvestments counter="11" {...this.props} />
                <br className="pageBreak" />

                <ImplementingAgencies counter="11.1" {...this.props} />
                <br className="pageBreak" />

                <ProposedFunding counter="12" {...this.props} />
                <br className="pageBreak" />

                <AdditionalInformation counter="13" {...this.props} />
                <br className="pageBreak" />

                <Attacments counter="14" {...this.props} />
            </div>
        );
    }

    renderFS() {
        const { customRecord, customBasePath, translate } = this.props;
        return (
            <div>
                <ExecutiveSummary counter="1" {...this.props} />
                <br className="pageBreak" />

                <Introduction counter="2" {...this.props} />
                <br className="pageBreak" />

                <ProjectBackGround
                    counter="3"
                    showMap={this.state.showMap}
                    onShowMap={this.handleShowMap}
                    {...this.props}
                />
                <br className="pageBreak" />

                <ResultMatrix counter="4" {...this.props} />
                <br className="pageBreak" />

                <GantChart counter="4.1" record={this.props.customRecord} />
                <br className="pageBreak" />

                <OptionsAppraisal counter="5" {...this.props} />
                <br className="pageBreak" />

                <ActivitiesInvestments counter="14" {...this.props} />
                <br className="pageBreak" />

                <OMCosts counter="14.1" {...this.props} />
                <br className="pageBreak" />

                <ImplementingAgencies counter="14.2" {...this.props} />
                <br className="pageBreak" />

                <ProposedFunding counter="15" {...this.props} />
                <br className="pageBreak" />

                <AdditionalInformation counter="16" {...this.props} />
                <br className="pageBreak" />

                <Attacments counter="17" {...this.props} />
            </div>
        );
    }

    renderPC() {
        const { customRecord, customBasePath, translate } = this.props;
        return (
            <div>
                <ExecutiveSummary counter="1" {...this.props} />
                <br className="pageBreak" />

                <Introduction counter="2" {...this.props} />
                <br className="pageBreak" />

                <ProjectBackGround
                    counter="3"
                    showMap={this.state.showMap}
                    onShowMap={this.handleShowMap}
                    {...this.props}
                />
                <br className="pageBreak" />

                <ResultMatrix counter="4" {...this.props} />
                <br className="pageBreak" />

                <GantChart counter="4.1" record={this.props.customRecord} />
                <br className="pageBreak" />

                <OptionsAppraisal counter="5" {...this.props} />
                <br className="pageBreak" />

                <ActivitiesInvestments counter="14" {...this.props} />
                <br className="pageBreak" />

                <OMCosts counter="14.1" {...this.props} />
                <br className="pageBreak" />

                <ImplementingAgencies counter="14.2" {...this.props} />
                <br className="pageBreak" />

                <ProposedFunding counter="15" {...this.props} />
                <br className="pageBreak" />

                <AdditionalInformation counter="16" {...this.props} />
                <br className="pageBreak" />

                <Attacments counter="17" {...this.props} />
            </div>
        );
    }

    renderContentByPhase() {
        const { customRecord, customBasePath, translate } = this.props;

        if (!customRecord) return null;

        switch (customRecord.phase_id) {
            case 1:
                return this.renderPCN();
            case 2:
                return this.renderPP();
            case 3:
                return this.renderPFS();
            case 4:
                return this.renderFS();
            case 5:
                return this.renderPC();

            default:
                return null;
        }
    }

    render() {
        const { customRecord, customBasePath, translate } = this.props;
        const record = customRecord;
        const basePath = customBasePath;

        return (
            <div>
                <div id="docx" className="export_container">
                    {!lodash.isEmpty(record) && record.is_high_risk && (
                        <Alert
                            color="danger"
                            style={{ position: 'absolute', top: '140px' }}
                        >
                            {translate('messages.high_risk_project')}
                        </Alert>
                    )}
                    <ProjectInformation {...this.props} />
                    <br className="pageBreak" />

                    {this.renderContentByPhase()}
                </div>
            </div>
        );
    }
}

export default translate(ProjectDetailShow);
