import ArrowForward from '@material-ui/icons/ArrowForward';
import Assignment from '@material-ui/icons/Assignment';
import Avatar from './Avatar';
import Cancel from '@material-ui/icons/Cancel';
import Card from '@material-ui/core/Card';
import Check from '@material-ui/icons/Check';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Refresh from '@material-ui/icons/Refresh';
import Timeline from '@material-ui/icons/Timeline';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    item: {
        width: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '10px',
        paddingLeft: '25px',
        position: 'relative'
    },
    status: {
        position: 'absolute',
        height: '100%',
        width: '30px',
        right: 0,
        top: 0,
    },
    submit: {
        backgroundColor: 'rgba(128, 128, 128, 0.5)'
    },
    approve: {
        backgroundColor: 'rgba(0, 128, 0, 0.5)'
    },
    reject: {
        backgroundColor: 'rgba(255, 0, 0, 0.8)'
    },
    revise: {
        backgroundColor: 'rgba(255, 165, 0, 0.8)'
    },
    assign: {
        backgroundColor: 'rgba(232, 232, 0, 0.7)'
    },
    revert: {
        backgroundColor: 'rgba(128, 0, 128, 0.5)'
    },
    header: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    subtitle: {
        fontWeight: 'normal',
        fontSize: '12px',
        margin: '0',
        whiteSpace: 'normal'
    },
    footer: {
        fontSize: '10px',
        margin: '0'
    }
};

const colors = {
    'submit': 'rgba(128, 128, 128, 0.5);',
    'approve': 'rgba(0, 128, 0, 0.5);',
    'reject': 'rgba(255, 0, 0, 0.8);',
    'revise': 'rgba(255, 165, 0, 0.8);',
    'assign': 'rgba(232, 232, 0, 0.7);',
    'revert': 'rgba(128, 0, 128, 0.5);',
}

const getPhaseById = (id) => {
    switch (id) {
        case 1: return 'Project Concept Note'
        case 2: return 'Project Profile'
        case 3: return 'Pre-Feasibility'
        case 4: return 'Feasibility'
        case 5: return 'Project Proposal'
        default:
            return '';
    }
}

const getActionType = (action) => {
    switch (action) {
        case 'submit': return ' has submitted '
        case 'approve': return ' has approved '
        case 'reject': return ' has rejected '
        case 'revise': return ' has revised '
        case 'assign': return ' has assigned '
        case 'revert': return ' has reverted '
        default:
            return '';
    }
}



const getFormattedStatusText = (username, action) => {
    return (
    <p>
        <strong>{username}</strong>
        <strong>{getActionType(action)}</strong>
    </p>
    )
}

const renderIcon = (action) => {
    switch (action) {
        case 'submit': return <Check  style={{fill: '#3f51b5'}}/>
        case 'approve': return <Check  style={{fill: '#3f51b5'}}/>
        case 'reject': return <Cancel  style={{fill: '#3f51b5'}}/>
        case 'revise': return <Refresh  style={{fill: '#3f51b5'}}/>
        case 'assign': return <Assignment  style={{fill: '#3f51b5'}}/>
        case 'revert': return <ArrowForward  style={{fill: '#3f51b5'}}/>
        default:
            return null
    }
}

const renderStatus = (action, classes) => 
    <div class={`${classes.status} ${classes[action]}`} />

const EventItemView = ({ event, classes }) => (
    <ListItem>
        <Card className={classes.item}>
            <ListItemAvatar>
                <Avatar>
                    {renderIcon(event.action)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <div>
                        <div className={classes.title}>
                            {getFormattedStatusText(event.user_fullname, event.action)}
                        </div>
                        <div className={classes.subtitle}>
                            {event.username ? `Username: ${event.username}` : ''}
                        </div>
                        <div className={classes.subtitle}>
                            {event.phase_id ? `Phase: ${getPhaseById(event.phase_id)}` : ''}
                        </div>
                        <div className={classes.subtitle}>
                            {event.reason ? `Comments: ${event.reason}` : ''}
                        </div>
                        <br/>
                        {
                            event.files && event.files.length !== 0 &&
                                <div className={classes.subtitle}>
                                    <span>Files:</span><br/>
                                    <ul style={{ paddingLeft: '15px' }}>
                                        {
                                            event.files.map((item) =>
                                                <li><a href={item.filename}>{item.title}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                        }
                        
                    </div>
                }
                secondary={
                    <div className={classes.footer}>
                        {moment(event.created_at).format('DD.MM.YYYY hh:mm').toLocaleString()}
                    </div>
                }
            />
            { renderStatus(event.action, classes) }
        </Card>
    </ListItem>
);

const EventItem = withStyles(styles)(EventItemView);

export default EventItem;
