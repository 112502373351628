import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DisabledInput,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    SelectInput,
} from 'react-admin';

import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';

export const CostCategoriesCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
        <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.code')}>
                <TextInput source="code" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.expenditure_type')}>
                <SelectInput 
                    source="expenditure_type" 
                    choices={[
                        { id: 'recurrent' , name: 'Recurrent '},
                        { id: 'capital', name: 'Capital'},
                    ]}
                    validate={required()} 
                />
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const CostCategoriesEdit = props => (
    <Edit {...props}>
        <SimpleForm  toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.code')}>
                <TextInput source="code" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.cost-categories.fields.expenditure_type')}>
                <SelectInput 
                    source="expenditure_type" 
                    choices={[
                        { id: 'recurrent' , name: 'Recurrent '},
                        { id: 'capital', name: 'Capital'},
                    ]}
                    validate={required()} 
                />
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const CostCategoriesShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const CostCategoriesShow = ({ translate, ...props }) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.cost-categories.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<CostCategoriesShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="name" />
                    <TextField source="expenditure_type" />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

export const CostCategoriesList = props => {
    return (
        <List {...props} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <TextField source="expenditure_type" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default CostCategoriesList;
