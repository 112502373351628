import React from 'react';
import { Table } from 'reactstrap';
import lodash from 'lodash';
import { costSumFormatter } from '../ProjectDetailShow/helpers';
import { getFiscalYearsRangeForIntervals } from '../../helpers/formatters';


const getYears = (years) => {
    if (years) {
        const startDate = years[0];
        const endDate = years[years.length - 1];

        return getFiscalYearsRangeForIntervals(startDate, endDate);
    }

    return [];
}

export const InvestmentList = props => {
    const totalByYear = {};
    let total = 0;

    return (
        <Table responsive>
            <thead>
                <tr>
                    {
                        props.type === 'outputs' &&
                        <th>{}</th>
                    }
                    {
                        props.type === 'activities' &&
                            <th>{props.translate(`resources.${props.type}.fields.investments.fields.fund_source_id`)}</th>
                    }
                    {
                        props.type === 'activities' &&
                            <th>{props.translate(`resources.${props.type}.fields.investments.fields.cost_classification_id`)}</th>
                    }
                    {props.years && getYears(props.years).map(year => (
                        <th>{year.name}</th>
                    ))}
                    <th>{props.translate(`resources.${props.type}.fields.investments.fields.total`)}</th>
                </tr>
            </thead>
            <tbody>
                {props.investments && props.investments.map(investment => {
                    const fund_source = investment.fund_source_details ||
                        (props.references && lodash.find(props.references['fund-sources'], 
                            (item) => item.id === investment.fund_source_id)) || {}
                    const cost_classification = investment.cost_classification ||
                        (props.references && lodash.find(props.references['cost-classifications'], 
                            (item) => item.id === investment.cost_classification_id)) || {}
                    const totalByFund = {};
                    return (
                        <tr>
                            {
                                props.type === 'outputs' &&
                                <td>{}</td>
                            }
                            {
                                props.type === 'activities' &&
                                <td>
                                    {fund_source ? `${fund_source.code} - ${fund_source.name}` : ''}
                                </td>
                            }
                            {
                                props.type === 'activities' &&
                                <td>
                                    {cost_classification ? `${cost_classification.code} - ${cost_classification.name}` : ''}
                                </td>
                            }
                            {props.years && props.years.map(year => {
                                if (!totalByYear[year]) {
                                    totalByYear[year] = 0;
                                }
                                if (!totalByFund[fund_source.code]) {
                                    totalByFund[fund_source.code] = 0;
                                }
                                totalByYear[year] += investment[year] 
                                    ?   parseFloat(investment[year])
                                    :   0;
                                
                                totalByFund[fund_source.code] += investment[year] 
                                    ?   parseFloat(investment[year])
                                    :   0;
                                total += investment[year] 
                                    ?   parseFloat(investment[year])
                                    :   0;

                                return <td>{investment[year] 
                                    ?   costSumFormatter(parseFloat(investment[year]))
                                    :   0}</td>;
                            })}
                            <td>{isNaN(totalByFund[fund_source.code]) ? '-' : costSumFormatter(totalByFund[fund_source.code])}</td>
                        </tr>
                    );
                })}
                <tr>
                    <td>
                        <b>{props.translate(`resources.${props.type}.fields.investments.fields.total`)}:</b>
                    </td>
                    {
                        props.type === 'activities' && <td></td>
                    }
                    {props.years && props.years.map(year => (
                        <td>
                            <b>{isNaN(totalByYear[year]) ? '-' : costSumFormatter(totalByYear[year])}</b>
                        </td>
                    ))}
                    <td>
                        <b>{isNaN(total) ? '-' : costSumFormatter(total)}</b>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default InvestmentList;
