import { createMuiTheme } from '@material-ui/core/styles';
const unit = 2;

export default createMuiTheme({
    // palette: {
    //     primary: {
    //       light: '#FFCC80', // same as '#FFCC80',
    //       main: '#FB8C00', // same as orange[600]
    //       dark: '#EF6C00',
    //       contrastText: 'rgb(0,0,0)'
    //     }
    //   },
    spacing: { unit: unit },
    overrides: {
        MuiTabs: {
            fixed: {
                'overflow-x': 'auto'
            }
        },
        MuiStepper: {
            horizontal: {
                'overflow-x': 'auto'
            }
        },
        MuiDivider: {
            root: {
                margin: '8px 0',
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
                display: 'flex',
                'flex-direction': 'row',
            },
        },
        MuiFormLabel: {
            root: {
                padding: `${unit}px 0 ${unit / 2}px`,
                width: '100%',
                'font-weight': 'bold',
                'margin-bottom': 0,
                'line-height': 1.5,
            },
        },
        MuiButton: {
            root: {
                margin: '0 5px'
            }
        },
        //error input message
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-15px'
            }
        },
        MuiInput: {
            root: {
                minWidth: '150px'
            }
        },
        FileInput: {
            root: {
                display: 'flex',
                flexDirection: 'row-reverse'
            }
        },
        MuiPaper: {
            root: {
                minWidth: '60%'
            }
        },
        SimpleFormIterator: {
            root: {
                width: '98%'
            }
        },
        MuiTab: {
            wrapper: {
                width: '90%',
                maxWidth: '160px',
                textAlign: 'center',
                lineHeight: '16px'
            }
        },
        MuiModal: {
            root: {
                zIndex: '1'
            }
        },
        MuiDrawer: {
            root: {
                width: '650px'
            }
        },
        MuiPaper: {
            root: {
                minWidth: '150px'
            }
        },
        MuiDialog: {
            paperFullWidth: {
                'max-width': '75%'
            }
        }
    },
});
