import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
    },
    resources: {
        notifications: {
            name: 'Notifications',
            fields: {
                title_html: 'Subject',
                created_at: 'Date',
                sender: 'Sender',
                body_html: 'Message',
            },
        },
        dashboards: {
            projects_waiting: 'Projects Awaiting Your Decision',
            projects_phases: 'Projects by Phases',
            projects_groups: 'Groupped Projects',
            projects: 'Projects',
        },
        reports: {
            title: 'REPORTS',
            projects_by_location: 'Projects by Location',
            phases_title: 'Phases',
            filters: 'Sector and Vote filters',
            fiscal_load: 'Fiscal Load',
            cost_evolution: 'Evolution of Project Cost',
            projects_by_cycles:
                'Projects at different stages of the Development Cycle (UGX)',
            projects_by_cycles_counts:
                'Projects at different stages of the Development Cycle',
            no_data: 'No data available',
            loading: 'Data is loading, please wait ...',
            pipeline: {
                name: 'Pipeline of Projects',
                header: {
                    id: 'ID',
                    title: 'Title',
                    cost: 'Cost of Investment',
                    start_date: 'Proposed Start Date',
                    duration: 'Duration',
                    enter_date: 'Date of Feasibility',
                },
            },
            ranking: {
                name: 'Project Ranking',
                header: {
                    rank: 'Rank',
                    title: 'Title',
                    cost: 'Cost (UGX)',
                    sector: 'Name of Sector',
                    vote: 'Vote',
                    npv: 'NPV (UGX)',
                    enpv: 'ENPV (UGX)',
                    fnpv: 'FNPV (UGX)',
                    irr: 'IRR (%)',
                    err: 'ERR (%)',
                },
            },
        },
        roles: {
            admin: 'Administrator',
            department_admin_user: 'Department Head',
            pap_commissioner_user: 'PAP Commissioner',
            pap_head_user: 'PAP Principle',
            pap_standard_user: 'PAP Standard User',
            standard_user: 'Standard User',
            vote_admin_user: 'Head Planning',
            account_officer: 'Accounting officer',
            dc_member_user: 'DC member',
            sector_head_user: 'Sector Head',
            desk_officer_user: 'Desk Officer',
            donor_user: 'Donor',
            pap_senior_economist_user: 'PAP Senior Economist',
            pap_assist_commissioner_user: 'PAP Assistant Commissioner',
            secretariat_user: 'Secretariat Level/Directors',
        },
        projects: {
            name: 'Project |||| Projects',
            fields: {
                project_no: 'Project No',
                created_by: 'Created By',
                created_at: 'Submission Date',
                phase_id: 'Phase',
                status: 'Status',
                'workflow.status': 'Workflow Step',
                title: 'Project Title',
                sector_id: 'Name of Sector',
                department_id: 'Sub Program Name',
                vote_id: 'Vote',
            },
        },
        'project-details': {
            name: 'Project Details',
            fields: {
                responsible_officers: {
                    header: 'Responsible Officers',
                    title: 'Officer Title',
                    name: 'Officer Name',
                    phone: 'Officer Phone',
                    email: 'Officer Email',
                    mobile_phone: 'Officer Mobile Phone',
                },
                current_investment_ratio: 'Capital to Recurrent Ratio',
                title: 'Project Title',
                summary: 'Technical Description',
                start_date: 'Start Date',
                end_date: 'End Date',
                location: 'Location',
                geo_location: 'Geo Location',
                estimated_lifetime: 'Estimated Lifetime',
                is_dlp: 'Project Has Defect Liability Period',
                dl_period: 'Defect Liability Period',
                is_omp: 'Project Has Operation & Maintenance Period',
                om_period: 'Operation & Maintenance Period',
                om_cost: 'Operation & Maintenance Costs (O&M Costs, UGX)',
                om_fund_id: 'Fund Name',
                exec_management_plan: 'Executive Summary',
                exec_management_plan_pfs: 'Executive Summary',
                introduction: 'Introduction',
                implementing_agencies: 'Implementing Agencies',
                project_categories_id: 'Project Category',
                //background-form
                situation_analysis: 'Situation Analysis',
                situation_analysis_file: 'Situation Analysis Attachment',
                problem_statement: 'Problem Statement',
                problem_statement_file: 'Problem Statement Attachment',
                problem_causes: 'Problem Causes',
                problem_causes_file: 'Problem Causes Attachment',
                problem_effects: 'Problem Effects',
                problem_effects_file: 'Problem Effects Attachment',
                justification: 'Justification',
                justification_file: 'Justification Attachment',
                stakeholders: 'Stakeholders',
                government_coordination: {
                    title: 'Coordination with Other Government Agencies',
                    name: 'Name',
                    details: 'Details',
                },
                in_ndp: {
                    title: 'In NDP',
                    yes: 'National Development Plan',
                    other: 'Other Strategic Documents',
                },
                ndp_type: {
                    title: 'NDP Type',
                    core: 'NDP Core Projects',
                    priority: 'NDP Priority Area',
                },
                ndp_name: 'Project Name',
                ndp_page_no: 'Page in NDP',
                focus_area: {
                    null: '',
                    title: 'Focus Area',
                    wealth_creation: 'Wealth Creation',
                    inclusive_growth: 'Inclusive growth',
                    competitiveness: 'Competitiveness',
                },
                intervention: 'Intervention',
                other_strategic_directives: 'Other Strategic Directives',
                other_plans: {
                    development_partners: 'Development partners',
                    cabinet_directives: 'Cabinet directives',
                    parliament_approval: 'Parliament approval',
                    stare_owned_enterprise: 'Stare owned enterprise',
                    regional_integration_policy: 'Regional integration policy',
                    political_manifestos: 'Political manifestos',
                    interest_groups_or_beneficiaries:
                        'Interest groups or beneficiaries',
                    private_sponsors: 'Private sponsors',
                    sustainable_development_goals:
                        'Sustainable development goals',
                },
                plan_details: 'Details',
                //--officer
                officer_title: 'Officer Title',
                officer_name: 'Officer Name',
                officer_phone: 'Officer Phone',
                officer_email: 'Officer Email',
                officer_mobile_phone: 'Officer Mobile Phone',
                //introduction
                rational_study: 'Rationale of the Study',
                methodology: 'Methodology',
                organization_study: 'Organisation of the PFS and FS',
                organization_study_pfs:
                    'Organisation of the Pre-feasibility Study',
                organization_study_fs: 'Organisation of the Feasibility Study',
                additional_info: 'Project Additional Information',
                attachments: {
                    title: 'Mandatory attachments',
                    add: 'Add Attachment',
                    remove: 'Remove Attachment',
                },

                benefits: 'Benefits',
                goal: 'Project Goal',
                commentaries: 'Commentaries',
                files: 'Project Files',
                //--demand_analys
                demand_analysis: 'Demand Analysis',
                demand_analysis_files: 'Demand Analysis Files',
                //
                pre_feasibility_cost: 'Pre-Feasibility Cost',
                pre_feasibility_consultants: 'Pre-Feasibility Consultants',
                strategic_analysis: 'Strategic Analysis',
                strategic_alignment: 'Strategic Alignment',
                //FinancialAnalysisForm
                financing_modality: 'Financing Modality',
                feasbility_cost: 'Feasibility Cost',
                feasibility_consultants: 'Feasibility Consultants',
                feasibility_funds_request: 'Feasibility Funds Request',
                env_impact_assessment: 'Environmental Impact Assessment',
                //modules
                technical_design: 'Technical Design',
                production_plan: 'Production Plan',
                hr_requirements: 'HR Requirements',
                legal_assessment: 'Legal Assessment',
                financial_evaluation: 'Financial/Private Evaluation',
                economic_evaluation: 'Economic/Social Evaluation',
                risk_evaluation: 'Risk Evaluation',
                distributional_assessment: 'Distributional Assessment',
                exec_management_plan: 'Executive Management Plan',
                exec_management_plan_pfs: 'Executive Summary',
                sustainability_assessment: 'Sustainability Assessment',
                //TechnicalApproachForm
                pcn_outcome: 'Outcomes',
                pcn_interventions: 'Interventions',
                investments: 'Investment Costs',
                //--background
                in_ndpi: 'Already Existing in NDPI',
                in_ndpii: 'Already Existing in NDPII',
                in_mfped: 'Already Existing in MFPED PIP',
                strategic_other_plan: 'Strategic Other Plan',
                strategic_considerations: 'Other Strategic Considerations',
                strategic_fit: 'Strategic Fit',
                strategic_alignment: 'Strategic Alignment',
                outcome_base: 'Indicator Baseline Year',
                outcome_targets: 'Outcome Indicator Target Years',
                output_base: 'Output Indicator Baseline',
                output_targets: 'Output Indicator Target Years',
            },
        },
        organizations: {
            name: 'Organisation |||| Organisations',
            fields: {
                code: 'Organisation Code',
                name: 'Organisation Name',
            },
        },
        departments: {
            name: 'Sub Program |||| Sub Programs',
            fields: {
                code: 'Sub Program Code',
                name: 'Sub Program Name',
                organization_id: 'Organisation Name',
                vote_id: 'Vote',
                sector_id: 'Sector',
            },
        },
        funds: {
            name: 'Fund |||| Funds',
            fields: {
                code: 'Fund Code',
                name: 'Fund Name',
            },
        },
        'fund-sources': {
            name: 'Source of Fund |||| Source of Funds',
            fields: {
                code: 'Source of Fund Code',
                name: 'Source of Fund Name',
                fund_id: 'Fund Name',
            },
        },
        votes: {
            name: 'Vote |||| Votes',
            fields: {
                code: 'Vote Code',
                name: 'Vote Name',
                sector_id: 'Sector Name',
            },
        },
        'vote-functions': {
            name: 'Program|||| Programs',
            fields: {
                code: 'Program Code',
                name: 'Program Name',
                vote_id: 'Vote Name',
            },
        },
        sectors: {
            name: 'Sector |||| Sectors',
            fields: {
                code: 'Sector Code',
                name: 'Sector Name',
            },
        },
        outcomes: {
            name: 'Outcome |||| Outcomes',
            fields: {
                title: 'Outcome Title',
            },
            actions: {
                create: 'Create Outcome',
                edit: 'Manage Indicators',
            },
        },
        outputs: {
            name: 'Output |||| Outputs',
            fields: {
                outcome_ids: 'Outcomes',
                title: 'Output Title',
                description: 'Output Description',
                vote_id: 'Output Votes',
                output_value: 'Output quantity',
                output_unit: 'Unit',
                investments: {
                    name: 'Outputs Investments',
                    fields: {
                        fund_id: 'Fund',
                        fund_source_id: 'Fund sources',
                        total: 'Total',
                        cost_classification_id: 'Cost Classification',
                        cost_category_id: 'Cost Category',
                    },
                    actions: {
                        edit: 'Estimated Cost',
                    },
                },
            },
            actions: {
                create: 'Create Output',
                edit: 'Manage Indicators',
                editCosts: 'Estimated Cost',
            },
        },
        indicators: {
            name: 'Indicator |||| Indicators',
            fields: {
                title: 'Indicator Name',
                baseline: 'Baseline',
                verification_means: 'Verification Means',
                assumptions: 'Assumptions',
                risk_factors: 'Risk Factors',
            },
        },
        phases: {
            phase_1: 'Project Concept',
            phase_2: 'Project Profile',
            phase_3: 'Pre-feasibility',
            phase_4: 'Feasibility',
            phase_5: 'Project Proposal',
        },
        'project-categories': {
            name: 'Project Category |||| Project Categories',
            fields: {
                project_categories_id: 'Project Category Id',
                title: 'Project Category Title',
                last_phase_id: 'Last Phase',
            },
        },
        activities: {
            name: 'Activity |||| Activities',
            fields: {
                title: 'Activity Name',
                start_date: 'Start date',
                end_date: 'End date',
                description: 'Activity Description',
                output_ids: 'Activity Outputs',
                vote_id: 'Activity Votes',
                investments: {
                    name: 'Activities Investments',
                    fields: {
                        fund_id: 'Fund',
                        fund_source_id: 'Fund sources',
                        total: 'Total',
                        cost_classification_id: 'Cost Classification',
                        cost_category_id: 'Cost Category',
                    },
                    actions: {
                        edit: 'Estimated Cost',
                    },
                },
            },
        },
        options_appraisals: {
            name: 'Options Analysis',
            fields: {
                title: 'Title',
                description: 'Description',
                cost: 'Cost (UGX)',
                score: 'Total Score',
                funding_modality: {
                    title: 'Preferred funding modality',
                    title_pfs: 'Preferred Implementation modality',
                    procurement: 'Traditional procurement',
                    partnership: 'Public private partnership',
                },
                is_best: 'Preferred Alternative',
                justification: 'Justification of the Preferred Alternative',
                modality_justification:
                    'Justification for the Preferred Funding Modality',
                modality_justification_pfs:
                    'Justification for the Preferred Implementation Modality',
                stepper: {
                    description: 'Description',
                    building_blocks: 'Building Blocks',
                    analytical_modules: 'Analytical Modules',
                    best_option: 'Preferred Alternative Selection',
                },
                building_blocks: {
                    title: 'Building Blocks',
                    description: 'Description',
                    advantage: 'Advantages',
                    disadvantage: 'Disadvantages',
                    score: 'Score',
                    modules: {
                        demand_module: 'Demand Analysis',
                        technical_module: 'Technical & Engineering',
                        env_module: 'Environmental',
                        hr_module: 'HR requirements',
                        legal_module: 'Legal assessment',
                    },
                    demand_module: {
                        present_supply: 'Current Supply',
                        present_demand: 'Current Demand',
                        deficit: 'Deficit',
                        demand_projections: 'Demand Projections',
                    },
                },
                analytical_modules: {
                    title: 'Analytical Modules',
                    description: 'Description',
                    advantage: 'Advantages',
                    disadvantage: 'Disadvantages',
                    score: 'Score',
                    appraisal_methodology: {
                        title: 'Appraisal Methodology',
                        cea: 'CEA',
                        cba: 'CBA',
                    },
                    financial_evaluation: {
                        fnpv: 'FNPV (UGX bln.)',
                        irr: 'IRR %',
                        summary: 'Financial Analysis Details',
                    },
                    economic_evaluation: {
                        enpv: 'ENPV (UGX bln.)',
                        err: 'ERR %',
                        summary: 'Economical Analysis Details',
                    },
                    criterias: {
                        title: 'Cost Effectiveness Results',
                        criteria_title: 'Title',
                        criteria_value: 'Value',
                        measure_unit: 'Measure unit',
                    },
                    stakeholder_evaluation: {
                        title: 'Identity of the Stakeholder',
                        description: 'Description',
                        impact_sign: {
                            title: 'Impact',
                            positive: 'Positive',
                            negative: 'Negative',
                        },
                        beneficiary: {
                            title: 'Relation',
                            direct: 'Direct',
                            indirect: 'Indirect',
                        },
                    },
                    risk_evaluation: {
                        description: 'Description',
                        occurrence: 'Likelihood of Occurrence',
                        impact: 'Impact',
                        levels: {
                            low: 'Low',
                            medium: 'Medium',
                            high: 'High',
                            very_high: 'Very High',
                        },
                        mitigation_plan: 'Mitigation Plan',
                    },
                    modules: {
                        financial: 'Financial',
                        economic: 'Economic',
                        distributional: 'Distributional',
                        risk: 'Risk',
                    },
                },
                summary: {
                    title: 'Analysis of Alternatives Summary',
                },
            },
        },
        risk_factors: {
            name: 'Risk Factor |||| Risk Factors',
            fields: {
                risk_level: 'Risk Level',
                measures: 'Measures',
                risk_description: 'Risk Description',
            },
        },
        'file-types': {
            name: 'File Type |||| File Types',
            fields: {
                is_required: 'Is Required',
                phase_ids: 'Project Phases',
                name: 'File Type Name',
            },
        },
        users: {
            name: 'User Management',
            fields: {
                username: 'Username',
                email: 'E-mail',
                fullname: 'Full Name',
                phone: 'Phone',
                vote_id: 'Vote',
                role: 'Role',
                password_hash: 'Password',
                department_id: 'Sub Program Name',
                management_id: 'Name of Management',
                sector_id: 'Name of Sector',
                sector_ids: 'Name of Sectors',
                donor_id: 'Donors Name',
            },
        },
        'cost-classifications': {
            name: 'Cost Classification |||| Cost Classifications',
            fields: {
                code: 'Code',
                name: 'Name',
                cost_category_id: 'Cost Category',
            },
        },
        'cost-categories': {
            name: 'Cost Categories |||| Cost Categories',
            fields: {
                code: 'Code',
                name: 'Name',
                expenditure_type: 'Expenditure Type',
            },
        },
    },
    buttons: {
        show_map: 'Show Map',
        hide_map: 'Hide Map',
        assign: 'Assign',
        approve: 'Approve',
        revise: 'Revise',
        reject: 'Reject',
        select_file: 'Select File',
        upload: 'Upload',
        delete: 'Delete',
        submit: 'Submit',
        back: 'Back',
        next: 'Next',
        save_exit: 'Save And Exit',
        create: 'Create a Project',
        timeline: 'Timeline',
        close: 'Close',
        logout: 'Logout',
        account: 'Account',
        help: 'HELP',
        profile_edit: 'Edit Profile',
        profile: 'Profile',
        reset_password: 'Reset Password',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    timeline: {
        status: {
            submit: 'Project Submitted',
            approve: 'Approved',
            revise: 'Deferred',
            reject: 'Rejected',
        },
        title: 'Flow of Approvals',
        event_title: 'Project Status Information',
        submitted: 'Project Submitted',
        draft: 'Draft',
        approved: 'Approved',
        revised: 'Deferred',
        rejected: 'Rejected',
        'in pipeline': 'In Pipeline',
        completed: 'Completed',
        assigned: 'Assigned',
        by: 'by',
        date: 'Date',
        comments: 'Comments',
        no_data: 'Creation of a project is in progress.',
        phase: 'Phase',
        files: 'Files',
        not_pap_status: 'Waiting for DC decision',
        hidden_phase_status: 'Approved at ',
    },
    workflow: {
        va_file_subtitle:
            '* You must submit PPC Minutes file before submitting the project.',
        va_additionalFile_subtitle:
            '* You must submit SWG Minutes file before submitting the project.',
        workflow_additional_file_upload:
            '* You must submit DC Minutes file before submitting the project.',
        workflow_file_upload:
            '* You must submit project analysis before submitting the project.',
        assign: 'Select assigned user',
        decision: 'Input decision reason',
        comments: 'Comments',
        assign_user: 'Assigned user',
        messages: {
            status_change: 'Workflow status changed',
            status_not_change: 'Workflow status not changed',
        },
    },
    messages: {
        project_reject_confirmation: 'Are you sure you want to reject this project?',
        reset_password: 'Reset Password',
        reset_password_confirmation:
            'Are you sure you want to reset the password?',
        reset_password_error: 'Your password wasn`t changed.',
        reset_password_success: 'New password was sent to your email',
        high_risk_project: 'High Risk Project!',
        unathorised: 'Incorrect user name and password.',
        new_messages: 'You have new messages',
        saving_data: 'Data is saving, please wait',
        loading_data: 'Data is loading, please wait',
        file_uploaded: 'File was uploaded',
        file_deleted: 'File was deleted',
        file_ext_error: 'You should select correct file',
        all_steps_completed: 'All steps completed!',
        max_outcomes: 'Maximum 2 outcomes per project',
        max_outcomes_per_output: 'Maximum 2 outcomes per output',
        min_activity_per_output: 'At least 1 activity per output',
        max_shortlisted_options: 'Maximum 3 shortlisted options',
        min_selected_option: 'At least 1 shortlisted options must be selected',
        max_best_option: 'Maximum 1 Preferred Alternative',
        min_best_option: 'At least 1 Preferred Alternative must be selected',
        no_files: 'No files uploaded',
        no_file_type: 'File type',
        no_file_action: 'Not Uploaded',
        no_outcomes: 'At least one outcome has to be filled',
        no_outputs: 'At least one output has to be filled',
        om_cost:
            'This project does not have Operation & Maintenance Period. You can change it in Summary Section.',
        options_blocks_all: 'All building blocks must be filled',
        options_evaluation_all: 'All Analytical Modules must be filled',
        options_blocks_single: 'You should asses every building block',
        options_required: 'All Required fields must be filled',
        options_summary: 'All Description Fields must be filled',
        duplicate_project_title:
            'You are about to create a project with a name that already exist in the database. Are you sure you want to proceed?',
    },
    tooltips: {
        resources: {
            om_costs: {
                om_fund_id:
                    'Select the fund name from which the operation and maintenance cost will be obtained from.',
                om_fund_source_id:
                    'Select the source from where the operation and maintenance cost will be got from',
                om_cost_category_id: 'Select the cost category of the expense',
                om_cost_classification_id:
                    'Select the cost classification of the expense',
                om_years:
                    'Identify the amounts that will be spent for each financial year.',
            },
            reports: {
                projects_by_location_help:
                    'Turn on/off selectors to filter projects showed on geo map',
                projects_by_location_filters:
                    'Select Sector or Vote to filter projects showed on geo map',
            },
            projects: {
                fields: {
                    project_no:
                        'A unique project identification number that can be used to track the project throughout its lifecycle.',
                    created_by: 'User that created project',
                    created_at: 'Submission Date',
                    phase_id: 'Phase of the project.',
                    status: 'Status of the project.',
                    'workflow.status': 'Workflow Step',
                },
            },
            'project-details': {
                fields: {
                    responsible_officers: {
                        title: 'Officer Title',
                        name: 'Officer Name',
                        phone: 'Officer Phone',
                        email: 'Officer Email',
                        mobile_phone: 'Officer Mobile Phone',
                    },
                    current_investment_ratio: 'Capital to Recurrent Ratio',
                    title:
                        'Let it be as clear as possible, avoiding duplication of names within the Public Investment Plan',
                    summary:
                        'Provide a detailed technical description detailing the project planning elements required to achieve the project outcome.',
                    start_date:
                        'Indicate the financial year in when the project is expected to commence.',
                    end_date:
                        'The expected date of the project construction completion',
                    duration: 'Estimate the project construction timeline',
                    location:
                        'Precisely identify all the districts where the project will be located.',
                    geo_location: 'Geo Location',
                    estimated_lifetime:
                        'Operational life of the project after construction is completed. A typical operational lifetime of infrastructure project range from 10 to 25 years.',
                    is_dlp: 'Select if project has a defect liability period.',
                    dl_period:
                        'Defect Liability Period of the project. Enter number of years.',
                    is_omp:
                        'Select if project has operation & maintenance Period',
                    om_period:
                        'Operational life of the project after construction is completed. A typical operational lifetime of infrastructure project range from 10 to 25 years.',
                    introduction: 'Introduction',
                    implementing_agencies:
                        'Agencies responsible for delivery of the project outputs.',
                    project_categories_id: 'Project Category',
                    //background-form
                    situation_analysis:
                        'Provide a background to the project idea or the problem your project will focus on by: (i) describing current situation including past and on-going interventions; ii) recent developments in the area of interest, achievements and challenges if any, iii) Explain projected trends using published forecasts. Quote official statistics to support your narrative.',
                    situation_analysis_file: `Provide a background to the project idea or the problem your project will focus on by;
	(i) describing the current situation including past and on-going interventions, recent developments in the area of interest, achievements and challenges if any.
        Quote official statistics to support your narrative.
	(ii) Explaining projected trends using published forecasts
        `,
                    problem_statement: `Provide an explicit definition of the problem to be addressed in terms of challenges, constraints or gaps that the market or private sector cannot resolve and:
	i.	 Mention the likely causes of the problem both direct and indirect and
	ii.	 Give a brief insight of the likely consequences if no government intervention is made.`,
                    problem_statement_file:
                        'Provide an explicit definition of the problem to be addressed in terms of challenges, constraints or gaps that the market or private sector cannot resolve.',
                    problem_causes:
                        'Please provide the likely causes of the problem both direct and indirect.',
                    problem_causes_file: 'Problem Causes Attachment',
                    problem_effects:
                        'Please provide a brief insight of the likely consequences if no government intervention is made.',
                    problem_effects_file: 'Problem Effects Attachment',
                    justification:
                        'Justify the need for the proposed project by: (i) linking the project to the National Development Plan (NDP) strategic interventions by identifying the objective (s) that the proposed project is expected to contribute to; (ii) Linking the proposed project to Sector Investment Plan (SIP) objectives by describing the sector outcomes that the project is expected to impact.',
                    justification_file: 'Justification Attachment',
                    stakeholders:
                        'Identify the key stakeholders that are likely to be affected by the interventions including: (i) Direct Beneficiaries (ii) Indirect Beneficiaries (iii) Project Affected Persons.  Give a brief description of the likely impact of the project on the stakeholders.',
                    government_agencies: {
                        name:
                            'Identify the government agency that will take part in the project implementation',
                        details:
                            'Describe the specific activities and issues for which the identified government agency will coordinate the implementation and it`s role',
                    },
                    in_ndp: {
                        title:
                            'Please select National Development Plan if the project is included in the plan. Select Other if project is included in other strategic directives.',
                        yes: 'Yes',
                        other: 'Other',
                    },
                    ndp_type: {
                        title:
                            'Please identify the NDP type in which the project belongs to',
                        core: 'NDP Core Projects',
                        priority: 'NDP Priority Area',
                    },
                    ndp_name: 'Project Name',
                    ndp_page_no: 'Page in NDP',
                    focus_area: {
                        null: '',
                        title: 'Identify the focus area of the project',
                        wealth_creation: 'Wealth Creation',
                        inclusive_growth: 'Inclusive growth',
                        competitiveness: 'Competitiveness',
                    },
                    intervention:
                        'Identify the suitable intervention from the NDP to which the project is focusing on.',
                    other_strategic_directives: 'Other Strategic Directives',
                    other_plans: {
                        development_partners: 'Development partners',
                        cabinet_directives: 'Cabinet directives',
                        parliament_approval: 'Parliament approval',
                        stare_owned_enterprise: 'Stare owned enterprise',
                        regional_integration_policy:
                            'Regional integration policy',
                        political_manifestos: 'Political manifestos',
                        interest_groups_or_beneficiaries:
                            'Interest groups or beneficiaries',
                        private_sponsors: 'Private sponsors',
                        sustainable_development_goals:
                            'Sustainable development goals',
                    },
                    plan_details:
                        'Give a clear description of the project in relation to the NDP',
                    //--officer
                    officer_title: 'Officer Title',
                    officer_name: 'Officer Name',
                    officer_phone: 'Officer Phone',
                    officer_email: 'Officer Email',
                    officer_mobile_phone: 'Officer Mobile Phone',
                    //introduction
                    rational_study:
                        'Please provide a detailed description / justification for undertaking the project',
                    methodology:
                        'Please describe the approach and methodology adopted in conducting the Pre- Feasibility Study.',
                    organization_study:
                        'Describe how the Pre-Feasibility Study is organized.',
                    organization_study_pfs:
                        'Organisation of the Pre-feasibility Study',
                    organization_study_fs:
                        'Organisation of the Feasibility Study',
                    additional_info:
                        'Provide any additional information necessary for the project',
                    attachments: {
                        title: 'Mandatory attachments',
                        add: 'Add Attachment',
                    },
                    benefits: 'Benefits',
                    goal:
                        'Describe the project goal.  This should be in relation to the development objective that the project is designed to contribute to.  Identify an indicator that will be used to measure success of the project against the goal and briefly explain how information on this indicator shall be obtained.',
                    commentaries: 'Commentaries',
                    files: 'Project Files',
                    //--demand_analys
                    demand_analysis:
                        'Identifies the need for public investment by assessing: (i) current demand (based on statistics provided by service suppliers/ regulators/ ministries/ national and regional statistical offices for the various types of users); (ii) future demand (based on reliable demand forecasting models) in both the scenarios with- and without the project',
                    demand_analysis_files: 'Demand Analysis Files',
                    //
                    pre_feasibility_cost: 'Pre-Feasibility Cost',
                    pre_feasibility_consultants: 'Pre-Feasibility Consultants',
                    strategic_analysis: 'Strategic Analysis',
                    strategic_alignment: 'Strategic Alignment',
                    //FinancialAnalysisForm
                    financing_modality: 'Financing Modality',
                    feasbility_cost: 'Feasibility Cost',
                    feasibility_consultants: 'Feasibility Consultants',
                    feasibility_funds_request: 'Feasibility Funds Request',
                    env_impact_assessment: 'Environmental Impact Assessment',
                    //modules
                    technical_design: `A summary of the proposed project solution shall be presented with the following headings
(i)	Location: description of the location of the project including a graphical illustration (map). Availability of land is a key aspect: evidence should be provided that the land is owned (or can be accessed) by the beneficiary, who has the full title to use it, or has to be purchased (or rented) through an acquisition process. In the latter case, the conditions of acquisition should be described. The administrative process and the availability of the relevant permits to carry out the works should also be explained.
(ii)	Technical design: description of the main works components, technology adopted, design standards and specifications. Key output indicators, defined as the main physical quantities produced (e.g. kilometers of pipeline, number of overpasses, number of trees planted, etc.), should be provided.
(iii)	Production plan: description of the infrastructure capacity and the expected utilization rate. These elements describe the service provision from the supply side. Project scope and size should be justified in the context of the forecasted demand.`,
                    production_plan: 'Production Plan',
                    hr_requirements: 'HR Requirements',
                    legal_assessment: 'Legal Assessment',
                    financial_evaluation: 'Financial/Private Evaluation',
                    economic_evaluation: 'Economic/Social Evaluation',
                    risk_evaluation: 'Risk Evaluation',
                    distributional_assessment: 'Distributional Assessment',
                    exec_management_plan:
                        'Describe the key headline information from the components of the feasibility study assessment the option evaluated, key results and recommendations.',
                    sustainability_assessment: 'Sustainability Assessment',
                    //TechnicalApproachForm
                    pcn_outcome: 'Outcomes',
                    pcn_interventions: 'Interventions',
                    investments: 'Investment Costs',
                    //--background
                    in_ndpi: 'Already Existing in NDPI',
                    in_ndpii: 'Already Existing in NDPII',
                    in_mfped: 'Already Existing in MFPED PIP',
                    strategic_other_plan: 'Strategic Other Plan',
                    strategic_considerations: 'Other Strategic Considerations',
                    strategic_fit: 'Strategic Fit',
                    strategic_alignment: 'Strategic Alignment',
                    outcome_base: 'Indicator Baseline Year',
                    outcome_targets: 'Outcome Indicator Target Years',
                    output_base: 'Output Indicator Baseline',
                    output_targets: 'Output Indicator Target Years',
                },
            },
            organizations: {
                fields: {
                    code: 'Organisation Code',
                    name: 'Organisation Name',
                },
            },
            departments: {
                fields: {
                    code: 'Sub Program Code',
                    name: 'Sub Program Name',
                    organization_id: 'Organisation Name',
                    vote_id: 'Vote Name',
                    sector_id: 'Sector Name',
                },
            },
            funds: {
                fields: {
                    code: 'Fund Code',
                    name: 'Fund Name',
                },
            },
            'fund-sources': {
                fields: {
                    code: 'Source of Fund Code',
                    name: 'Source of Fund Name',
                    fund_id: 'Fund Name',
                },
            },
            votes: {
                fields: {
                    code: 'Vote Code',
                    name: 'Vote Name',
                    sector_id: 'Sector Name',
                },
            },
            'vote-functions': {
                fields: {
                    code: 'Program Code',
                    name: 'Program Name',
                    vote_id: 'Vote Name',
                },
            },
            sectors: {
                fields: {
                    code: 'Sector Code',
                    name: 'Sector Name',
                },
            },
            outcomes: {
                fields: {
                    title:
                        'Define the project outcomes to include the effects that will follow from the utilization of products or services delivered by the project. These could be the eventual benefits to society that the project interventions are intended to achieve and are reflected in terms of  what people will be able to do better, faster, or more efficiently, or what they could never do before.',
                },
                actions: {
                    create: 'Create Outcome',
                    edit:
                        'For each project outcome identified, you are required to define at least one indicator that will be used to measure performance of the project against the relevant outcome and briefly explain how information on this indicator shall be obtained.',
                },
            },
            outputs: {
                fields: {
                    outcome_ids: 'Outcomes',
                    title: `Describe the direct/tangible results that the project is expected to deliver.  These outputs shall be the basis for the components around which the project shall be built. Some examples of project deliverables could be: trainings equipment purchased, ICT backbone developed, infrastructure built / renovated and etc. Outputs are usually the immediate and concrete consequences of the implemented activities and resources used..
    With the exception of the project management, monitoring and evaluation components, the project outputs should relate to physical assets and must contribute at-least 70% of the total project.`,
                    description:
                        'Clearly describe the output. Describe the tangible products and services delivered or competences and capacities established directly as a result of the project',
                    vote_id:
                        'Identify the implementing agency that will undertake the identified output.',
                    output_value: 'Output quantity',
                    output_unit: 'Unit',
                    investments: {
                        name: 'Outputs Investments',
                        fields: {
                            fund_id: 'Fund',
                            fund_source_id: 'Fund sources',
                            total: 'Total',
                            cost_classification_id: 'Cost Classification',
                        },
                        actions: {
                            edit: 'Estimated Cost',
                        },
                    },
                },
            },
            indicators: {
                fields: {
                    title:
                        'For each project output identified, you will be required to define at least one indicator that will be used to measure performance of the project against the relevant output and briefly explain how information on this indicator (s) shall be obtained.  This could be through surveys or secondary data sources',
                    baseline:
                        'Identify a standard that will be used to measure the performance of the project',
                    verification_means:
                        'Define the exact source of data that will be used to evaluate and measure performance',
                    assumptions:
                        'Describe the conditions that must be met to obtain the expected project objectives.',
                    risk_factors:
                        'Clearly provide information about the risks that may affect the project activities',
                    target:
                        'Identify the expected performance of the project in each financial year.',
                },
            },
            'project-categories': {
                fields: {
                    project_categories_id: 'Project Category Id',
                    title: 'Project Category Title',
                    last_phase_id: 'Last Phase',
                },
            },
            activities: {
                fields: {
                    title:
                        'For each output briefly highlight the major activities that you propose to implement in order to achieve the output',
                    start_date:
                        'For the given activity identify the financial year when it’s expected to commerce',
                    end_date:
                        'Provide the expected end date of the define activity',
                    description: 'Describe specific details of the activities.',
                    output_ids:
                        'For the given activity identify the corresponding output',
                    vote_id:
                        'For the given activity identify the corresponding votes',
                    investments: {
                        fields: {
                            fund_id: 'Fund',
                            fund_source_id: 'Fund sources',
                            total: 'Total',
                            cost_classification_id: 'Cost Classification',
                        },
                        actions: {
                            edit: 'Estimated Cost',
                        },
                    },
                },
            },
            options_appraisals: {
                fields: {
                    title:
                        'Describe the title of the option intended to achieve the project objective.',
                    description:
                        'Describe the option identified clearly detailing its components.',
                    cost: 'Identify the investment cost for this option',
                    score:
                        'The option score is autogenerated after populating the building blocks',
                    funding_modality: {
                        title:
                            'Identify the preferred funding modality suitable for the option.',
                        title_pfs:
                            'Identify the preferred Implementation modality suitable for the option.',
                        procurement: 'Traditional procurement',
                        partnership: 'Public private partnership',
                    },
                    is_best: 'Identify Preferred Alternative',
                    justification:
                        'Provide a detailed description for the selection of the option for the project intervention.',
                    modality_justification:
                        'Provide a detailed description for the selection of the option for the project preferred Funding Modality.',
                    modality_justification_pfs:
                        'Provide a detailed description for the selection of the option for the project intervention.',
                    stepper: {
                        description: 'Description',
                        building_blocks: 'Building Blocks',
                        analytical_modules: 'Analytical Modules',
                        best_option: 'Preferred Alternative Selection',
                    },
                    building_blocks: {
                        description: {
                            demand_module:
                                'Describe the nature and source of demand, nature of the market, prices and qualities of the project.',
                            technical_module:
                                'Determine and specify in detail the technical parameters, investment and operational costs',
                            env_module:
                                'Determine and specify the environmental impacts and risks, possible compensation for the ecological damages and where possible qualify the environmental impacts.',
                            hr_module:
                                'Determine the human resource requirements for implementation and operation, in terms of quantities and specialties; identifies the sources of the work force and the cost of employing them. In addition, determine the management capacity and the functional structure of the operating entity.',
                            legal_module:
                                'Describe the legal restrictions that may obstruct or impede construction or operation, for example, limitations in localization and in the use of land, special tax considerations, and guarantees in the case of Public- Private Partnership among others.',
                        },
                        advantage: {
                            demand_module:
                                'Identify the advantages of the option in relation to demand module',
                            technical_module:
                                'Identify the advantages of the option in relation to the technical and engineering module',
                            env_module:
                                'Identify the advantages of the option in relation to the environmental module',
                            hr_module:
                                'Identify the advantages of the option in relation to the HR requirement module',
                            legal_module:
                                'Identify the advantages of the option in relation to the legal assessment module',
                        },
                        disadvantage: {
                            demand_module:
                                'Identify the disadvantages of the option in regard to demand module.',
                            technical_module:
                                'Identify the disadvantages of the option in relation to the technical and engineering module',
                            env_module:
                                'Identify the advantages of the option in relation to the environmental module',
                            hr_module:
                                'Identify the disadvantages of the option in relation to the HR requirements module',
                            legal_module:
                                'Identify the disadvantages of the option in relation to the legal assessment module',
                        },
                        score: 'Score',
                        modules: {
                            demand_module: 'Demand Analysis',
                            technical_module: 'Technical & Engineering',
                            env_module: `Describe and specify the economic effects of environmental norms and possible compensations for ecological damages. Key questions to address:
The likely environmental impacts from undertaking project?
(i)	What is the cost of reducing the negative impact?
(ii)	Evaluation of the environmental impacts and risks with and without
(iii)	Technical measures are taken to reduce these impacts?
(iv)	Are there alternative ways of supplying the good or service of project without incurring these environmental costs?
(v)	What are the costs of these alternatives?`,
                            hr_module: `Point out the human resource requirements for implementation and operation, in terms of quantities and specialties; identifies the sources of the work force. Also it determines the management capacity and the functional structure of the operating entity.  Key Issues to be addressed:
(i)	What are the managerial and labour needs of the project?
(ii)	Does organisation have the ability to get the managerial skills
Needed?
(iii)	Is timing of project consistent with quantity and quality of
(iv)	Management?
(v)	What are wage rates for labour skills required?
(vi)	Manpower requirements by category are reconciled with
(vii)	availabilities and project timing.`,
                            legal_module: `Studies the legal restrictions that may obstruct or impede construction or operation, for example, limitations in localization and in the use of soil, special tax considerations in the case of public–private partnerships, etc.
(i)	Is the entity that is supposed to manage the project properly
organized and its management adequately equipped to handle the
Project?
(ii)	Are the capabilities and facilities being properly utilized?
(iii)	Is there a need for changes in the policy and institutional set up
(iv)	Outside this entity? What changes may be needed in policies of the Local, regional and central governments?`,
                        },
                    },
                    analytical_modules: {
                        description: 'Description',
                        advantage: 'Advantages',
                        disadvantage: 'Disadvantages',
                        score: 'Score',
                        appraisal_methodology: {
                            title: 'Appraisal Methodology',
                            cea: 'CEA',
                            cba: 'CBA',
                        },
                        financial_evaluation: {
                            appraisal_methodology:
                                'Select the appraisal methodology to be used either CBA or CEA',
                            fnpv:
                                'State the Financial Net Present Value obtained using the selected appraisal methodology',
                            irr:
                                'State the Internal Rate of Return obtained using the selected appraisal methodology',
                            summary: `Describe the financial costs and benefits at market prices, studies alternative financial leverage methods.
(i)	Integration of financial and technical variables from demand module, technical module, and management module
(ii)	 Construct cash flow (resource flow) profile of project
(iii)	Identify key variables for doing economic and social analysis

Key questions:
a. What is relative certainty of financial variables?
b. What are sources and costs of financing?
c. What are minimum cash flow requirements for each of the stakeholders?
d. What can be adjusted to satisfy each of the stakeholders?`,
                        },
                        economic_evaluation: {
                            appraisal_methodology:
                                'Select the appraisal methodology to be used either CBA or CEA',
                            enpv:
                                'State the Economic Net Present Value obtained using the selected appraisal methodology',
                            err:
                                'State the Economic Rate of Return obtained using the selected appraisal methodology',
                            summary: `Economic adjustments from financial data using conversion factors; after that, costs and benefits are appraised from the point of view of the entire economy.
(i)	Examines the project using the whole country as the accounting
entity
(ii)	Evaluation of externalities including environmental
(iii)	What are differences between financial and economic values for a
Variable?
(iv)	What causes these differences?
(v)	With what degrees of certainty do we know values of these
Differences?
(vi)	What is the expected value of economic net benefits?
(vii)	What is the probability of positive economic feasibility?`,
                        },
                        criterias: {
                            title: 'Cost Effectiveness Results',
                            criteria_title:
                                'Describe the Cost Effectiveness Analysis method that will be used',
                            criteria_value:
                                'State the value obtained using the CEA method selected',
                            measure_unit:
                                'Identify the measurement units that are used in the analysis',
                        },
                        stakeholder_evaluation: {
                            title:
                                'Identify the stakeholder that will be affected by the project',
                            description:
                                'Give a detailed description of the stakeholder.',
                            impact_sign: {
                                title:
                                    'Select the likely impact of the project on the stakeholders',
                                positive: 'Positive',
                                negative: 'Negative',
                            },
                            beneficiary: {
                                title:
                                    'Identify the relationship of the stakeholder to the project',
                                direct: 'Direct',
                                indirect: 'Indirect',
                            },
                        },
                        risk_evaluation: {
                            description:
                                'identify the uncertainty that may arise during the lifetime of the project.',
                            occurrence:
                                'Select the likelihood of occurrence of the uncertainty',
                            impact:
                                'Select the impact of the risk on the project',
                            levels: {
                                low: 'Low',
                                medium: 'Medium',
                                high: 'High',
                                very_high: 'Very High',
                            },
                            mitigation_plan:
                                'Identify and generate guidance on how to reduce the exposure of the risk through different contractual clauses.',
                        },
                        modules: {
                            financial: 'Financial',
                            economic: 'Economic',
                            distributional: 'Distributional',
                            risk: 'Risk',
                        },
                    },
                },
            },
            risk_factors: {
                fields: {
                    risk_level: 'Risk Level',
                    measures: 'Measures',
                    risk_description: 'Risk Description',
                },
            },
            'file-types': {
                fields: {
                    is_required: 'Is Required',
                    phase_ids: 'Project Phases',
                    name: 'File Type Name',
                    extensions: 'File Type Extension',
                },
            },
            users: {
                name: 'User Management',
                fields: {
                    username: 'Username',
                    email: 'E-mail',
                    fullname: 'Full Name',
                    phone: 'Phone',
                    vote: 'Vote',
                    role: 'Role',
                    password_hash: 'Enter New Password',
                    sector_id: 'Sector Name',
                    vote_id: 'Vote Name',
                    department_id: 'Sub Program Name',
                    sector_ids: 'Name of Sectors',
                    donor_id: 'Donors Name',
                },
            },
            'cost-classifications': {
                fields: {
                    code: 'Code',
                    name: 'Name',
                    cost_category_id: 'Cost Category',
                },
            },
            'cost-categories': {
                fields: {
                    code: 'Code',
                    name: 'Name',
                    expenditure_type: 'Expenditure Type',
                },
            },
        },
        workflow: {
            assign: 'Select assigned user',
            decision: 'Input decision reason',
            assign_user: 'Assigned user',
        },
    },
    printForm: {
        introduction: {
            title: 'Introduction',
        },
        project_framework: {
            title_logical: 'Logical Framework',
            title: 'Project Framework',
            goal: 'Project Goal',
            outcome: 'Outcome |||| Outcomes',
            output: 'Output |||| Outputs',
            activity: 'Activity |||| Activities',
        },
        result_matrix: {
            indicator: 'Performance Indicators',
            indicator_title: 'Indicator',
            means: 'Means of Verification',
            assumption: 'Assumptions',
            risks: 'Risks',
            baseline: 'Baseline',
            target: 'Target',
            project_goal: 'Project Goal',
            activities_title: 'Activities to achieve the outputs',
        },
        attachments: {
            title: 'List of Attachments',
            no_attachments: 'No attachments',
        },
        investments: {
            title: 'Costed Annualized Plan', //'Estimated Project Cost and Activity Plan',
            subtitle: 'Project Investment Cost',
            outputs_title: 'Costed Annualized Work Plan',
            total: 'Sub-Total',
            total_costs: 'Total Costs',
            invest_title: '',
            code: 'Code',
            name: 'Name',
            funds: 'Sources of Funds',
            period: 'Period',
            fund_id: 'Fund',
        },
        background: {
            title: 'Project Background',
            situation_analysis: 'Situational Analysis',
            problem_statement: 'Problem Statement',
            problem_cause: 'Problem Causes',
            problem_effects: 'Problem Effects',
            justification: 'Justification',
            stakeholders: 'Stakeholders',
            location: 'Location',
            geo_location: 'Geo Location',
            technical_description: 'Technical Description',
            government_agencies: 'Coordination with Other Government Agencies',
            government_agencies_empty:
                'Coordination with Other Government Agencies is empty',
            strategic_fit: 'Strategic Fit',
            strategic_alignment: 'Strategic Alignment',
            strategic: 'Strategic Directive',
            details: 'Details',
            in_ndp: 'Project in NDP',
            ndp_project_name: 'Project Name',
            ndp_page_no: 'Page in NDP',
            ndp_focus_area: 'Focus Area',
            ndp_interventions: 'Interventions',
            ndp_project_name: 'Project Name',
            implementing_agencies: 'Implementing Agencies',
            om_costs: 'Operation and Maintenance Costs (O&M Costs, UGX)',
        },
        project_info: {
            responsible_officers: {
                title: 'Officer Title',
                name: 'Officer Name',
                phone: 'Officer Phone',
                email: 'Officer Email',
                mobile_phone: 'Officer Mobile Phone',
            },
            current_investment_ratio: 'Capital to Recurrent Ratio',
            project_no: 'Project No',
            project_title: 'Project Title',
            project_duration: 'Project Duration',
            project_cost: 'Estimated Project Cost',
            officer: 'Responsible Officer',
            officer_name: 'Name',
            officer_contacts: 'Contacts',
            officer_email: 'Email',
            officer_mobile: 'Mobile Phone',
            officer_phone: 'Phone',
            project_date: 'Date of Submission',
            project_approved: {
                phase_2: 'Date of Approval of Concept note',
                phase_3: 'Date of submission of the profile',
                phase_4: 'Date of approval of the Pre-feasibility Study',
                phase_5: 'Date of approval of the Feasibility Study',
            },
            executive_summary: 'Executive Summary',
            additional_info: 'Additional Information',
            gantt_chart: 'Gantt Chart',
            gantt_chart_title: 'Output / Activity Title',
        },
        funding: {
            title: 'Proposed Fundings',
            fund: 'Fund',
            ugx: 'UGX billion',
        },
        introduction: {
            title: 'Introduction',
        },
        options: {
            title: 'Strategic Options Analysis',
            name: 'Option Name',
            cost: 'Project Cost',
            funding_modality: 'Funding Modality',
            score: 'Total Score',
            modular_scores: 'Modular Scores',
            demand_module: 'Demand',
            tech_module: 'Technical',
            env_module: 'Environment',
            hr_module: 'HR',
            legal_module: 'Inst. & Legal',
            detailed_analysis: 'Detailed Analysis for',
            detailed_analysis_empty: 'No Detailed Analysis',
            option_title: 'Option',
            header_title: 'Title',
            description: 'Description',
            advantage: 'Advantages',
            disadvantage: 'Disadvantages',
            cea: 'CEA',
            cba: 'CBA',
            cba_cea: 'CBA/CEA',
            financial_evaluation: {
                title: 'Financial Appraisal',
                summary: 'Summary of Financial Evaluation:',
                fnpv: 'FNPV',
                irr: 'IRR',
                cba: 'CBA',
                cea: 'CEA',
            },
            economical_evaluation: {
                title: 'Economic or Social Appraisal',
                summary: 'Summary of Economic Evaluation:',
                enpv: 'ENPV',
                err: 'ERR',
                cba: 'CBA',
                cea: 'CEA',
                option_title: 'Option',
            },
            justification: {
                best_options_empty: 'No Preferred Alternative Selected',
                title: 'Justification for the Preferred Project Alternative',
                description: 'Preferred Alternative Description',
                alternative: 'Alternative',
                alternative_text:
                    'was identified as a best alternative to proceed to the feasibility study stage. Please refer to details below.',
                details: 'Justification for Preferred Alternative',
                modality: 'Justification for the Preferred Funding Modality',
            },
            risk_evaluation: {
                title: 'Risk Management',
                empty: 'No Risk Evaluation',
                description: 'Risk Description',
                occurrence: 'Likelihood of Occurrence (Low to Very High)',
                impact: 'Impact (Low to Very High)',
                mitigation_plan: 'Mitigation Plan',
                analysis: 'Risk Analysis for',
            },
            stakeholder_evaluation: {
                title: 'Distributional/Stakeholder Impact Assessment',
                empty: 'No stakeholders',
                number: 's/no',
                name: 'Identity of Stakeholder',
                impact_sign: 'Impact Positive/Negative',
                beneficiary: 'Beneficiary Direct/Indirect',
                description: 'Description',
                analysis: 'Distributional/Stakeholder Analysis for ',
            },
            criterias: {
                title: 'Cost Effectiveness Results',
                criteria_title: 'Title',
                criteria_value: 'Value',
                measure_unit: 'Measure unit',
            },
        },
    },
    validation: {
        government_coordination: 'Coordination with Other Government Agencies',
        options_appraisal_empty: 'You must create at least one option',
        empty_fields: 'These Fields Must Be Filled',
        plan_details: 'Details',
        sector_id: 'Name of Sector',
        vote_id: 'Vote',
        vote_function_id: 'Program Name',
        pillar_id: 'Pillar Name',
        subprogram_id: 'Subprogramm Name',
        implementing_agencies: 'Sectors responsible for implementation',
        title: 'Project Ttle',
        summary: 'Technical description',
        start_date: 'Start date',
        end_date: 'End Date',
        location: 'Location',
        geo_location: 'Geo Location',
        administrative_post: 'Administrative Post',
        district_id: 'Name Districts',
        province_id: 'Name of Provinces',

        exec_management_plan: 'Executive summary',

        officer_title: 'Title Officer',
        officer_name: 'Name Officer',
        officer_phone: 'Officer Phone',
        officer_email: 'Officer Email',
        officer_mobile_phone: 'Officer Mobile Phone ',

        situation_analysis: 'Situation Analysis',
        problem_statement: 'Problem Identification',
        problem_cause: 'Causes of the Problem',
        problem_effects: 'Effects of the Problem',
        justification: 'Justification',
        stakeholders: 'Stakeholders',
        goal: 'Project goal',
        goal_indicators: 'Goals Indicators',
        outcomes: 'Outcomes',
        outcomes_indicators: 'Outcomes  Indicators',
        outputs: 'Outputs',
        outputs_indicators: 'Outputs Indicators',
        outputs_investments: 'Outputs Cost of Investment',
        activities: 'Activities',
        activities_investments: 'Activities Cost of Investments',
        outcome_base: 'Baseline of the Result Indicator',
        ndp_type: 'NDP Type',
        om_costs: 'O & M Costs',

        rational_study: 'Rationality of the Study',
        methodology: 'Methodology',
        organization_study: 'Organisation of the PFS and FS',
        organization_study_pfs: 'Organisation of the Pre-feasibility Study',
        organization_study_fs: 'Organisation of the Feasibility Study',
        beneficiary_analysis: 'Analysis of Beneficiaries',
        strategic_alignments: 'Strategic Alignments',

        options_appraisal: {
            all_fields: 'All Fields',
            summary: {
                description: 'Description',
                title: 'Title',
                cost: 'Cost',
                score: 'Total Result',
                funding_modality: 'Preferential Financing Mode',
            },
            building_blocks: {
                description: 'Description',
                advantage: 'Advantages',
                disadvantage: 'Disadvantages',
                score: 'Score',
                modules: {
                    demand_module: 'Demand Analysis',
                    technical_module: 'Technical & Engineering',
                    env_module: 'Environmental',
                    hr_module: 'HR requirements',
                    legal_module: 'Legal Evaluation',
                },
            },
            financial_evaluation: {
                title: 'Financial Evaluation',
                summary: 'Financial Analysis Details',
                fnpv: 'FNPV',
                irr: 'IRR',
                cea: 'Cost-Effectiveness Analysis',
                criterias: 'Cost Effectiveness Results',
            },
            economic_evaluation: {
                title: 'Economic evaluation',
                summary: 'Summary of economic evaluation',
                enpv: 'ENPV',
                err: 'ERR',
                cea: 'Cost-Effectiveness Analysis',
                option_title: 'Option Title',
                criterias: 'Cost Effectiveness Results',
            },
            risk_evaluation: {
                title: 'Risk management',
                empty: 'No risk assessment',
                description: 'Risk Description',
                occurrence: 'Probability of Occurrence (Low to Very High)',
                impact: 'Impact (low to very high)',
                mitigation_plan: 'Mitigation Plan',
                analysis: 'Risk analysis for',
            },
            stakeholder_evaluation: {
                title: 'Impact Assessment on Distribution',
                empty: 'No Stakeholders',
                number: 's/no',
                name: 'Stakeholder Identity',
                impact_sign: 'Positive Impact / Negative',
                beneficiary: 'Direct / Indirect Beneficiary',
                description: 'Description',
            },
        },
    },
};
