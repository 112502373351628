import { API_URL, PAP_DEPARTMENT_USERS, PROJECT_PHASES, USER_ROLES, DEFAULT_SORTING } from '../../constants/common';
import { Bar, Line } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import {
    Datagrid,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    Responsive,
    SelectInput,
    SimpleList,
    TextField,
    TextInput
} from 'react-admin';
import React, { Component, Fragment } from 'react';
import { dateFormatter, getCurrentPhaseId } from '../Projects/helpers';

import CustomShowButton from '../Projects/ToolBar/CustomShowButton';
import CustomShowEventButton from '../Projects/ToolBar/CustomShowEventButton';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import Widget01 from '../Widgets/Widget01';
import dataProvider from '../../providers/dataProvider';
import { linkToRecord } from 'ra-core';
import lodash from 'lodash';
import moment from 'moment';

const cardChartOpts4 = {
        animation: {
            duration: 2000,
        },
        tooltips: {
            enabled: false,
            custom: CustomTooltips,
            mode: 'label',
            callback: function(value) {
                return value
            },
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
            labels: {

            }
        },
        scales: {
            xAxes: [
                {
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                        stepSize: 1,
                        min: 0,
                        autoSkip: false,
                        callback: function(value) {
                            if (value.length > 25) {
                                return value.substr(0, 23) + '...'; //truncate
                            } else {
                                return value
                            }
                        },
                    }
                }],
            yAxes: [
                {
                    display: false,
                }],
        },
    };
const COLORS = {
    '1': 'yellow',
    '2': 'blue',
    '3': 'purple',
    '4': 'teal',
    '5': 'green',
    '6': 'green',
}

const chartStyle = {
    position: 'relative',
    padding: '20px 30px',
    margin: '15px auto'
}

const ProjectsFilter = props => (
    <Filter {...props}>
        <TextInput label="Project Number" source="project_no" alwaysOn />
        <TextInput label="Project Title" source="title" alwaysOn />
       
        <ReferenceInput 
            sort={DEFAULT_SORTING}
            perPage={-1} 
            source="sector_id" 
            reference="sectors" 
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput 
            sort={DEFAULT_SORTING}
            perPage={-1} 
            source="department_id" 
            reference="departments" 
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput 
            sort={DEFAULT_SORTING}
            perPage={-1} 
            source="vote_id" 
            reference="votes" 
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export class Dashboard extends Component {
    state = {
        data: [],
        dashboardData: [],
        totalCount: 0
    }
    componentDidMount() {
        this.setState({ isFetching: true })
        dataProvider('GET_ONE', 'reports', { id: 'project-count-by-phases' })
            .then(response => {
                if (response && response.data) {
                    this.setState({ data: response.data, isFetching: false, totalCount:  lodash.sumBy(response.data, 'total') })
                }
            })
            .catch((err) => {
                this.setState({ isFetching: false })
            })
        dataProvider('GET_ONE', 'reports', { id: 'grouped-project-count' })
            .then(response => {
                if (response && response.data) {
                    const dashboardData = {
                        labels: response.data.map((item) => item.title),
                        datasets: [
                            {
                                label: 'Groupped Projects',
                                backgroundColor: response.data.map((item) => "green"),
                                data: response.data.map((item) => item.total),
                            },
                        ],
                    }
                    this.setState({ dashboardData, isFetching: false })
                }
            })
            .catch((err) => {
                this.setState({ isFetching: false })
            })
    }

    

    renderStatus = (record) => {
        const { permissions, translate } = this.props;
        const role = permissions && JSON.parse(permissions.role);
        const roleType = lodash.first(lodash.keys(role));

        let workflowStatus = record.workflow.status;

        if ( getCurrentPhaseId(record) !== record.phase_id){
            workflowStatus = `${translate(`timeline.hidden_phase_status`)} ${translate(`resources.phases.phase_${getCurrentPhaseId(record)}`)}`;
        }
        
        if (record.workflow.in_pap && !PAP_DEPARTMENT_USERS.includes(roleType)) {
            workflowStatus = translate(`timeline.not_pap_status`) 
        }
            
        return record.status === 'rejected' || record.status === 'completed' 
            ? translate(`timeline.${record.status}`) 
            : workflowStatus
    }

    render() {
        const { permissions, translate } = this.props;
        const role = permissions && JSON.parse(permissions.role);
        const title =
            (role &&
                (lodash.first(lodash.keys(role)) === USER_ROLES.SU
                    ? translate('resources.dashboards.projects')
                    : translate('resources.dashboards.projects_waiting'))) ||
            '';
        const filterParams = role && (lodash.first(lodash.keys(role)) !== USER_ROLES.SU 
            ?   { type: 'pending' }
            :   null) || '';

            
        return (
            <Fragment>
                <Row>
                    {
                        role &&
                        <Col md={8}>                    
                            <h5>{title}</h5>
                            <List
                                {...this.props}
                                resource="projects"
                                bulkActions={false}
                                filters={<ProjectsFilter />}
                                filter={filterParams}
                                perPage={5}
                                actions={false}
                            >
                            <Responsive
                                small={
                                    <SimpleList
                                        linkType={false}
                                        primaryText={record => 
                                            <Link
                                                to={`${linkToRecord('projects', record.id)}/show/${record.phase_id}`}
                                            ><b>{record.title}</b></Link>
                                        }
                                        secondaryText={record => {
                                            return (
                                                <span className="small-list-item">
                                                    <span>{translate(`resources.projects.fields.project_no`)}: {`${record.project_no}`}</span>
                                                    <span>{translate(`resources.projects.fields.created_at`)}: {`${dateFormatter(record.created_at)}`}</span>
                                                    <span>{translate(`resources.projects.fields.phase_id`)}: {translate(`resources.phases.phase_${record.phase_id}`)}</span>
                                                    <span>{translate(`resources.projects.fields.status`)}: {translate(`timeline.${record.status}`)}</span>
                                                    <span><b>{this.renderStatus(record)}</b></span>
                                                </span>
                                            )
                                        }}
                                    />
                                }
                                medium={
                                    <Datagrid>
                                        <TextField source="project_no" />
                                        <TextField source="title" />
                                        <FunctionField
                                            source="created_at"
                                            label={translate(
                                                `resources.projects.fields.created_at`,
                                            )}
                                            render={record => dateFormatter(record.created_at)}
                                        />
                                        <FunctionField
                                            source="status"
                                            label={translate(
                                                `resources.projects.fields.status`,
                                            )}
                                            render={record =>
                                                record && record.current_step <= 2
                                                    ?   translate(`timeline.${record.status}`)
                                                    :    <CustomShowEventButton
                                                            label={translate(`timeline.${record.status}`)}
                                                            record={record}
                                                            {...this.props}
                                                        />
                                            }
                                        />
                                    
                                        <ReferenceField
                                            addLabel={false}
                                            source="phase_id"
                                            reference="phases"
                                            linkType={null}
                                        >
                                            <FunctionField
                                                label={translate(
                                                    `resources.projects.fields.phase_id`,
                                                )}
                                                render={record =>
                                                    translate(
                                                        `resources.phases.phase_${record.id}`,
                                                    )
                                                }
                                            />
                                        </ReferenceField>
                                        <FunctionField
                                            source="current_step"
                                            label={translate(
                                                `resources.projects.fields.workflow.status`,
                                            )}
                                            render={record =>
                                                this.renderStatus(record)
                                            }
                                        />
                                        <CustomShowButton basePath="/projects" />
                                    </Datagrid>
                                }
                            />
                            </List>
                            <br/>
                            {
                                this.state.dashboardData &&
                                    <div  style={{ height: '550px', padding: '35px' }}>
                                        <h5>Groupped Projects</h5>
                                        <Bar data={this.state.dashboardData} options={cardChartOpts4} height={500} />
                                    </div>
                            }
                    </Col>
                }
                  <Col md={4}>
                  {
                      this.state.data.length !== 0 &&
                      <Fragment>
                            <h5>{`Projects By Phases  (${this.state.totalCount})`}</h5>
                            {
                                this.state.data.map((item) =>
                                    <Widget01 
                                        bgColor={COLORS[item.phase_id]}
                                        mainText={PROJECT_PHASES[item.phase_id]}
                                        value={parseInt(item.total * 100 / this.state.totalCount)}
                                        header={item.total}
                                    />
                                )
                            }
                      </Fragment>
                  }
                  </Col>
                </Row>
            </Fragment>
        );
    }
  }
