import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    FunctionField,
    number
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';
import { DEFAULT_SORTING } from '../../constants/common';

export const CurrencyRatesCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText="name">
                <ReferenceInput
                    perPage={-1}
                    sort={DEFAULT_SORTING}
                    label="Currency"
                    source="currency_id"
                    reference="currencies"
                    validate={required()}
                >
                    <SelectInput
                        optionText="name"
                        options={{ fullWidth: 'true' }}
                    />
                </ReferenceInput>
            </CustomInput>
            <CustomInput tooltipText="name">
                <TextInput source="rate" validate={[required(), number()]} />
            </CustomInput>
            <br/>
        </SimpleForm>
    </Create>
);

export const CurrencyRatesEdit = props => (
    <Edit {...props}>
        <SimpleForm  toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText="name">
                <ReferenceInput
                    perPage={-1}
                    sort={DEFAULT_SORTING}
                    label="Currency"
                    source="currency_id"
                    reference="currencies"
                    validate={required()}
                >
                    <SelectInput
                        optionText="name"
                        options={{ fullWidth: 'true' }}
                    />
                </ReferenceInput>
            </CustomInput>
            <CustomInput tooltipText="rate">
                <TextInput source="rate" validate={[required(), number()]} />
            </CustomInput>
            <br/>
        </SimpleForm>
    </Edit>
);

const CurrencyRatesShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const CurrencyRatesShow = props => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>Currencies</strong>
            </CardHeader>
            <Show actions={<CurrencyRatesShowActions />} {...props}>
                <SimpleShowLayout>
                    <ReferenceField
                        source="currency_id"
                        reference="currencies"
                        linkType="show"
                    >
                        <FunctionField
                            label="Name"
                            render={record => `${record.abbr} - ${record.name} (${record.sign})`}
                        />
                    </ReferenceField>
                    <TextField source="rate" />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

export const CurrencyRatesList = props => {
    return (
        <List {...props} bulkActions={false}>
            <Datagrid>
                <ReferenceField
                    source="currency_id"
                    reference="currencies"
                    linkType="show"
                >
                    <FunctionField
                        label="Name"
                        render={record => `${record.abbr} - ${record.name} (${record.sign})`}
                    />
                </ReferenceField>
                <TextField source="rate" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default CurrencyRatesList;
