import { CardActions, Confirm, DeleteButton, EditButton, showNotification } from 'react-admin';
import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import { USER_ROLES } from '../../constants/common';
import { connect } from 'react-redux';
import dataProvider from '../../providers/dataProvider';
import lodash from 'lodash';

class UserManagmentShowActions extends Component {
    state = {
        isOpen: false,
        isFetching: false
    }

    handleResetPassword = (id) => {
        this.setState({ isFetching: true })
        dataProvider('RESET', 'users', { id })
            .then((response) => {
                this.setState({ isFetching: false })
                if (response) {
                    return this.props.showNotification(this.props.translate('messages.reset_password_success'))
                }
            })
            .catch((err) => {
                this.setState({ isFetching: false })
                return this.props.showNotification(this.props.translate('messages.reset_password_error') + err)
            })
    }

    handleDialogShow = () => {
        this.setState({ isOpen: true })
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false })
    };

    handleConfirm = () => {
        const { basePath, data, resource } = this.props;
        this.setState({ isOpen: false });
        this.handleResetPassword(data.id)
    };

    render() {
        const { permissions, translate, basePath, data, resource } = this.props;
        const role = permissions && JSON.parse(permissions.role);
        const userId = permissions && JSON.parse(permissions.user_id);
        const roleType = role && lodash.first(lodash.keys(role));

        return (
            <CardActions>
                {
                    data && Number(data.id) !== Number(userId) && roleType === USER_ROLES.ADMIN &&
                        <Button variant={"outlinedPrimary"} 
                            onClick={this.handleDialogShow}
                            disabled={this.state.isFetching}
                        >
                            <Refresh />{translate('buttons.reset_password')}        
                        </Button>
                }
                <EditButton basePath={basePath} record={data} />
                <DeleteButton basePath={basePath} record={data} resource={resource} />
                <Confirm
                    isOpen={this.state.isOpen}
                    title={translate('messages.reset_password')}
                    content={translate('messages.reset_password_confirmation')}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                    confirm={translate('buttons.confirm')}
                    cancel={translate('buttons.cancel')}
                />
            </CardActions>
        );
    }
}

export default connect(undefined, { showNotification })(UserManagmentShowActions);