import React, { Fragment } from 'react';
import { 
    FormDataConsumer, 
    TextInput, 
    translate, number,
    ReferenceInput,
    SelectInput,
    AutocompleteInput
} from 'react-admin';

import CustomInput from '../../../components/CustomInput';
import { commasFormatter, commasParser } from '../helpers';
import { getFiscalYearsRangeForIntervals, optionRendererCodeName } from '../../../helpers/formatters';
import { DEFAULT_SORTING } from '../../../constants/common';

const validateStock = [ number() ];

const getYears = (years) => {
    if (years) {
        const startDate = years[0];
        const endDate = years[years.length - 1];

        return getFiscalYearsRangeForIntervals(startDate, endDate);
    }

    return [];
}

const OMCostsForm = props => 
    <FormDataConsumer>
        {({ getSource, scopedFormData, formData, ...rest }) => {
            if (formData && !formData.is_omp) {
                return <h5>{props.translate('messages.om_cost')}</h5>
            }

            return (
                <div>
                    <h5>{props.translate(
                        'resources.project-details.fields.om_cost',
                    )}</h5>
                    <CustomInput
                        tooltipText={props.translate('tooltips.resources.om_costs.om_fund_id')}
                        fullWidth
                    >
                        <ReferenceInput
                            sort={DEFAULT_SORTING}
                            perPage={-1}
                            source={'om_fund_id'}
                            reference="funds"
                            label={props.translate('resources.project-details.fields.om_fund_id')}
                        > 
                        <SelectInput 
                            optionText="name"
                        />
                        </ReferenceInput>
                    </CustomInput>
                    <CustomInput
                        tooltipText={props.translate('tooltips.resources.om_costs.om_fund_source_id')}
                        fullWidth
                    >
                        <ReferenceInput
                            perPage={-1}
                            sort={DEFAULT_SORTING}
                            source={'om_fund_source_id'}
                            reference="fund-sources"
                            label={props.translate('resources.activities.fields.investments.fields.fund_source_id')}
                            filterToQuery={searchText => ({ "name": searchText })}
                            filter={{ fund_id: (formData && formData.om_fund_id) || null }}
                        >
                            <SelectInput 
                                options={{ fullWidth: 'true'}}
                                optionText={optionRendererCodeName}
                            />
                        </ReferenceInput>
                    </CustomInput>

                        <CustomInput
                            tooltipText={props.translate('tooltips.resources.om_costs.om_cost_category_id')}
                            fullWidth
                        >
                            <ReferenceInput
                                perPage={-1}
                                sort={DEFAULT_SORTING}
                                source={'om_cost_category_id'}
                                reference="cost-categories"
                                label={props.translate('resources.activities.fields.investments.fields.cost_category_id')}
                            >
                                <SelectInput 
                                   options={{ fullWidth: 'true'}}
                                   optionText={optionRendererCodeName}
                                />
                            </ReferenceInput>
                        </CustomInput>
                        
                        <CustomInput
                            tooltipText={props.translate('tooltips.resources.om_costs.om_cost_classification_id')}
                            fullWidth
                        >
                            <ReferenceInput
                                perPage={-1}
                                sort={DEFAULT_SORTING}
                                source={'om_cost_classification_id'}
                                reference="cost-classifications"
                                label={props.translate('resources.activities.fields.investments.fields.cost_classification_id')}
                                filter={{ cost_category_id: (formData && formData.om_cost_category_id) || null}}
                                filterToQuery={searchText => ({ "name": searchText })}   
                            >
                                <SelectInput 
                                   options={{ fullWidth: 'true'}}
                                   optionText={optionRendererCodeName}
                                />
                            </ReferenceInput>
                        </CustomInput>
                    
                    {
                        formData && getYears(formData.operating_years)
                            .map(year => {
                                return (
                                    <CustomInput 
                                        tooltipText={props.translate('tooltips.resources.om_costs.om_years')}
                                        fullWidth
                                    >
                                        <TextInput
                                            label={`${year.name}`}
                                            source={`om_cost[${year.id}]`}
                                            resource={formData}
                                            validate={validateStock}
                                            format={ commasFormatter }
                                            parse={ commasParser }
                                        />
                                    </CustomInput>
                                    
                                );
                            })
                    }
                </div>
            )
            
        }}
    </FormDataConsumer>

export default translate(OMCostsForm);
