// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
import { TOKEN } from '../constants/auth';
import { API_URL } from '../constants/common';

const uploadFiles = (item) => {
    const reader = new FileReader();
    reader.readAsDataURL(item.file.rawFile);
    reader.onload = () => {
        fetch(API_URL, {
            method: 'PUT',
            body: JSON.stringify({ 
                file: reader.result, 
                title:item.file.title 
            }),
        })
    }
}

const confertToMultiPart = item => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item.file.rawFile);

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject;
});

const token = sessionStorage.getItem(TOKEN);

const uploaderWrapper =  requestHandler => (type, resource, params) => {
    if (type === 'UPDATE') { //need to select resources for files
        if (params.data.files && params.data.files.length) {
            const newFiles = params.data.files.filter(item => 
                item.file && item.file.rawFile instanceof File);

                // uploadFiles(newFiles[0])
            if (!newFiles || newFiles && newFiles.length === 0) {
                return requestHandler(type, resource, {
                        ...params,
                        data: {
                            ...params.data,
                        },
                    });
            }
            // return 
            Promise.all(newFiles.map(confertToMultiPart))
                .then(convertedFiles => convertedFiles.forEach((file, index) => {
                    const options = {};
                    options.headers = new Headers({ Accept: 'application/json' });
                    options.headers.set('Authorization', `Bearer ${token}`);
                    options.headers.set('Content-Type', 'application/json');
                    options.body = JSON.stringify({ 
                        src: file, 
                        "title": `${newFiles[index].file.title}`,
                        "file_type_id": newFiles[index].file_type_id 
                            ? `${newFiles[index].file_type_id}`
                            : 0,
                        "entity_id": params.data.id,
                        "entity_type": "project_detail",
                        "related_field": "swg_file"
                    });
                    options.method = 'POST';
                    fetch(`${API_URL}/files`, {...options})
                }))
                .catch((err) => { console.log(err); })
        }
       
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default uploaderWrapper;