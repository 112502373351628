import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    number,
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';
import { DEFAULT_SORTING } from '../../constants/common';

export const FundSourceCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.code')}>
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.fund_id')}>
                <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="fund_id" reference="funds" validate={required()}>
                    <SelectInput optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceInput>
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const FundSourceEdit = props => (
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.code')}>
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.name')}>
                <TextInput source="name" validate={required()} />
            </CustomInput>
            <CustomInput tooltipText={props.translate('tooltips.resources.fund-sources.fields.fund_id')}>
                <ReferenceInput sort={DEFAULT_SORTING} perPage={-1} source="fund_id" reference="funds"  validate={required()}>
                    <SelectInput optionText="name" options={{ fullWidth: 'true' }}/>
                </ReferenceInput>
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const FundSourceShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const FundSourceShow = ({ translate, ...props }) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.fund-sources.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<FundSourceShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="code" />
                    <TextField source="name" />
                    <ReferenceField
                        source="fund_id"
                        reference="funds"
                        linkType="show"
                    >
                        <FunctionField
                            label="Name"
                            render={record => `${record.code} - ${record.name}`}
                        />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

const FundSourceFilter = props => (
    <Filter {...props}>
        <TextInput source="code" />
        <TextInput source="name" />
        <ReferenceInput
            sort={DEFAULT_SORTING}
            perPage={-1}
            source="fund_id"
            reference="funds"
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const FundSourceList = props => {
    return (
        <List {...props} filters={<FundSourceFilter />} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <ReferenceField
                    source="fund_id"
                    reference="funds"
                    linkType="show"
                >
                    <FunctionField
                        label="Name"
                        render={record => `${record.code} - ${record.name}`}
                    />
                </ReferenceField>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default FundSourceList;
