import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import lodash from 'lodash';

import '../styles.scss';
import { getFiscalYears, getFiscalYearsRange, getFiscalYearsRangeForIntervals, romanize } from '../../../helpers/formatters';

const filledCell = {
    background: 'blue',
    padding: '12px 0px'
};

class GanttChart extends Component {

    render() {
        const { record, counter } = this.props;
        let fiscalYearsFromProps;

        if ( record.investment_years ) {
            const startDate = record.investment_years[0];
            const endDate = record.investment_years[record.investment_years.length - 1];
            fiscalYearsFromProps = record.investment_years && 
                getFiscalYearsRangeForIntervals(startDate, endDate);
        }
        
        return (
            <div className="landscapeSection">
                <div className="content-area">
                <h4>{romanize(counter)}. Gantt Chart</h4>
                <Table responsive bordered className="gantt-chart-table">
                    <thead>
                        <tr className="filled-row">
                            <th style={{ textAlign: 'center' }}>Output / Activity Title</th>
                            {
                                fiscalYearsFromProps.map((item) =>
                                    <th>{item.name}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            record && record.outputs.map((output, outputIdx) => {
                                let tableBody = [];

                                tableBody.push(
                                    <tr className="filled-row">
                                        <td className="title" colSpan={record.investment_years.length + 1}>
                                            {`Output ${outputIdx + 1}: ${output.title}`}
                                        </td>
                                    </tr>);

                                if (output.activities) {
                                    tableBody = lodash.concat(
                                        tableBody, 
                                        output.activities.map((activity, activityIdx) => {
                                            const startYear = moment(activity.start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                                            const endYear = moment(activity.end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                                            const activityChart = [];

                                            for (let index = Number(startYear); index <= Number(endYear); index++) {
                                                activityChart.push(Number(index));
                                            }
                                            return (
                                                <tr>
                                                    <td className="title">
                                                    {`Activity ${activityIdx + 1}: ${activity.title}`}
                                                </td>
                                                {
                                                    record && record.investment_years.map((year) => {
                                                        // const cellClass = classNames({ 'filled-cell':  activityChart.includes(year) });
                                                        const cellStyle = activityChart.includes(year) ? filledCell : {};
                                                        return (
                                                            <td style={cellStyle}><div></div></td>
                                                        )
                                                    }
                                                    )
                                                }
                                                </tr>
                                            )
                                        })
                                    )
                                }

                                return tableBody;
                            })
                        }
                    </tbody>
                </Table>
            </div>
            </div>
        )
    }
  }

  export default GanttChart;
