import lodash from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { validateArraysData } from './ToolBar/helper';

const option_modules = [
    'demand_module',
    'technical_module',
    'env_module',
    'hr_module',
    'legal_module',
];
const analytics = [
    'financial_evaluation',
    'economic_evaluation',
    'stakeholder_evaluation',
    'risk_evaluation',
];

const emptyValues = (blockData) =>
    lodash.keys(blockData).filter((key) => !blockData[key]);

const formatResultString = (fieldsData) =>
    lodash
        .keys(fieldsData)
        .filter(
            (optionTitle) =>
                fieldsData[optionTitle] && fieldsData[optionTitle].length !== 0,
        )
        .map((optionTitle) => {
            return `${optionTitle}: ${fieldsData[optionTitle].join(', ')}`;
        });

export const validateModulesFill = (formValues, translate) => {
    const { options_appraisals } = formValues;
    const summaryFields = ['advantage', 'disadvantage', 'description', 'score'];
    const emptyFieldsObj = {};
    const translationString = 'validation.options_appraisal.building_blocks';

    options_appraisals.forEach((option) => {
        emptyFieldsObj[option.title] = [];
    });

    options_appraisals.forEach((option, idx) => {
        option_modules.forEach((modul) => {
            summaryFields.forEach((key) => {
                if (option[modul]) {
                    if (formValues.phase_id >= 4) {
                        if (!option[modul]['description']) {
                            emptyFieldsObj[option.title].push(
                                `${translate(
                                    `${translationString}.modules.${modul}`,
                                )}(${translate(
                                    `${translationString}.${'description'}`,
                                )})`,
                            );
                        }
                    } else {
                        if (!option[modul][key]) {
                            if (!emptyFieldsObj[option.title].includes(key)) {
                                emptyFieldsObj[option.title].push(
                                    `${translate(
                                        `${translationString}.modules.${modul}`,
                                    )}(${translate(
                                        `${translationString}.${key}`,
                                    )})`,
                                );
                            }
                        }
                    }
                } else {
                    if (
                        !emptyFieldsObj[option.title].includes(
                            `${translate(
                                `${translationString}.modules.${modul}`,
                            )}(${translate(
                                'validation.options_appraisal.all_fields',
                            )})`,
                        )
                    ) {
                        emptyFieldsObj[option.title].push(
                            `${translate(
                                `${translationString}.modules.${modul}`,
                            )}(${translate(
                                'validation.options_appraisal.all_fields',
                            )})`,
                        );
                    }
                }
            });
        });
    });
    return formatResultString(emptyFieldsObj);
};

export const validateBestSelectionFill = (formValues) => {
    const { options_appraisals } = formValues;
};

export const validateEvaluationsFill = (formValues, translate) => {
    const { options_appraisals } = formValues;

    const emptyFieldsObj = {};
    const summaryFields = ['appraisal_methodology', 'summary'];
    const stakeholdersFields = [
        'title',
        'impact_sign',
        'beneficiary',
        'description',
    ];
    const riskFactors = [
        'description',
        'occurrence',
        'impact',
        'mitigation_plan',
    ];
    const translationString = 'validation.options_appraisal';

    options_appraisals.forEach((option) => {
        emptyFieldsObj[option.title] = [];
    });

    options_appraisals
        .filter((item) => item.is_shortlisted)
        .forEach((option, idx) => {
            analytics.forEach((modul) => {
                if (option[modul]) {
                    if (
                        modul === 'financial_evaluation' ||
                        modul === 'economic_evaluation'
                    ) {
                        summaryFields.forEach((key) => {
                            if (!option[modul][key]) {
                                if (
                                    !emptyFieldsObj[option.title].includes(key)
                                ) {
                                    emptyFieldsObj[option.title].push(
                                        `${translate(
                                            `${translationString}.${modul}.title`,
                                        )}(${translate(
                                            `${translationString}.${modul}.${key}`,
                                        )})`,
                                    );
                                }
                            } else {
                                if (modul === 'financial_evaluation') {
                                    if (
                                        key === 'appraisal_methodology' &&
                                        option[modul][key] === 'cba'
                                    ) {
                                        if (!option[modul]['fnpv']) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.fnpv`,
                                                )})`,
                                            );
                                        }
                                        if (!option[modul]['irr']) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.irr`,
                                                )})`,
                                            );
                                        }
                                    }
                                    if (
                                        key === 'appraisal_methodology' &&
                                        option[modul][key] === 'cea'
                                    ) {
                                        if (
                                            !option[modul]['criterias'] ||
                                            option[modul]['criterias']
                                                .length === 0
                                        ) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.criterias`,
                                                )})`,
                                            );
                                        }
                                    }
                                }
                                if (modul === 'economic_evaluation') {
                                    if (
                                        key === 'appraisal_methodology' &&
                                        option[modul][key] === 'cba'
                                    ) {
                                        if (!option[modul]['enpv']) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.enpv`,
                                                )})`,
                                            );
                                        }
                                        if (!option[modul]['err']) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.err`,
                                                )})`,
                                            );
                                        }
                                    }
                                    if (
                                        key === 'appraisal_methodology' &&
                                        option[modul][key] === 'cea'
                                    ) {
                                        if (
                                            !option[modul]['criterias'] ||
                                            option[modul]['criterias']
                                                .length === 0
                                        ) {
                                            emptyFieldsObj[option.title].push(
                                                `${translate(
                                                    `${translationString}.${modul}.title`,
                                                )}(${translate(
                                                    `${translationString}.${modul}.criterias`,
                                                )})`,
                                            );
                                        }
                                    }
                                }
                            }
                        });
                    } else {
                        if (option[modul].length === 0) {
                            if (
                                !emptyFieldsObj[option.title].includes(
                                    `${translate(
                                        `${translationString}.${modul}.title`,
                                    )}(${translate(
                                        `${translationString}.all_fields`,
                                    )})`,
                                )
                            ) {
                                emptyFieldsObj[option.title].push(
                                    `${translate(
                                        `${translationString}.${modul}.title`,
                                    )}(${translate(
                                        `${translationString}.all_fields`,
                                    )})`,
                                );
                            }
                        } else {
                            const fieldsValidating =
                                modul === 'stakeholder_evaluation'
                                    ? stakeholdersFields
                                    : riskFactors;

                            option[modul].forEach((item) => {
                                fieldsValidating.map((field) => {
                                    if (!item[field]) {
                                        emptyFieldsObj[option.title].push(
                                            `${translate(
                                                `${translationString}.${modul}.title`,
                                            )}(${translate(
                                                `${translationString}.${modul}.${field}`,
                                            )})`,
                                        );
                                    }
                                });
                            });
                        }
                    }
                } else {
                    if (
                        !emptyFieldsObj[option.title].includes(
                            `${translate(
                                `${translationString}.${modul}.title`,
                            )}(${translate(
                                `${translationString}.all_fields`,
                            )})`,
                        )
                    ) {
                        emptyFieldsObj[option.title].push(
                            `${translate(
                                `${translationString}.${modul}.title`,
                            )}(${translate(
                                `${translationString}.all_fields`,
                            )})`,
                        );
                    }
                }
            });
        });
    return formatResultString(emptyFieldsObj);
};

export const validateSummaryFill = (formValues, translate) => {
    const { options_appraisals } = formValues;

    const summaryFields = ['title', 'description', 'cost', 'funding_modality'];
    const translationString = 'validation.options_appraisal.summary';
    const emptyFieldsObj = {};

    options_appraisals.forEach((option) => {
        emptyFieldsObj[option.title] = [];
    });

    options_appraisals.forEach((option, idx) => {
        summaryFields.forEach((key) => {
            if (!option[key]) {
                if (!emptyFieldsObj[option.title].includes(key)) {
                    emptyFieldsObj[option.title].push(
                        translate(`${translationString}.${key}`),
                    );
                }
            }
        });
    });

    return formatResultString(emptyFieldsObj);
};

export const validateOptionsBeforeSave = (
    formValues,
    showNotification,
    translate,
) => {
    if (formValues && formValues.phase_id < 3) return true;

    if (
        formValues &&
        formValues.options_appraisals &&
        formValues.options_appraisals.length !== 0
    ) {
        let errorMessage = `${translate('validation.empty_fields')}:`;
        let errorsCount = 0;

        const summaryValidation = validateSummaryFill(formValues, translate);
        const modulesValidation = validateModulesFill(formValues, translate);
        const evaluationsValidation = validateEvaluationsFill(
            formValues,
            translate,
        );
        const bestSelection = validateBestSelectionFill(formValues, translate);

        if (summaryValidation.length !== 0) {
            errorMessage = errorMessage + ` ${summaryValidation.join(', ')}`;
            errorsCount += 1;
        }
        if (modulesValidation.length !== 0) {
            errorMessage = errorMessage + ` ${modulesValidation.join(', ')}`;
            errorsCount += 1;
        }
        if (evaluationsValidation.length !== 0) {
            errorMessage =
                errorMessage + ` ${evaluationsValidation.join(', ')}`;
            errorsCount += 1;
        }

        return errorsCount > 0 ? errorMessage : false;
    }

    return translate('validation.options_appraisal_empty');
};

export const validateTopOptions = (formValues, translate) => {
    const { options_appraisals } = formValues;
    return lodash.groupBy(options_appraisals, 'score');
};

export const commasFormatter = (value) => {
    if (value) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return parts.join('.');
    }

    return value;
};

export const commasParser = (value) => {
    if (value) {
        return value.replace(/,/g, '');
    }

    return value;
};

export const costSumFormatter = (value) => {
    if (!value) {
        return '-';
    }
    return typeof value !== 'undefined' && value !== 0
        ? numeral(value).format('0,0.00')
        : '-';
};

export const dateFormatter = (value, time = true) => {
    if (value) {
        return time
            ? moment(value).format('DD-MM-YYYY h:mm a')
            : moment(value).format('DD-MM-YYYY');
    }

    return value;
};

export const currencyConverter = (value, rate, sign) => {
    if (value) {
        const formattedValue = value.replace(/,/g, '');

        if (rate) {
            if (sign) {
                return (parseFloat(formattedValue) / parseFloat(rate)).toFixed(
                    2,
                );
            }
            return (parseFloat(formattedValue) * parseFloat(rate)).toFixed(2);
        }
        return formattedValue;
    }

    return value;
};

export const getCurrentPhaseId = (record) => {
    return !record.project_details.includes(record.last_detail_id)
        ? record.phase_id - 1
        : record.phase_id;
};
