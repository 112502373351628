import React from 'react';
import HTML2React from 'html2react'
import { romanize } from '../../../helpers/formatters';

export const Introduction = props => {
    const { customRecord, customBasePath, translate, counter } = props;
    const record = customRecord;
    const basePath = customBasePath;

    const title = customRecord && customRecord.phase_id === 3
        ? <p><b>{translate('resources.project-details.fields.organization_study_pfs')}</b></p>
        : <p><b>{translate('resources.project-details.fields.organization_study_fs')}</b></p>

    return(
        <div className="Section2">
            <div className="content-area">
                <h4>{counter ? `${romanize(counter)}.` : ''}  {translate('printForm.introduction.title')}</h4>
                <div>
                    <p><b>{translate('resources.project-details.fields.rational_study')}</b></p>
                    {HTML2React(customRecord.rational_study)}
                    <p><b>{translate('resources.project-details.fields.methodology')}</b></p>
                    {HTML2React(customRecord.methodology)}
                    {title}
                    {HTML2React(customRecord.organization_study)}
                </div>
            </div>
        </div>
    )
};
