import React, { Fragment } from 'react';
import {
    BooleanInput,
    LongTextInput,
    FormDataConsumer,
    Labeled,
    TextInput,
    translate,
    SelectInput,
    required,
    number,
    FileInput,
    FileField,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import CustomInput from '../../../components/CustomInput';
import CustomTextArea from '../../../components/customTextArea';

const ProjectStrategicFitForm = props => {
    const OTHER_PLANS = [
        {
            id: 'development_partners',
            name: props.translate(
                'resources.project-details.fields.other_plans.development_partners',
            ),
        },
        {
            id: 'cabinet_directives',
            name: props.translate(
                'resources.project-details.fields.other_plans.cabinet_directives',
            ),
        },
        {
            id: 'parliament_approval',
            name: props.translate(
                'resources.project-details.fields.other_plans.parliament_approval',
            ),
        },
        {
            id: 'stare_owned_enterprise',
            name: props.translate(
                'resources.project-details.fields.other_plans.stare_owned_enterprise',
            ),
        },
        {
            id: 'regional_integration_policy',
            name: props.translate(
                'resources.project-details.fields.other_plans.regional_integration_policy',
            ),
        },
        {
            id: 'political_manifestos',
            name: props.translate(
                'resources.project-details.fields.other_plans.political_manifestos',
            ),
        },
        {
            id: 'interest_groups_or_beneficiaries',
            name: props.translate(
                'resources.project-details.fields.other_plans.interest_groups_or_beneficiaries',
            ),
        },
        {
            id: 'private_sponsors',
            name: props.translate(
                'resources.project-details.fields.other_plans.private_sponsors',
            ),
        },
        {
            id: 'sustainable_development_goals',
            name: props.translate(
                'resources.project-details.fields.other_plans.sustainable_development_goals',
            ),
        },
    ];

    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                if (
                    formData &&
                    formData.government_coordination &&
                    formData.government_coordination.length === 0
                ) {
                    formData.government_coordination.push({});
                }

                return (
                    <Fragment>
                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.situation_analysis',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.situation_analysis',
                                )}
                            />
                            <CustomTextArea
                                source="situation_analysis"
                                {...props}
                            />
                        </CustomInput>

                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.problem_statement',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.problem_statement',
                                )}
                            />
                            <CustomTextArea
                                source="problem_statement"
                                {...props}
                            />
                        </CustomInput>

                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.problem_causes',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.problem_causes',
                                )}
                            />
                            <CustomTextArea source="problem_cause" {...props} />
                        </CustomInput>

                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.problem_effects',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.problem_effects',
                                )}
                            />
                            <CustomTextArea
                                source="problem_effects"
                                {...props}
                            />
                        </CustomInput>

                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.justification',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.justification',
                                )}
                            />
                            <CustomTextArea source="justification" {...props} />
                        </CustomInput>

                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.stakeholders',
                            )}
                            textArea
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.stakeholders',
                                )}
                            />
                            <CustomTextArea source="stakeholders" {...props} />
                        </CustomInput>

                        <ArrayInput
                            source="government_coordination"
                            label={props.translate(
                                'resources.project-details.fields.government_coordination.title',
                            )}
                        >
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({
                                        formData,
                                        scopedFormData,
                                        getSource,
                                        ...rest
                                    }) => (
                                        <Fragment>
                                            <CustomInput
                                                tooltipText={props.translate(
                                                    'tooltips.resources.project-details.fields.government_agencies.name',
                                                )}
                                                textArea
                                            >
                                                <TextInput
                                                    label={props.translate(
                                                        'resources.project-details.fields.government_coordination.name',
                                                    )}
                                                    source={getSource('name')}
                                                />
                                            </CustomInput>
                                            <CustomInput
                                                tooltipText={props.translate(
                                                    'tooltips.resources.project-details.fields.government_agencies.details',
                                                )}
                                                textArea
                                            >
                                                <Labeled
                                                    label={props.translate(
                                                        'resources.project-details.fields.government_coordination.details',
                                                    )}
                                                />
                                                <CustomTextArea
                                                    source={getSource(
                                                        'details',
                                                    )}
                                                    {...props}
                                                />
                                            </CustomInput>
                                        </Fragment>
                                    )}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>

                        <h5>
                            {props.translate(
                                'resources.project-details.fields.strategic_alignment',
                            )}
                        </h5>
                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.in_ndp.title',
                            )}
                        >
                            <SelectInput
                                label={props.translate(
                                    'resources.project-details.fields.in_ndp.title',
                                )}
                                source={'in_ndp'}
                                choices={[
                                    {
                                        id: 1,
                                        name: props.translate(
                                            'resources.project-details.fields.in_ndp.yes',
                                        ),
                                    },
                                    {
                                        id: 0,
                                        name: props.translate(
                                            'resources.project-details.fields.in_ndp.other',
                                        ),
                                    },
                                ]}
                                options={{ fullWidth: 'true' }}
                            />
                        </CustomInput>
                        {formData && formData.in_ndp === 1 && (
                            <CustomInput
                                tooltipText={props.translate(
                                    'tooltips.resources.project-details.fields.ndp_type.title',
                                )}
                            >
                                <SelectInput
                                    label={props.translate(
                                        'resources.project-details.fields.ndp_type.title',
                                    )}
                                    source="ndp_type"
                                    choices={[
                                        {
                                            id: 'core',
                                            name: props.translate(
                                                'resources.project-details.fields.ndp_type.core',
                                            ),
                                        },
                                        {
                                            id: 'priority',
                                            name: props.translate(
                                                'resources.project-details.fields.ndp_type.priority',
                                            ),
                                        },
                                    ]}
                                    options={{ fullWidth: 'true' }}
                                />
                            </CustomInput>
                        )}
                        {formData &&
                            (formData.in_ndp === 1 &&
                                formData.ndp_type === 'core') && (
                                <Fragment>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.ndp_name',
                                        )}
                                        fullWidth
                                    >
                                        <TextInput
                                            label={props.translate(
                                                'resources.project-details.fields.ndp_name',
                                            )}
                                            source="ndp_name"
                                        />
                                    </CustomInput>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.ndp_page_no',
                                        )}
                                        fullWidth
                                    >
                                        <TextInput
                                            label={props.translate(
                                                'resources.project-details.fields.ndp_page_no',
                                            )}
                                            source="ndp_page_no"
                                            validate={number()}
                                        />
                                    </CustomInput>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.focus_area.title',
                                        )}
                                    >
                                        <SelectInput
                                            label={props.translate(
                                                'resources.project-details.fields.focus_area.title',
                                            )}
                                            source="focus_area"
                                            choices={[
                                                {
                                                    id: 'wealth_creation',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.wealth_creation',
                                                    ),
                                                },
                                                {
                                                    id: 'inclusive_growth',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.inclusive_growth',
                                                    ),
                                                },
                                                {
                                                    id: 'competitiveness',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.competitiveness',
                                                    ),
                                                },
                                            ]}
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </CustomInput>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.intervention',
                                        )}
                                        fullWidth
                                    >
                                        <TextInput
                                            label={props.translate(
                                                'resources.project-details.fields.intervention',
                                            )}
                                            source="intervention"
                                        />
                                    </CustomInput>
                                </Fragment>
                            )}
                        {formData &&
                            (formData.in_ndp === 1 &&
                                formData.ndp_type === 'priority') && (
                                <Fragment>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.focus_area.title',
                                        )}
                                    >
                                        <SelectInput
                                            label={props.translate(
                                                'resources.project-details.fields.focus_area.title',
                                            )}
                                            source="focus_area"
                                            choices={[
                                                {
                                                    id: 'wealth_creation',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.wealth_creation',
                                                    ),
                                                },
                                                {
                                                    id: 'inclusive_growth',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.inclusive_growth',
                                                    ),
                                                },
                                                {
                                                    id: 'competitiveness',
                                                    name: props.translate(
                                                        'resources.project-details.fields.focus_area.competitiveness',
                                                    ),
                                                },
                                            ]}
                                            options={{ fullWidth: 'true' }}
                                        />
                                    </CustomInput>
                                    <CustomInput
                                        tooltipText={props.translate(
                                            'tooltips.resources.project-details.fields.intervention',
                                        )}
                                        fullWidth
                                    >
                                        <TextInput
                                            label={props.translate(
                                                'resources.project-details.fields.intervention',
                                            )}
                                            source="intervention"
                                        />
                                    </CustomInput>
                                </Fragment>
                            )}
                        {formData && formData.in_ndp === 0 && (
                            <CustomInput
                                tooltipText={props.translate(
                                    'tooltips.resources.project-details.fields.other_strategic_directives',
                                )}
                            >
                                <SelectInput
                                    label={props.translate(
                                        'resources.project-details.fields.other_strategic_directives',
                                    )}
                                    source="other_strategic_directives"
                                    choices={OTHER_PLANS}
                                    options={{ fullWidth: 'true' }}
                                />
                            </CustomInput>
                        )}
                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.plan_details',
                            )}
                            fullWidth
                        >
                            <Labeled
                                label={props.translate(
                                    'resources.project-details.fields.plan_details',
                                )}
                            />
                            <CustomTextArea source="plan_details" {...props} />
                        </CustomInput>
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(ProjectStrategicFitForm);
