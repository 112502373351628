import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY, PROJECT_PHASES_COLORS } from '../../constants/common';
import lodash from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

const markerStyle = {
  position: 'relative',
  textAlign: 'center',
  color: '#000',
  fontSize: 14,
  fontWeight: 'bold',
  padding: 4
}

class Marker extends Component {
  handleClick = () => {
    window.location.href = this.props.link;
  }

  render(){
    const { text, color } = this.props;

    return (
      <Tooltip title={text} placement="right">
          <svg onClick={this.handleClick}
              x="0px" y="0px" width="8.6444445mm" height="9.847393mm" viewBox="0 0 512 512" enableBackground="new 0 0 512 512"
              style={{ transform: "translate(-25px, -60px)" }}>
            <g>
              <path fill={color} stroke="#000" strokeWidth="10px" d="M256.292,12.781c-98.628,0-178.585,79.955-178.585,178.585c0,42.256,13.724,77.289,34.268,113.638
                c48.065,85.042,144.533,193.714,144.533,193.714c64.417-69.391,147.02-206.308,147.02-206.308s31.351-63.531,31.351-101.044
                C434.877,92.736,354.921,12.781,256.292,12.781z M256.292,297.159c-66.021,0-119.546-53.523-119.546-119.546
                S190.271,58.067,256.292,58.067s119.546,53.522,119.546,119.546S322.313,297.159,256.292,297.159z"/>
              <circle fill={color} cx="256.292" cy="177.613" r="72.107"/>
            </g>
          </svg>
      </Tooltip>
    )
  }
}

const SimpleMarker = ({ text, onClick, item }) => 
  <div style={markerStyle}>
    <span>{text}</span>
    <svg x="0px" y="0px" width="8.6444445mm" height="9.847393mm" viewBox="0 0 512 512" enableBackground="new 0 0 512 512"
        style={{ transform: "translate(-25px, -60px)" }}
        onClick={onClick(item)}>
        <g>
          <path fill="#ff4646" d="M256.292,12.781c-98.628,0-178.585,79.955-178.585,178.585c0,42.256,13.724,77.289,34.268,113.638
            c48.065,85.042,144.533,193.714,144.533,193.714c64.417-69.391,147.02-206.308,147.02-206.308s31.351-63.531,31.351-101.044
            C434.877,92.736,354.921,12.781,256.292,12.781z M256.292,297.159c-66.021,0-119.546-53.523-119.546-119.546
            S190.271,58.067,256.292,58.067s119.546,53.522,119.546,119.546S322.313,297.159,256.292,297.159z"/>
          <circle fill="#ff4646" cx="256.292" cy="177.613" r="72.107"/>
        </g>
      </svg>
</div>

class SimpleMap extends Component {
  static defaultProps = {
    height: '300px', 
    width: '100%',
    center: {
      lat: 0.18,
      lng: 32.37
    },
    zoom: 7
  };

  state = {
    center: {
      lat: 0.18,
      lng: 32.37
    },
    lng: '',
    lat: '',
    markers: []
  }

  componentDidMount() {
    if (!this.props.isReport) {
      const { geo_location } = this.props.formValues || this.props.customRecord;

      if (geo_location && lodash.isString(geo_location)) {
        let markers = [];

        try {
          markers = JSON.parse(geo_location)
            .map((item) => ({ lng: String(item.lng), lat: String(item.lat) }))
        } catch (error) {
          console.log(error);
        }

        this.setState({ markers });
      }
    } 
  }

  handleClick = ({x, y, lat, lng, event}) => {
    if (!this.props.isDisabled && this.props.change) {
      const markers = lodash.cloneDeep(this.state.markers);
      markers.push({ lat: String(lat), lng: String(lng) });

      this.setState({ markers });
      this.props.change('record-form', 'geo_location', JSON.stringify(markers));
    }
  }

  handleDeleteMarker = (marker) => (event) => {
    event.stopPropagation();
    
    const { markers } = this.state;
    if (lodash.find(markers, marker)){
      const markersFiltered = lodash.filter(markers, (item) => !lodash.isEqual(marker, item))
      this.setState({ markers: markersFiltered })
    }
  }

  renderReportMap() {
    console.log(this.props.data);
    
    return (
      // Important! Always set the container height explicitly
      <div id="location_map" style={{ height: this.props.height, width: this.props.width }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onClick={this.handleClick}
        >
        {
          !lodash.isEmpty(this.props.data) &&
          lodash.keys(this.props.data).map((phase_id) => 
            this.props.data[phase_id] 
              ? this.props.data[phase_id].map((item) =>
                  item.markers.map((marker) => 
                    <Marker
                      lat={marker.lat}
                      lng={marker.lng}
                      text={item.title}
                      link={`#/projects/${item.id}/show/${item.phase_id}`}
                      color={PROJECT_PHASES_COLORS[item.phase_id]}
                    />
                  )
                )
              : null
          )
        }
          
        </GoogleMapReact>
      </div>
    );
  }

  renderMap() {
    return (
      // Important! Always set the container height explicitly
      <div id="location_map" style={{ height: this.props.height, width: this.props.width }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
            defaultCenter={this.props.center}
            center={this.state.center || this.props.center }
            defaultZoom={this.props.zoom}
            onClick={this.handleClick}
          >
          {
            this.state.markers.length !== 0 &&
            this.state.markers.map((item, idx) =>
              <SimpleMarker
                lat={item.lat}
                lng={item.lng}
                item={item}
                text={`Location_${idx + 1}`}
                onClick={this.handleDeleteMarker}
              />
            )
              
          }
          </GoogleMapReact>
      </div>
    );
  }

  render() {
    return this.props.isReport
      ? this.renderReportMap()
      : this.renderMap()
  }
}

export default SimpleMap;