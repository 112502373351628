import { Button, translate } from 'react-admin';
import React, { Component, Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Print from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import { linkToRecord } from 'ra-core';
import { printOutStyles } from './helper';
import shouldUpdate from 'recompose/shouldUpdate';

const defaultProps = {
    label: 'ra.action.export',
    record: {},
    icon: <Print />,
    id: '',
    printId: '',
};

class CustomPrintButton extends Component {
    state = {
        exportType: 'pdf',
        isExporting: false
    }

    handleExportType = (event) => {
        event.stopPropagation();
        this.setState({ exportType: event.target.value })
    }

    handleExport = () => {
        const { printId, record } = this.props;
        
        this.setState({ isExporting: true });

        if (this.state.exportType === 'pdf') {
            this.handlePDFexport(printId, record);
        } else {
            this.handleDOCexport(printId, record);
        }
    }

    handlePDFexport = (id, record) => {
        var content = document.getElementById(id);
        var clone = content.cloneNode(true);
        var imgMap = clone.getElementsByClassName("static_map")[0];
        var static_map_btn = clone.getElementsByClassName("static_map_btn")[0];

        if (static_map_btn && static_map_btn.parentNode) {
            static_map_btn.parentNode.removeChild(static_map_btn)
        }
        if (imgMap && imgMap.parentNode) {
            imgMap.parentNode.removeChild(imgMap)
        }

        dataProvider('CUSTOM', 'projects', { 
            type: `${record.id}/export`, 
            query: { 
                content: document.getElementById(id) ? `<div>${clone.innerHTML}</div>` : '',
                format: 'pdf',
                phase: this.props.phase
            } 
        })
        .then((response) => {
            let printName = record ? `${record.project_no}-${record.title}` : 'document';
            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64,"+response.data;
            a.download = `${printName}.pdf`
            a.click();
            a.remove();

            this.setState({ isExporting: false });
        })
    }

    handleDOCexport = (id, record) => {
        dataProvider('CUSTOM', 'projects', { 
            type: `${record.id}/export`, 
            query: { 
                content: document.getElementById(id) ? `<div>${document.getElementById(id).innerHTML}</div>` : '',
                phase: this.props.phase
            } 
        })
        .then((response) => {
            var printName = record ? `${record.project_no}-${record.title}` : 'document';
            var blob = new Blob([response.data], { type:"text/plain" });
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${printName}.doc`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again   

            this.setState({ isExporting: false });
        })
    }

    render () {
        return (
            <Fragment>
                {
                    this.props.isEditUser &&
                        <Select
                            style={{
                                'min-width': '0px',
                                'fontSize': '0.8125rem',
                                'color': '#3f51b5',
                                'border-bottom': 'none',
                                'padding-left': '3px'
                            }}
                            autoWidth={true}
                            value={this.state.exportType}
                            onChange={this.handleExportType}
                        >
                            <MenuItem value={'pdf'}>PDF</MenuItem>
                            <MenuItem value={'word'}>WORD</MenuItem>
                        </Select>
                }
                <Button
                    component={"span"}
                    label = {'ra.action.export'}
                    onClick={this.handleExport}
                >
                    { 
                        this.state.isExporting
                            ?   <CircularProgress style={{marginRight: '10px' }} size={25} thickness={2} />
                            :   <Print />
                    }
                </Button>
            </Fragment>
        
        )
    }
}

CustomPrintButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
    id: PropTypes.number,
};

CustomPrintButton.defaultProps = defaultProps;


const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(CustomPrintButton);
