import { Button } from 'react-admin';
import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';


const stopPropagation = e => e.stopPropagation();

const CustomEditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record = {},
    icon = <ContentCreate />,
    id = '',
    ...rest
}) => (
    <Button
        component={Link}
        to={linkToRecord(basePath, id || record.id)}
        label={label}
        onClick={stopPropagation}
        {...rest}
    >
        {icon}
    </Button>
);

CustomEditButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
    id: PropTypes.number,
};

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(CustomEditButton);
