import React, { Fragment } from 'react';
import { 
    FormDataConsumer, 
    TextInput, 
    translate, number,
    Labeled,
} from 'react-admin';

import CustomInput from '../../../components/CustomInput';
import CustomTextArea from '../../../components/customTextArea';

const IntroductionForm = props => 
    <FormDataConsumer>
        {({ getSource, scopedFormData, formData, ...rest }) => {
            
            const title = formData && formData.phase_id === 3
                ? props.translate('resources.project-details.fields.organization_study_pfs')
                : props.translate('resources.project-details.fields.organization_study_fs')

                return (
                    <Fragment>
                        <CustomInput tooltipText={props.translate('tooltips.resources.project-details.fields.rational_study')} textArea>
                            <Labeled label={props.translate('resources.project-details.fields.rational_study')} />
                            <CustomTextArea  source="rational_study" {...props} />
                        </CustomInput>
                        <CustomInput tooltipText={props.translate('tooltips.resources.project-details.fields.methodology')} textArea>
                            <Labeled label={props.translate('resources.project-details.fields.methodology')} />
                            <CustomTextArea  source="methodology" {...props} />
                        </CustomInput>
                        <CustomInput tooltipText={props.translate('tooltips.resources.project-details.fields.organization_study')} textArea>
                            <Labeled label={title} />
                            <CustomTextArea  source="organization_study" {...props} />
                        </CustomInput>
                    </Fragment>
                )
            }
        }
    </FormDataConsumer>

export default translate(IntroductionForm);
