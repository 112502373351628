import React from 'react';
import HTML2React from 'html2react'

const DetailedAnalysysForOptions = props => {
    const { customRecord, translate } = props;
        const { options_appraisals } = customRecord;
        const option_modules = [
            {
                name: translate('resources.options_appraisals.fields.building_blocks.modules.demand_module'),
                id: 'demand_module'
            },
            {
                name: translate('resources.options_appraisals.fields.building_blocks.modules.technical_module'),
                id: 'technical_module'
            },
            {
                name: translate('resources.options_appraisals.fields.building_blocks.modules.env_module'),
                id: 'env_module'
            },
            {
                name: translate('resources.options_appraisals.fields.building_blocks.modules.hr_module'),
                id: 'hr_module'
            },
            {
                name: translate('resources.options_appraisals.fields.building_blocks.modules.legal_module'),
                id: 'legal_module'
            },
        ]
        
        if (!customRecord) {
            return null;
        }
    
        return(
        <div className="Section2">
            <div className="content-area">
                {
                    options_appraisals &&
                    customRecord.options_appraisals
                        .map((option) =>
                        <div>
                            <h4><strong>{`${translate('printForm.options.detailed_analysis')} ${option.title}`}</strong></h4>
                            {
                                option_modules.map((modul) =>
                                    <div>
                                        <div><strong>{modul.name}</strong></div>
                                        {
                                            option[modul.id] &&
                                            <ul>
                                                <li>
                                                    <p className="content-area_subtitle">{translate('printForm.options.description')}:</p>
                                                    {HTML2React(option[modul.id].description)}
                                                </li>
                                                <li>
                                                    <p className="content-area_subtitle">{translate('printForm.options.advantage')}:</p>
                                                    {HTML2React(option[modul.id].advantage)}
                                                </li>
                                                <li>
                                                    <p className="content-area_subtitle">{translate('printForm.options.disadvantage')}:</p>
                                                    {HTML2React(option[modul.id].disadvantage)}
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            </div>
        )
};

export default DetailedAnalysysForOptions;
