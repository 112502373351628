import React, { Fragment }  from 'react';
import { FormDataConsumer, Labeled, FileInput, FileField, translate } from 'react-admin';

import CustomInput from '../../../components/CustomInput';
import CustomTextArea from '../../../components/customTextArea';

const DemandAnalysisForm = props => {
    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <Fragment>
                        <CustomInput tooltipText="Demand Analysis" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.demand_analysis')} />
                            <CustomTextArea  source="demand_analysis" {...this.props} />
                        </CustomInput>
                        
                        <FileInput
                            source="demand_analysis_files"
                            options={{ multiple: true }}
                            accept="application/pdf"
                            label={props.translate('resources.project-details.fields.demand_analysis_files')}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(DemandAnalysisForm);
