import lodash from 'lodash';

export const printOutStyles = `td{border: 1px solid #000;padding:.75rem;vertical-align: top;} th {border: 1px solid #000;padding: .75rem;	vertical-align: top;}`;

export const validateArraysData = (field, checkingField, record) => {
    let errorCounter = 0;

    if (!lodash.isEmpty(record[field])) {
        record[field].forEach(outcome => {
            if (lodash.isEmpty(outcome[checkingField])) {
                errorCounter += 1;
            }
        });
    }
    return errorCounter;
};

export const validateInnerData = (field, innerFields, data) => {
    if (lodash.isArray(data[field])) {
        if (data[field] && data[field].length === 0) {
            return true;
        }
    }

    const emptyFields =
        data[field] &&
        data[field].filter(innerItem => {
            const emptyInnerFields =
                innerFields &&
                innerFields.filter(innerField => {
                    if (
                        innerItem[innerField] &&
                        lodash.isArray(innerItem[innerField])
                    ) {
                        return innerItem[innerField].length === 0;
                    }

                    return !innerItem[innerField];
                });
            return emptyInnerFields && emptyInnerFields.length !== 0;
        });

    return emptyFields && emptyFields.length !== 0;
};

export const validateInnerArrayData = (
    field,
    arrayField,
    innerFields,
    data,
) => {
    if (lodash.isEmpty(data[field])) return true;

    const emptyFields =
        data[field] &&
        data[field].filter(item => {
            if (lodash.isEmpty(item[arrayField])) return true;

            return validateInnerData(arrayField, innerFields, item[arrayField]);
        });

    return emptyFields && emptyFields.length !== 0;
};
