import React from 'react';
import HTML2React from 'html2react'
import { romanize } from '../../../helpers/formatters';

export const Attacments = props => {
    const { customRecord, customBasePath, translate, counter = 1 } = props;
    const record = customRecord;
    const basePath = customBasePath;

    return(
    <div className="Section2">
        <div className="content-area">
            <h4>{romanize(counter)}.  {translate('printForm.attachments.title')}</h4>
            <div>
                {
                    record.files && record.files.length !== 0
                    ? <ul>
                        {
                            record.files.map((item) =>
                                <li><a href={item.filename}>{item.title}</a></li>
                            )
                        }
                    </ul>
                    : <p>{translate('printForm.attachments.no_attachments')}</p>
                }
            </div>
        </div>
    </div>
    )
};
