import { PROJECT_STEPS, VALIDATION_FIELDS } from '../../constants/common';
import { validateInnerArrayData, validateInnerData } from './ToolBar/helper';

import lodash from 'lodash';
import lodashMemoize from 'lodash/memoize';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

/* eslint-disable no-underscore-dangle */
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
var isEmpty = function (value) {
    return typeof value === 'undefined' || value === null || value === '';
};
var getMessage = function (message, messageArgs, value, values, props) {
    return typeof message === 'function'
        ? message(__assign({ args: messageArgs, value: value,
            values: values }, props))
        : props.translate(message, __assign({ _: message }, messageArgs));
};
// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
var memoize = function (fn) { return lodashMemoize(fn, function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return JSON.stringify(args);
}); };

export var outputsValidation = memoize(function (item, max, message) {
    if (message === void 0) { message = 'Maximum 2 outcomes per output'; }
    return function (value, values, props) {
        const result = item && item.outcome_ids.length > max

        return !isEmpty(result) && result
            ? getMessage(message, { outcomes: item }, value, values, props)
            : undefined;
    };
});

export var outcomesValidation = memoize(function (item, max, message) {
    if (message === void 0) { message = 'Maximum 2 outcomes per output'; }
    return function (value, values, props) {
        const result = item && item.outcome_ids.length > max

        return !isEmpty(result) && result
            ? getMessage(message, { outcomes: item }, value, values, props)
            : undefined;
    };
});


export const  projectValidation = (values, step, phase_id) => {
    const errors = {};
    //TODO: make validation by steps    
    if (step === PROJECT_STEPS.RESULT_MATRIX) {
        if (phase_id > 2) {
            if (values.outcomes && values.outcomes.length > 2) {
                errors.outcomes = 'Maximum 2 outcomes per project';
            }
            if (values.outputs && values.outputs.filter((item) => 
                item.outcome_ids.length > 2).length !== 0) {
                    errors.outcomes = 'Maximum 2 outcomes per output';
            }
        }
    }

    if (step === PROJECT_STEPS.OPTIONS_ANALYSIS) {
        if (phase_id === 2) {
            if (values.options_appraisals && values.options_appraisals
                .filter((item) => item.is_shortlisted) &&
                     values.options_appraisals.filter((item) => item.is_shortlisted).length > 3) {
                         errors.options_appraisals = 'Maximum 3 shortlisted options';
                }
            if (values.options_appraisals && values.options_appraisals
                .filter((item) => item.is_shortlisted) && 
                     values.options_appraisals.filter((item) => item.is_shortlisted).length === 0) {
                         errors.options_appraisals = 'At least 1 shortlisted options must be selected';
                }
        }
        if (phase_id === 3) {
            if (values.options_appraisals && values.options_appraisals
                .filter((item) => item.is_best).length > 1) {
                    errors.options_appraisals = 'Maximum 1 is_best options';
                }
            if (values.options_appraisals && values.options_appraisals
                .filter((item) => item.is_best).length === 0) {
                    errors.options_appraisals = 'At least 1 is_best options must be selected';
                }
        }
    }

    return errors
};

export const validateFieldsByPhase = (data, currentStep) => {
    if (lodash.isEmpty(data)) {
        return [];
    }

    const emptyFields = VALIDATION_FIELDS
        .filter(item => currentStep ? item.step === currentStep : true)
        .filter(item => {
            if (item.phases.includes(data.phase_id)) {
                switch (item.field) {
                    case 'om_costs':
                        if (data['om_period']) {
                            const emptyOmFields = item.innerFields.filter(
                                item => !data[item],
                            );

                            return emptyOmFields && emptyOmFields.length > 0;
                        } else {
                            return false;
                        }
                    case 'ndp_type':
                        if (data.in_ndp === 1 && data.ndp_type === 'core') {
                            if (
                                !data.ndp_name ||
                                !data.ndp_page_no ||
                                !data.intervention ||
                                !data.focus_area
                            ) {
                                return true;
                            }
                        }
                        if (data.in_ndp === 1 && data.ndp_type === 'priority') {
                            if (!data.intervention || !data.focus_area) {
                                return true;
                            }
                        }
                        if (data.in_ndp === 0) {
                            if (!data.other_strategic_directives) {
                                return true;
                            }
                        }
                        break;
                    case 'government_coordination':
                        return validateInnerData(
                            'government_coordination',
                            item.innerFields,
                            data,
                        );
                    case 'outcomes':
                        return validateInnerData(
                            'outcomes',
                            item.innerFields,
                            data,
                        );
                    case 'outputs':
                        return validateInnerData(
                            'outputs',
                            item.innerFields,
                            data,
                        );
                    case 'outcomes.indicators':
                        return validateInnerArrayData(
                            'outcomes',
                            'indicators',
                            item.innerFields,
                            data,
                        );
                    case 'outputs.indicators':
                        return validateInnerArrayData(
                            'outputs',
                            'indicators',
                            item.innerFields,
                            data,
                        );
                    case 'goal.indicators':
                        return validateInnerData(
                            'goal_indicators',
                            item.innerFields,
                            data,
                        );
                    case 'activities':
                        return validateInnerData(
                            'activities',
                            item.innerFields,
                            data,
                        );
                    case 'activities.investments':
                        return validateInnerArrayData(
                            'activities',
                            'investments',
                            item.innerFields,
                            data,
                        );
                    case 'outputs.investments':
                        return validateInnerArrayData(
                            'outputs',
                            'investments',
                            item.innerFields,
                            data,
                        );
                    case 'responsible_officers':
                        return validateInnerData(
                            'responsible_officers',
                            item.innerFields,
                            data,
                        );
                    default:
                        if (!data[item.field]) {
                            return true;
                        }
                        if (
                            data[item.field] &&
                            (lodash.isArray(data[item.field]) &&
                                lodash.isEmpty(data[item.field]))
                        ) {
                            return true;
                        }
                        break;
                }
            }

            return false;
        });

    if (emptyFields && emptyFields.length !== 0) {
        return emptyFields;
    }
       

    return [];
};

const getRequiredFilesForPhase = (file_types, phase_id) =>
        file_types
            .filter(
                item =>
                    item.phase_ids.includes(phase_id) &&
                    item.is_required === 'yes',
            )
            .map(item => item.id);

export const fileValidation = record => {
    if (lodash.isEmpty(record)) {
        return true;
    }

    const requiredFiles = getRequiredFilesForPhase(
        record.file_types,
        record.phase_id,
    );
        
    if (requiredFiles && requiredFiles.length !== 0) {
        if (!record.files || record.files.length === 0) {
            return true;
        }
    }

    const files = record.files.map(item => item.file_type_id);
    const result = requiredFiles.filter(item => !files.includes(item));

    if (result.length !== 0 ) {
        return true;
    }
};