import React from 'react';
import HTML2React from 'html2react'
import { Table } from 'reactstrap';
import lodash from 'lodash';
import { costSumFormatter } from '../helpers';
import { getFiscalYearsRangeForIntervals, romanize } from '../../../helpers/formatters';

export const OMCosts = props => {
    const { customRecord, customBasePath, translate, counter } = props;
    const record = customRecord;
    const basePath = customBasePath;
    let fiscalYearsFromProps;

    if (customRecord && lodash.isEmpty(customRecord.om_cost)) {
        return null;
    };

    if ( customRecord.om_cost ) {
        const years = lodash.keys(customRecord.om_cost);
        const startDate = years[0];
        const endDate = years[years.length - 1];
        fiscalYearsFromProps = record.investment_years && 
            getFiscalYearsRangeForIntervals(startDate, endDate);
    }

    return(
        <div className="landscapeSection">
        <div className="content-area">
                <h4>{counter ? `${romanize(counter)}.` : ''}  {translate('printForm.background.om_costs')}</h4>
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th>Cost Classification Code</th>
                            <th>Cost Classification Name</th>
                            {
                                customRecord && customRecord.om_cost &&
                                fiscalYearsFromProps.map((year) => <th>{year.name}</th>)
                            }
                            <th>Source of Funds</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{customRecord && customRecord.om_cost_classification && `${customRecord.om_cost_classification.code}`}</td>
                            <td>{customRecord && customRecord.om_cost_classification && `${customRecord.om_cost_classification.name}`}</td>
                            {
                                customRecord && customRecord.om_cost &&
                                lodash.keys(customRecord.om_cost).map((year) => <td>{costSumFormatter(customRecord.om_cost[year])}</td>)
                            }
                            <td>{customRecord && customRecord.om_fund_source && `${customRecord.om_fund_source.code} - ${customRecord.om_fund_source.name}`}</td>
                        </tr>
                    </tbody>
                </Table>
        </div>
        </div>
    )
};
