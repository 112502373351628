import './styles.scss';

import React, { Component } from 'react';
import {
    showNotification,
    translate
} from 'react-admin';

import { API_URL } from '../../constants/common';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderOpen from '@material-ui/icons/FolderOpen';
import HelpOutline from '@material-ui/icons/HelpOutline';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { TOKEN } from '../../constants/auth';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { connect } from 'react-redux';
import lodash from 'lodash';

const confertToMultiPart = item => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(item.file);

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject;
});

class CustomFileLoader extends Component {
    state = {
        isUploading: false,
        isUploaded: false,
        files: [],
    }

    componentDidMount () {
        const { formData, entityType, relatedField } = this.props;
        if (formData && formData.files) {
            const uploadedFiles = formData.files.filter((item) => 
                item.related_field === relatedField && item.entity_type === entityType);
        
            if (uploadedFiles.length === 0) {
                return;
            }

            this.setState({ files: uploadedFiles, isUploaded: true })
        }
        
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmitted) {
            this.handleStartUploading()
        }
    }

    handleChange = (event) => {
        if (!lodash.isEqual(this.state.files[0], event.target.files[0])){   
            const fileSelected = event.target.files[0];
            const titleSelectedFile= event.target.files[0].name;
            const selectedFileExtension = titleSelectedFile.slice(titleSelectedFile.lastIndexOf('.'), titleSelectedFile.length);

            if (this.props.extensions && this.props.extensions !== '*' && this.props.extensions.indexOf(selectedFileExtension) === -1){
                return this.props.showNotification(this.props.translate('messages.file_ext_error'), 'warning');
            } 
            this.setState({ 
                files: [{ 
                    file: fileSelected, 
                    title: titleSelectedFile
                }],
                isUploaded: false,
                isUploading: false
            }, () => { 
                if(this.props.filesValidation) {
                    this.props.onFileSelect();
                } else {
                    this.handleStartUploading() 
                }
            })
        }
    }

    handleDeleteFile = () => {
        this.setState({ isUploading: true });
        const token = sessionStorage.getItem(TOKEN);
        const options = {};
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('Authorization', `Bearer ${token}`); 
        options.headers.set('Content-Type', 'application/json');
        options.method = 'DELETE';

        try {
            fetch(`${API_URL}/files/${this.state.files[0].id}`, {...options})
                .then((response) => {
                    if (response.status === 204) {
                        this.setState({ isUploading: false, isUploaded: false, files: [] });
                        this.props.showNotification(this.props.translate('messages.file_deleted'))
                        if (this.props.onDeleteAttacments) {
                            this.props.onDeleteAttacments();
                        }
                    }
                })
                .catch((error) => { console.log(error) })
        } catch (error) {
            console.log(error);
        }
    }

    handleStartUploading = () => {
        this.setState({ isUploading: true });
        Promise.all(this.state.files.map(confertToMultiPart))
            .then(convertedFiles => convertedFiles.forEach((file, index) => {
                const token = sessionStorage.getItem(TOKEN);
                const options = {};
                options.headers = new Headers({ Accept: 'application/json' });
                options.headers.set('Authorization', `Bearer ${token}`); 
                options.headers.set('Content-Type', 'application/json');
                options.body = JSON.stringify({ 
                    "src": file, 
                    "title": `${this.state.files[0].title}`,
                    "file_type_id": this.props.fileTypeId,
                    "entity_id": this.props.entityId,
                    "entity_type": this.props.entityType,
                    "related_field": this.props.relatedField,
                    "project_detail": this.props.project_detail_id
                });
                options.method = 'POST';
 
                try {
                    fetch(`${API_URL}/files`, {...options})
                        .then((response) => {
                            if (response.status === 200) {
                                this.setState({ isUploading: false, isUploaded: true });
                                this.props.showNotification(this.props.translate('messages.file_uploaded'));

                                if (this.props.onFileUpload) {
                                    this.props.onFileUpload();
                                }

                                return response.json()
                            }
                        })
                        .then((json) => {
                            this.setState({ files: [json] })
                        })
                        .catch((error) => { console.log(error) })
                } catch (error) {
                    console.log(error);
                }
            }))
    }

    render() {
        
        return (
            <div className="file-uploader-container">
                <input
                    accept={ this.props.extensions ? this.props.extensions : "*/*"}
                    id={this.props.relatedField || 'custom_field'}
                    type="file"
                    className={"input-file"}
                    onChange={this.handleChange}
                />
                <label htmlFor={this.props.relatedField || 'custom_field'}>
                    <Button variant="contained" component="span">
                        {this.props.translate('buttons.select_file')}
                        <FolderOpen style={{ marginLeft: '15px' }} />
                    </Button>
                </label>
                { 
                    this.state.files.length !== 0 && 
                        this.state.files[0].filename 
                            ?   <a className="file-link" href={this.state.files[0].filename} download={this.state.files[0].title}>
                                    {this.state.files[0].title}
                                </a> 
                            :   <span className="file-link">{this.state.files[0] && this.state.files[0].title}</span>
                    
                }
                {
                    this.state.files.length !== 0 &&
                        <div>
                            <Button 
                                variant="contained" component="span"  disabled ={!this.state.isUploaded}
                                onClick={this.handleDeleteFile}
                            >
                                {this.props.translate('buttons.delete')}
                                <DeleteIcon  style={{ marginLeft: '15px' }} />
                            </Button>
                        </div>
                }
                
            </div>
        )
    }
}

const mapDispatchToProps = {
    showNotification
};

export default translate(connect(null, mapDispatchToProps)(
    CustomFileLoader
));