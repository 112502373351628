import React, { Fragment, useState } from 'react';
import {
    ArrayInput,
    FormDataConsumer,
    SimpleFormIterator,
    Labeled,
    TextInput,
    SimpleForm,
    translate,
    LongTextInput,
} from 'react-admin';

import ManageIndicatorsButton from './ManageIndicatorsButton';
import CustomInput from '../../../../components/CustomInput';
import { GoalIndicatorList } from '../../ProjectDetails';

const GoalForm = (props) => {
    function handleShowIndicators(visible) {
        props.onShowPopup('goalIndicators', visible);
    }

    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <Fragment>
                        <CustomInput
                            tooltipText={props.translate(
                                'tooltips.resources.project-details.fields.goal',
                            )}
                        >
                            <LongTextInput
                                source="goal"
                                label={props.translate(
                                    'resources.project-details.fields.goal',
                                )}
                            />
                        </CustomInput>
                        {formData && formData.phase_id > 1 && (
                            <div>
                                <ManageIndicatorsButton
                                    onShow={handleShowIndicators}
                                    show={props.popups.goalIndicators}
                                    source={'goal_indicators'}
                                    title={props.translate(
                                        'resources.outputs.actions.edit',
                                    )}
                                    targets={formData.outcome_targets}
                                    type="goals"
                                    {...props}
                                />
                                {formData.goal_indicators &&
                                    formData.goal_indicators.length > 0 && (
                                        <GoalIndicatorList
                                            indicators={
                                                formData.goal_indicators
                                            }
                                            baseline={formData.baseline}
                                            targets={formData.outcome_targets}
                                            isGoalIndicators
                                            translate={props.translate}
                                        />
                                    )}
                            </div>
                        )}
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(GoalForm);
