import { USER_ROLES } from './constants/common';

export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-grid',
            access: [
                USER_ROLES.ADMIN,
                USER_ROLES.DAU,
                USER_ROLES.VAU,
                USER_ROLES.PHU,
                USER_ROLES.PSU,
                USER_ROLES.PCU,
                USER_ROLES.SU,
                USER_ROLES.AO,
                USER_ROLES.DCU,
                USER_ROLES.SHU,
                USER_ROLES.DOU,
                USER_ROLES.DU,
                USER_ROLES.PAC,
                USER_ROLES.PSE,
                USER_ROLES.SECU
            ],
        },
        {
            name: 'Reports',
            url: '/reports',
            icon: 'icon-pie-chart',
            access: [
                USER_ROLES.ADMIN,
                USER_ROLES.DAU,
                USER_ROLES.VAU,
                USER_ROLES.PHU,
                USER_ROLES.PSU,
                USER_ROLES.PCU,
                USER_ROLES.SU,
                USER_ROLES.AO,
                USER_ROLES.DCU,
                USER_ROLES.SHU,
                USER_ROLES.DOU,
                USER_ROLES.DU,
                USER_ROLES.PAC,
                USER_ROLES.PSE,
            ],
            children: [
                {
                    name: 'Pipeline of Projects',
                    url: '/reports/pip',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                    name: 'Fiscal Load',
                    url: '/reports/fl',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                    name: 'Project Cost Evolution',
                    url: '/reports/pce',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                    name:
                        'Projects Costs and Counts at different stages of the Development Cycle',
                    url: '/reports/project-count-by-sectors',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                    name: 'Projects Ranking',
                    url: '/reports/project-ranking',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                    name: 'Projects Location',
                    url: '/reports/project-geo-by-phases',
                    icon: 'icon-list',
                    access: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.DAU,
                        USER_ROLES.VAU,
                        USER_ROLES.PHU,
                        USER_ROLES.PSU,
                        USER_ROLES.PCU,
                        USER_ROLES.SU,
                        USER_ROLES.AO,
                        USER_ROLES.DCU,
                        USER_ROLES.SHU,
                        USER_ROLES.DOU,
                        USER_ROLES.DU,
                        USER_ROLES.PAC,
                        USER_ROLES.PSE,
                    ],
                },
                {
                  name: 'IBP Usage',
                  url: '/reports/usage',
                  icon: 'icon-list',
                  access: [
                      USER_ROLES.ADMIN,
                      USER_ROLES.DAU,
                      USER_ROLES.VAU,
                      USER_ROLES.PHU,
                      USER_ROLES.PSU,
                      USER_ROLES.PCU,
                      USER_ROLES.SU,
                      USER_ROLES.AO,
                      USER_ROLES.DCU,
                      USER_ROLES.SHU,
                      USER_ROLES.DOU,
                      USER_ROLES.DU,
                      USER_ROLES.PAC,
                      USER_ROLES.PSE,
                  ],
              },
              {
                name: 'Projects scheduled for DC',
                url: '/reports/dc',
                icon: 'icon-list',
                access: [
                    USER_ROLES.ADMIN,
                    USER_ROLES.DAU,
                    USER_ROLES.VAU,
                    USER_ROLES.PHU,
                    USER_ROLES.PSU,
                    USER_ROLES.PCU,
                    USER_ROLES.SU,
                    USER_ROLES.AO,
                    USER_ROLES.DCU,
                    USER_ROLES.SHU,
                    USER_ROLES.DOU,
                    USER_ROLES.DU,
                    USER_ROLES.PAC,
                    USER_ROLES.PSE,
                ],
            },
            ],
        },
        {
            title: true,
            name: 'Administration',
            wrapper: {
                // optional wrapper object
                element: '', // required valid HTML5 element tag
                attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
            },
            class: '', // optional class names space delimited list for title item ex: "text-center"
            access: [
                USER_ROLES.ADMIN,
                USER_ROLES.DAU,
                USER_ROLES.VAU,
                USER_ROLES.PHU,
                USER_ROLES.PSU,
                USER_ROLES.PCU,
                USER_ROLES.SU,
                USER_ROLES.AO,
                USER_ROLES.DCU,
                USER_ROLES.SHU,
                USER_ROLES.DOU,
                USER_ROLES.DU,
                USER_ROLES.PAC,
                USER_ROLES.PSE,
            ],
        },
        {
            name: 'Sectors',
            url: '/sectors',
            icon: 'icon-notebook',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Votes',
            url: '/votes',
            icon: 'icon-book-open',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Programs',
            url: '/vote-functions',
            icon: 'icon-layers',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Funds',
            url: '/funds',
            icon: 'icon-doc',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Source of Funds',
            url: '/fund-sources',
            icon: 'icon-credit-card',
            access: [USER_ROLES.ADMIN],
        },
        // {
        //   name: 'Organizations',
        //   url: '/organizations',
        //   icon: 'icon-list',
        // },
        {
            name: 'Sub Programs',
            url: '/departments',
            icon: 'icon-folder-alt',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Projects',
            url: '/projects',
            icon: 'icon-briefcase',
            access: [
                USER_ROLES.ADMIN,
                USER_ROLES.DAU,
                USER_ROLES.PCU,
                USER_ROLES.PHU,
                USER_ROLES.PSU,
                USER_ROLES.SU,
                USER_ROLES.VAU,
                USER_ROLES.AO,
                USER_ROLES.DCU,
                USER_ROLES.SHU,
                USER_ROLES.DOU,
                USER_ROLES.DU,
                USER_ROLES.PAC,
                USER_ROLES.PSE,
                USER_ROLES.SECU
            ],
        },
        // {
        //   name: 'Project Categories',
        //   url: '/project-categories',
        //   icon: 'icon-list',
        // },
        {
            name: 'Cost Categories',
            url: '/cost-categories',
            icon: 'icon-folder',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'Cost Classification',
            url: '/cost-classifications',
            icon: 'icon-wallet',
            access: [USER_ROLES.ADMIN],
        },
        // {
        //   name: 'Phases',
        //   url: '/phases',
        //   icon: 'icon-list',
        //   access: [USER_ROLES.ADMIN],
        // },
        {
            name: 'File Types',
            url: '/file-types',
            icon: 'icon-paper-clip',
            access: [USER_ROLES.ADMIN],
        },
        {
            name: 'User Management',
            url: '/users',
            icon: 'icon-people',
            access: [USER_ROLES.ADMIN],
        },
        // {
        //   name: 'Currencies',
        //   url: '/currencies',
        //   icon: 'icon-list',
        //   access: [USER_ROLES.ADMIN],
        // },
        {
            name: 'Currency Rates',
            url: '/currency-rates',
            icon: 'icon-calculator',
            access: [USER_ROLES.ADMIN],
        },
        /*{
      name: 'Charts',
      url: '/charts',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Icons',
      url: '/icons',
      icon: 'icon-star',
      children: [
        {
          name: 'CoreUI Icons',
          url: '/icons/coreui-icons',
          icon: 'icon-star',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        },
        {
          name: 'Flags',
          url: '/icons/flags',
          icon: 'icon-star',
        },
        {
          name: 'Font Awesome',
          url: '/icons/font-awesome',
          icon: 'icon-star',
          badge: {
            variant: 'secondary',
            text: '4.7',
          },
        },
        {
          name: 'Simple Line Icons',
          url: '/icons/simple-line-icons',
          icon: 'icon-star',
        },
      ],
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'icon-bell',
      children: [
        {
          name: 'Alerts',
          url: '/notifications/alerts',
          icon: 'icon-bell',
        },
        {
          name: 'Badges',
          url: '/notifications/badges',
          icon: 'icon-bell',
        },
        {
          name: 'Modals',
          url: '/notifications/modals',
          icon: 'icon-bell',
        },
      ],
    },
    {
      name: 'Widgets',
      url: '/widgets',
      icon: 'icon-calculator',
      badge: {
        variant: 'info',
        text: 'NEW',
      },
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'Extras',
    },

    {
      name: 'Download CoreUI',
      url: 'https://coreui.io/react/',
      icon: 'icon-cloud-download',
      class: 'mt-auto',
      variant: 'success',
      attributes: { target: '_blank', rel: 'noopener' },
    },
    {
      name: 'Try CoreUI PRO',
      url: 'https://coreui.io/pro/react/',
      icon: 'icon-layers',
      variant: 'danger',
      attributes: { target: '_blank', rel: 'noopener' },
    },*/
    ],
};
