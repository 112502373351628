import React from 'react';
import HTML2React from 'html2react';
import { Table } from 'reactstrap';
import { romanize } from '../../../helpers/formatters';

export const GovernmentAgencies = props => {
    const { customRecord, translate, counter = 1 } = props;

    if (
        !customRecord.government_coordination ||
        (customRecord.government_coordination &&
            customRecord.government_coordination.length === 0)
    ) {
        return (
            <div>
                <h4>
                    {romanize(counter)}.{' '}
                    {translate('printForm.background.government_agencies')}
                </h4>
                <p>
                    {translate(
                        'printForm.background.government_agencies_empty',
                    )}
                </p>
            </div>
        );
    }
    return (
        <div className="Section2">
            <div className="content-area">
                {/* <h4>{counter}. {translate('printForm.ba<GovernmentAgencies counter="10.2" {...this.props} />
                <br className="pageBreak" />ckground.government_agencies')}</h4> */}
                {/* <ul>
            {
                customRecord.government_coordination &&
                customRecord.government_coordination.map((item, idx) =>
                    <li>
                        <p>{item.name}</p>
                        {HTML2React(item.details)}
                    </li>
                )
            }
            </ul> */}
                {customRecord.government_coordination &&
                    customRecord.government_coordination.length !== 0 && (
                        <div>
                            <h4>
                                {romanize(counter)}.{' '}
                                {translate(
                                    'printForm.background.government_agencies',
                                )}
                            </h4>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customRecord.government_coordination.map(
                                        item => (
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>
                                                    {HTML2React(item.details)}
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}
            </div>
        </div>
    );
};
