import React from 'react';
import { FormDataConsumer, Labeled, translate } from 'react-admin';
import CustomInput from '../../../components/CustomInput';
import CustomTextArea from '../../../components/customTextArea';

const TechnicalApproachForm = props => {
    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <div>
                        <CustomInput  tooltipText={translate('tooltips.resources.project-details.fields.pcn_outcome')} textArea>
                            <Labeled label={props.translate('resources.project-details.fields.pcn_outcome')} />
                            <CustomTextArea  source="pcn_outcome" {...this.props} />
                        </CustomInput>
                        <CustomInput  tooltipText={translate('tooltips.resources.project-details.fields.pcn_interventions')} textArea>
                            <Labeled label={props.translate('resources.project-details.fields.pcn_interventions')} />
                            <CustomTextArea  source="pcn_interventions" {...this.props} />
                        </CustomInput>
                    </div>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(TechnicalApproachForm);
