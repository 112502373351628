import React from 'react';
import lodash from 'lodash';
import CKEditor from '@ckeditor/ckeditor5-react';
import { addField } from 'react-admin';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { API_URL } from '../../constants/common';
import { TOKEN } from '../../constants/auth';

const convertToMultiPart = item =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(item);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(
            file =>
                new Promise((resolve, reject) => {
                    this._initRequest();
                    this._initListeners(resolve, reject, file);
                    this._sendRequest(file);
                }),
        );
    }

    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());

        xhr.open('POST', `${API_URL}/files`, true);
        xhr.responseType = 'json';
        xhr.setRequestHeader(
            'Authorization',
            `Bearer ${sessionStorage.getItem(TOKEN)}`,
        );
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;

            if (!response || response.error) {
                return reject(
                    response && response.error
                        ? response.error.message
                        : genericErrorText,
                );
            }

            resolve({
                default: response.filename,
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
        convertToMultiPart(file).then(converted => {
            const data = new FormData();

            data.append('src', converted);
            data.append('title', `${file.name}`);
            data.append('entity_id', 77);
            data.append('entity_type', 'editor');
            data.append('related_field', 'summary');
            data.append('project_detail', 77);
            data.append('file_type', 'image');

            this.xhr.send(data);
        });
    }
}

function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new UploadAdapter(loader);
    };
}

const CustomTextArea = props => (
    <div className="aor-ckeditor-input">
        <CKEditor
            editor={ClassicEditor}
            config={{
                allowedContent: true,
                extraPlugins: [CustomUploadAdapterPlugin],
            }}
            data={
                lodash.get(props.formData, props.source) ||
                lodash.get(props.formValues, props.source) ||
                lodash.get(props.record, props.source) ||
                ''
            }
            onChange={(event, editor) => props.input.onChange(editor.getData())}
            {...props}
        />
        {props.meta && props.meta.touched && props.meta.error && (
            <div style={{ color: '#f44336' }}>{props.meta.error}</div>
        )}
    </div>
);

CustomTextArea.defaultProps = {
    addField: true,
};

export default addField(CustomTextArea);
