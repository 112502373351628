import {Button} from 'react-admin';
import CustomMap from '../../../components/customMap';
import { GOOGLE_MAP_API_KEY, LOCATIONS } from '../../../constants/common';
import HTML2React from 'html2react'
import Map from '@material-ui/icons/Map';
import React from 'react';
import { Table } from 'reactstrap';
import { romanize } from '../../../helpers/formatters';
import lodash from 'lodash';

const getLocationName = (customRecord) => {
    const location = lodash.find(LOCATIONS, 
        (item) => Number(item.id) === Number(customRecord.location_id));

    return location 
        ? location.name 
        : null;
}

export const ProjectBackGround = props => {
    const { customRecord, translate } = props;
    const counter = props.counter || 1;
    let markersCoords = [];

    if (!customRecord) return null;

    var staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";
        staticMapUrl += "?center=" +  0.18 + "," + 32.37;
        staticMapUrl += "&size=700x300&scale=2";
        staticMapUrl += "&maptype=roadmap";
        staticMapUrl += "&zoom=" + 6;
        //key
        staticMapUrl += "&key=" + GOOGLE_MAP_API_KEY;
    if (customRecord && customRecord.geo_location && customRecord.geo_location.indexOf(',') > -1) {
        try {
            markersCoords = JSON.parse(customRecord.geo_location)
              .map((item) => `${String(item.lat)},${String(item.lng)}`)
          } catch (error) {
            console.log(error);
        }
        staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";
        staticMapUrl += "?center=" +  0.18 + "," + 32.37;
        staticMapUrl += "&size=700x300&scale=2";
        staticMapUrl += "&maptype=roadmap";
        staticMapUrl += "&zoom=" + 6;
        staticMapUrl += "&markers=" + markersCoords.join('|');
        //key
        staticMapUrl += "&key=" + GOOGLE_MAP_API_KEY;
    }

    return (
        <div className="Section2">
            <div className="content-area">
                <h4>{romanize(counter)}.  {translate('printForm.background.title')}</h4>
                <div>
                    <p><strong>{romanize(counter + '.1.')}  {translate('printForm.background.situation_analysis')}</strong></p>
                    {HTML2React(customRecord.situation_analysis)}
                </div>
                <div>
                    <p><strong>{romanize(counter + '.2.')}  {translate('printForm.background.problem_statement')}</strong></p>
                    {HTML2React(customRecord.problem_statement)}
                </div>
                <div>
                    <p><strong>{romanize(counter + '.2.1.')}  {translate('printForm.background.problem_cause')}</strong></p>
                    {HTML2React(customRecord.problem_cause)}
                </div>
                <div>
                    <p><strong>{romanize(counter + '.2.2.')}  {translate('printForm.background.problem_effects')}</strong></p>
                    {HTML2React(customRecord.problem_effects)}
                </div>
                <div>
                    <p><strong>{romanize(counter + '.3.')}  {translate('printForm.background.strategic_fit')}</strong></p>
                    {HTML2React(customRecord.strategic_fit)}
                </div>
                <div>
                    {
                        customRecord && customRecord.in_ndp === 1
                            ? renderNdp(props)
                            : renderNdpOther(props)
                    }
                </div>
                <div>
                    <p><strong>{romanize(counter + '.4.')}  {translate('printForm.background.justification')}</strong></p>
                    {HTML2React(customRecord.justification)}
                </div>
                <div>
                    <p><strong>{romanize(counter + '.5.')}  {translate('printForm.background.stakeholders')}</strong></p>
                    {HTML2React(customRecord.stakeholders)}
                </div>
                <div>
                    <p>
                        <strong>{romanize(counter + '.6.')}  {translate('printForm.background.location')}</strong>
                        <Button label={props.showMap ? translate('buttons.hide_map') : translate('buttons.show_map')} 
                            onClick={props.onShowMap} className="static_map_btn" style={{ marginLeft: '15px' }}>
                            <Map />
                        </Button>
                    </p>
                    { getLocationName(customRecord) && <p>{getLocationName(customRecord)}</p>}
                    {HTML2React(customRecord.location)}
                    {
                        props.showMap   &&
                        <p>
                            <img id="map_img" className="static_map" width="650px" height="350px"
                             src={staticMapUrl} alt="GeoMap of Project Location" />
                        </p>
                    }
                </div>
                <div>
                    <p><strong>{romanize(counter + '.7.')}  {translate('printForm.background.technical_description')}</strong></p>
                    {HTML2React(customRecord.summary)}
                </div>
            </div>
        </div>
    )
}



const renderNdp = (props) => {
    const { customRecord, translate } = props;
    
    if (!customRecord) return null;

    if (customRecord.ndp_type === 'core'){
        return (
            <div>
                <p>{translate('printForm.background.ndp_project_name')}:</p>
                <p>{customRecord.ndp_name}</p>
                <p>{translate('printForm.background.ndp_page_no')}:</p>
                <p>{customRecord.ndp_page_no}</p>
                <p>{translate('printForm.background.details')}:</p>
                {HTML2React(customRecord.plan_details)}
            </div>
        )
    }
    return(
        <div>
            <div>
                <p>{translate('printForm.background.ndp_focus_area')}:</p>
                <p>{translate(`resources.project-details.fields.focus_area.${customRecord.focus_area}`)}</p>
            </div>
            <div>
                <p>{translate('printForm.background.ndp_interventions')}:</p>
                <p>{customRecord.intervention}</p>
            </div>
            <div>
                <p>{translate('printForm.background.details')}:</p>
                {HTML2React(customRecord.plan_details)}
            </div>
        </div>
    )
}

const renderNdpOther = (props) => {
    const { customRecord, translate } = props;

    if (!customRecord) return null;

    return (
        <div>
            <p>{translate('printForm.background.strategic')}</p>
            {HTML2React(customRecord.other_strategic_directives)}
            <p>{translate('printForm.background.details')}</p>
            {HTML2React(customRecord.plan_details)}
        </div>
    )
}
