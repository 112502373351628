import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Responsive } from 'react-admin';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardActions } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Notification, translate, userLogin } from 'react-admin';
import moment from 'moment';

const styles = {
    bgImageleft: {
        backgroundImage: 'url(assets/img/logo_new.png)',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '305px',
        position: 'absolute',
        left: '5%',
        backgroundSize: '90%',
    },
    bgImageRight: {
        backgroundImage: 'url(assets/img/coat_of_arms.png)',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '305px',
        position: 'absolute',
        right: '5%',
        backgroundSize: '90%',
    },
    sm_bgImageLeft: {
        backgroundImage: 'url(assets/img/logo_new.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        width: '150px',
        height: '150px',
    },
    sm_bgImageRight: {
        backgroundImage: 'url(assets/img/coat_of_arms.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '90%',
        width: '150px',
        height: '150px',
    },
    small_box: {
        display: 'flex',
        margin: '75px auto',
        alignItems: 'center',
        justifyContent: 'space-around',
        textAlign: 'center',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#d1bc9b',
    },
    header: {
        position: 'absolute',
        left: '15px',
        top: '15px',
    },
    title: {
        marginBottom: '55px',
        textShadow: '2px 2px #ffffff',
        textAlign: 'center',
    },
    card: {
        minWidth: 300,
    },
    avatar: {
        margin: '1em',
        textAlign: 'center ',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        display: 'flex',
    },
    hint: {
        textAlign: 'center',
        marginTop: '1em',
        color: '#ccc',
    },
    footer: {
        position: 'absolute',
        bottom: '15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        color: 'rgba(255, 255, 255, 1.00)',
        textShadow:
            '1px 1px 2px rgba(0, 0, 0, 1.00), 0px 0px 5px rgba(0, 0, 0, 1.00)',
        leftTitle: {
            paddingLeft: '15px',
        },
        rightTitle: {
            paddingRight: '15px',
            textAlign: 'center'
        },
    },
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {}, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const login = (auth, dispatch, { redirectTo }) =>
    dispatch(userLogin(auth, '/dashboard'));

class Login extends Component {
    render() {
        const { handleSubmit, isLoading, theme, translate } = this.props;
        const muiTheme = getMuiTheme(theme);

        return (
            <MuiThemeProvider muiTheme={muiTheme}>
                <Responsive
                    small={
                        <div style={{ ...styles.main }}>
                            <h1 style={{ ...styles.title }}>
                                Integrated Bank of Projects
                            </h1>

                            <Card style={styles.card}>
                                <div style={styles.avatar}>
                                    <Avatar icon={<LockIcon />} size={60} />
                                </div>
                                <form onSubmit={handleSubmit(login)}>
                                    <div style={styles.form}>
                                        <div style={styles.input}>
                                            <Field
                                                name="username"
                                                component={renderInput}
                                                floatingLabelText={translate(
                                                    'ra.auth.username',
                                                )}
                                            />
                                        </div>
                                        <br />
                                        <div style={styles.input}>
                                            <Field
                                                name="password"
                                                component={renderInput}
                                                floatingLabelText={translate(
                                                    'ra.auth.password',
                                                )}
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <CardActions>
                                        <Button
                                            variant="raised"
                                            type="submit"
                                            color="primary"
                                            disabled={isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            {isLoading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                            {translate('ra.auth.sign_in')}
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>
                            <Notification />
                            <div style={{ ...styles.small_box }}>
                                <div
                                    style={{
                                        ...styles.sm_bgImageLeft,
                                        ...styles.smallSizeImg,
                                    }}
                                ></div>
                                <div
                                    style={{
                                        ...styles.sm_bgImageRight,
                                        ...styles.smallSizeImg,
                                    }}
                                ></div>
                            </div>
                            <div style={styles.footer}>
                            <h5 style={styles.footer.rightTitle}>
                                {`Copyright © ${moment().format('YYYY')}, Ministry of Finance, Planning and Economic Development
                                 `}   
                                </h5>
                            </div>
                        </div>
                    }
                    medium={
                        <div style={{ ...styles.main }}>
                            <div style={{ ...styles.bgImageleft }}></div>
                            <h1 style={{ ...styles.title }}>
                                Integrated Bank of Projects
                            </h1>
                            <Card style={styles.card}>
                                <div style={styles.avatar}>
                                    <Avatar icon={<LockIcon />} size={60} />
                                </div>
                                <form onSubmit={handleSubmit(login)}>
                                    <div style={styles.form}>
                                        <div style={styles.input}>
                                            <Field
                                                name="username"
                                                component={renderInput}
                                                floatingLabelText={translate(
                                                    'ra.auth.username',
                                                )}
                                            />
                                        </div>
                                        <br />
                                        <div style={styles.input}>
                                            <Field
                                                name="password"
                                                component={renderInput}
                                                floatingLabelText={translate(
                                                    'ra.auth.password',
                                                )}
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <CardActions>
                                        <Button
                                            variant="raised"
                                            type="submit"
                                            color="primary"
                                            disabled={isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            {isLoading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                            {translate('ra.auth.sign_in')}
                                        </Button>
                                    </CardActions>
                                </form>
                            </Card>
                            <div style={{ ...styles.bgImageRight }}></div>
                            <Notification />

                            <div style={styles.footer}>
                                <h5 style={styles.footer.rightTitle}>
                                {`Copyright © ${moment().format('YYYY')}, Ministry of Finance, Planning and Economic Development
                                 `}   
                                </h5>
                            </div>
                        </div>
                    }
                />
            </MuiThemeProvider>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authClient: PropTypes.func,
    previousRoute: PropTypes.string,
    theme: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

Login.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = (state) => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username)
                errors.username = translate('ra.validation.required');
            if (!values.password)
                errors.password = translate('ra.validation.required');
            return errors;
        },
    }),
);

export default enhance(Login);
