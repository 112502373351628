import React, { Fragment, Component } from 'react';
import {
    BooleanField,
    CloneButton,
    Create,
    Edit,
    EditButton,
    Datagrid,
    DateField,
    FunctionField,
    ReferenceField,
    ReferenceManyField,
    RichTextField,
    Tab,
    TabbedShowLayout,
    TextField,
    translate,
    ReferenceArrayField,
    ArrayField,
    SimpleFormIterator,
    FormDataConsumer
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ProjectForm from './ProjectForm/';
import { InvestmentList } from './InvestmentList';
import ImplementingAgencies from './ImplementingAgencies'
import { Table } from 'reactstrap';
import ProjectDetailShow from '../../views/ProjectDetailShow';
import lodash from 'lodash';
import { getFiscalYearsRangeForIntervals } from '../../helpers/formatters';

const styles = {
    root: {
        '& p': {
            display: 'none',
        },
    },
};


const getYears = (years) => {
    if (years) {
        const startDate = years[0];
        const endDate = years[years.length - 1];

        return getFiscalYearsRangeForIntervals(startDate, endDate);
    }

    return [];
}

const redirect = (basePath, id, data) =>
    `/projects/${data.project_id}/show/${data.phase_id}`;

const editRedirect = (basePath, id, data) => 
    `/project-details/${data.id}`;

export const IndicatorList = (props, isGoalIndicators = false) => {
    const { baseline = '', targets = [], indicators = [], translate } = props;
    const ids = [...indicators.keys()];
    const sort = { field: 'id', order: 'ASC' };
    
    if (indicators.length === 0) {
        return null;
    }

    return (
        <Datagrid
            ids={ids}
            data={props.indicators}
            basePath="project-details"
            resource="indicators"
            currentSort={sort}
            {...props}
        >
            <TextField source="title" />
            <TextField source="baseline" />
            {targets && getYears(targets).map(target => (
                <TextField source={target.id} label={target.name} />
            ))}
            <TextField source="assumptions" />
            <TextField source="verification_means" />
            <TextField source="risk_factors" />
        </Datagrid>
    );
};

export const GoalIndicatorList = (props, isGoalIndicators = false) => {
    const { baseline = '', targets, indicators = [], translate } = props;
    const ids = [...indicators.keys()];
    const sort = { field: 'id', order: 'ASC' };
    let resultTargets = targets;

    if (indicators.length === 0) {
        return null;
    }

    if (typeof targets === 'string') {
        resultTargets = targets.split(',')
    }

    return (
        <Datagrid
            ids={ids}
            data={props.indicators}
            basePath="project-details"
            resource="indicators"
            currentSort={sort}
            {...props}
        >
            <TextField source="title" />
            <TextField source="baseline" /> 
            {resultTargets && getYears(resultTargets).map(target => (
                <TextField source={target.id} label={target.name} />
            ))}
            <TextField source="verification_means" />
        </Datagrid>
    );
};

const ProjectDetails = ({ ids = [], data = [], basePath = '' }) => {
    ids.sort();
    
    return (
        <div style={{ marginTop: '55px' }}>
            <TabbedShowLayout>
                {lodash.keys(data).map(id => {
                    const record = data[id];

                    if (record) {
                        return (
                            <Tab
                                key={`project-detail-tab-${id}`}
                                label={`resources.phases.phase_${record.phase_id}`}
                                path={`${record.phase_id}`}
                            >
                                <ProjectDetailShow
                                    customBasePath={basePath}
                                    customRecord={record}
                                />
                            </Tab>
                        );
                    }

                    return null;
                })}
            </TabbedShowLayout>
        </div>
    );
};


ProjectDetails.defaultProps = {
    data: {},
    ids: [],
};

export const ProjectCreate = withStyles(styles)(({ classes, ...props }) => {
    return (
        <Create {...props} redirect="show">
            <ProjectForm redirect={editRedirect} {...props} />
        </Create>
    );
});

//add props undoable={false} for save without undo
export const ProjectEdit = withStyles(styles)(({ classes, ...props }) => {
    return (
        <Edit {...props} undoable={false} actions={false} redirect="show"> 
            <ProjectForm redirect={redirect} {...props} />
        </Edit>
    );
});

export default ProjectDetails;
