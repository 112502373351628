import HTML2React from 'html2react'
import React from 'react';
import { Table } from 'reactstrap';
import { romanize } from '../../../helpers/formatters';

export const ProjectFramework = props => {
    const { customRecord, translate, counter } = props;

    return (
        <div className="landscapeSection">
         <div className="content-area">
            <h4>{romanize(counter)}.  {translate('printForm.project_framework.title')}</h4>
            <Table responsive striped bordered>
                <tbody>
                    <tr><td><strong>{romanize(counter + '.1')} {translate('printForm.project_framework.goal')}</strong></td></tr>
                    <tr>
                        <td>
                            <ul>
                                <li>{romanize(counter + '.1.1')} {customRecord.goal}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr><td><strong>{romanize(counter + '.2')} {translate('printForm.project_framework.outcome', { smart_count: 2 })}</strong></td></tr>
                    <tr>
                        <td>
                        {
                            customRecord && customRecord.outcomes && customRecord.outcomes.length !== 0 
                                ?   <ul>
                                        {
                                            customRecord.outcomes.map((item, idx) =>
                                                <li>{romanize(counter + '.2.' + Number(idx + 1))} {`${translate('printForm.project_framework.outcome', { smart_count: 1 })} ${idx + 1}: ${item.title}`}</li>
                                            )
                                        }
                                    </ul>
                                :   null
                        }
                        </td>
                    </tr>
                    <tr><td><strong>{romanize(counter + '.3')} {translate('printForm.project_framework.output', { smart_count: 2 })}</strong></td></tr>
                    <tr>
                        <td>
                        {
                            customRecord && customRecord.outputs && customRecord.outputs.length !== 0 
                                ?   <ul>
                                        {
                                            customRecord.outputs.map((item, idx) =>
                                                <li>
                                                    {romanize(counter + '.3.' + Number(idx + 1))}   {`${translate('printForm.project_framework.output', { smart_count: 1 })} ${idx + 1}: ${item.title}`}
                                                    <br/>
                                                    <div style={{ fontStyle: 'italic' }}>
                                                        {HTML2React(item.description)}
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </ul>
                                :   null
                        }
                        <br></br>
                        {
                            customRecord && customRecord.activities && customRecord.activities.length !== 0
                                ?   <p>
                                        {`${translate('printForm.project_framework.activity', { smart_count: 2 })}: ${
                                            customRecord.activities ? customRecord.activities.map((item) => item.title).join(', ') : ''
                                        }`}
                                    </p>
                                :   null
                        }
                        </td>
                    </tr>
                </tbody>
            </Table>
            </div>
        </div>
    )
};
