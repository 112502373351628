import React from 'react';
import { Table } from 'reactstrap';
import { costSumFormatter } from '../helpers';
import lodash from 'lodash';
import { romanize } from '../../../helpers/formatters';

export const OutputInvestments = props => {
    const { customRecord, counter, translate } = props;
        const record = customRecord;
        let totalByYears = {};
        let totalByYearsAll = 0;

        if (!record) {
            return null;
        }


        return(
            <div className="landscapeSection">
            <div className="content-area">
                <h4>{romanize(counter)}.  {translate('printForm.investments.outputs_title')}</h4>
                <Table responsive bordered>
                    <thead>
                        <tr className="filled-row">
                            <th>{translate('printForm.project_framework.output', { smart_count: 2 })}</th>
                            {
                                record.investment_years.map(year =>
                                    <th>{year}</th>
                                )
                            }
                            <th>{translate('printForm.investments.total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            record.outputs.map((output) => {
                                let outputTotal = 0;
                                
                                return (
                                    <tr>
                                        <td>{output.title}</td>
                                        {                                              
                                            record.investment_years.map((year) => {
                                                let investmentSum = lodash.sumBy(output.investments, (item) => 
                                                    item[year] ? parseFloat(item[year]) : 0)
                                                outputTotal += investmentSum ? investmentSum : 0;

                                                totalByYears[year] =  totalByYears[year]
                                                    ? totalByYears[year] + investmentSum
                                                    : investmentSum
                                                return (
                                                    <td>{costSumFormatter(investmentSum)}</td>
                                                )
                                            })
                                        }
                                        <td><b>{isNaN(outputTotal) ? '-' : costSumFormatter(outputTotal)}</b></td>
                                    </tr>
                                )
                            })
                            
                        }
                        <tr>
                            <td><b>{translate('printForm.investments.total')}</b></td>
                            {
                                    record.investment_years.map((year) => {
                                        totalByYearsAll += totalByYears[year] ? totalByYears[year] : 0;

                                        return (
                                            <td>
                                                <b>{ costSumFormatter(totalByYears[year]) }</b>
                                            </td>
                                        )
                                    }
                                   
                                )
                            }
                            <td><b>{costSumFormatter(totalByYearsAll)}</b></td>
                        </tr>
                    </tbody>
                </Table>
            </div></div>
        )
};
