import React, { Fragment, Component }  from 'react';
import {
    Labeled, TextInput,   FileInput, 
    FileField, SelectInput, required, translate
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomInput from '../../../../components/CustomInput';
import CustomTextArea from '../../../../components/customTextArea';

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

class OptionModules extends Component {
    state = {
        activeTab: 0,
    }

    option_modules = [
        {
            name: this.props.translate('resources.options_appraisals.fields.building_blocks.modules.demand_module'),
            id: 'demand_module'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.building_blocks.modules.technical_module'),
            id: 'technical_module'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.building_blocks.modules.env_module'),
            id: 'env_module'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.building_blocks.modules.hr_module'),
            id: 'hr_module'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.building_blocks.modules.legal_module'),
            id: 'legal_module'
        },
    ]

    handleChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
        // this.props.save(this.props.formValues , false);
    }

    renderTabs () {
        return this.option_modules
            .map((module) => 
                <Tab label={module.name} />
            )
    }

    renderTabContent = (activeTab)=>  {
        const { getSource, translate, formValues } = this.props;
        const optionsCount = this.props.record && this.props.record.options_appraisals
            && this.props.record.options_appraisals.length;

        return (
            <Fragment>
                <CustomInput tooltipText={translate(`tooltips.resources.options_appraisals.fields.building_blocks.description.${this.option_modules[activeTab].id}`)} textArea>
                    <Labeled label={translate('resources.options_appraisals.fields.building_blocks.description')} />
                    <CustomTextArea source={getSource(`${this.option_modules[activeTab].id}.description`)} {...this.props} />
                </CustomInput>
                {
                     this.props.record.phase_id === 3 &&
                     <Fragment>
                        <CustomInput tooltipText={translate(`tooltips.resources.options_appraisals.fields.building_blocks.advantage.${this.option_modules[activeTab].id}`)} textArea>
                            <Labeled label={translate('resources.options_appraisals.fields.building_blocks.advantage')} />
                            <CustomTextArea  source={getSource(`${this.option_modules[activeTab].id}.advantage`)} {...this.props} />
                        </CustomInput>
                        <CustomInput tooltipText={translate(`tooltips.resources.options_appraisals.fields.building_blocks.disadvantage.${this.option_modules[activeTab].id}`)} textArea>
                            <Labeled label={translate('resources.options_appraisals.fields.building_blocks.disadvantage')} />
                            <CustomTextArea source={getSource(`${this.option_modules[activeTab].id}.disadvantage`)} {...this.props} />
                        </CustomInput>
                        <SelectInput 
                            className="boolean-selector"
                            label={translate('resources.options_appraisals.fields.building_blocks.score')}
                            source={getSource(`${this.option_modules[activeTab].id}.score`)}
                            choices={[
                                { id: 0, name: '0' },
                                { id: 1, name: '1' },
                                { id: 2, name: '2' },
                                { id: 3, name: '3' },
                                { id: 4, name: '4' },
                                { id: 5, name: '5' },
                            ]} 
                        />
                     </Fragment>
                }
            </Fragment>
        )
    }

    render() {
        const { activeTab } = this.state;
        const { record, getSource, translate } = this.props;

        return (
            <div className="options-modules">
                <h5 style={{ paddingLeft: '15px', lineHeight: '45px' }}>{translate('resources.options_appraisals.fields.building_blocks.title')}</h5>
                <Tabs
                    value={activeTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={null}
                >
                    { this.renderTabs() }
                </Tabs>
                {activeTab === 0 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 1 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 2 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 3 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 4 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                
            </div>
        )
    }
}

export default translate(OptionModules);
