import React from 'react';
import { Table } from 'reactstrap';
import { romanize } from '../../../helpers/formatters';

export const ImplementingAgencies = props => {
    const { customRecord, translate, counter } = props;
    const mainVote = customRecord && customRecord.vote_id;
    const implementingAgencies = customRecord.outputs
        // .filter((item) => item.vote_id !== mainVote);

    if (customRecord.implementing_agencies && implementingAgencies.length !== 0) {
        return(
        <div className="Section2">
            <div className="content-area">
                <h4>{romanize(counter)}. {translate('printForm.background.implementing_agencies')}</h4>
                <Table responsive bordered>
                    {/* <thead>
                        <tr>
                            <th colSpan="2">{counter}. {translate('printForm.background.implementing_agencies')}</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        {
                            implementingAgencies.map((item) =>
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{`${item.vote.code} - ${item.vote.name}`}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            </div>
        )
    }

    return (
        null
    )
}