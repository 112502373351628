import React from 'react';
import { Table } from 'reactstrap';
import { translate, ReferenceField, FunctionField } from 'react-admin';
import {
    getTotalProjectCost,
    getFiscalYears,
    getTotalProjectOutputsCost,
} from '../helpers';
import moment from 'moment';
import { dateFormatter } from '../../Projects/helpers';
import { Fragment } from 'react';

const renderSubmissionDates = (props) => {
    const { customRecord, translate } = props;

    if (customRecord) {
        if (customRecord.phase_id === 4) {
            return [
                <tr>
                    <td>
                        {translate(
                            `printForm.project_info.project_approved.phase_${
                                Number(customRecord.phase_id) - 1
                            }`,
                        )}
                        :
                    </td>
                    <td>
                        {moment(customRecord.created_at).format('DD.MM.YYYY')}
                    </td>
                </tr>,
                <tr>
                    <td>
                        {translate(
                            `printForm.project_info.project_approved.phase_${customRecord.phase_id}`,
                        )}
                        :
                    </td>
                    <td>{dateFormatter(customRecord.created_at)}</td>
                </tr>,
            ];
        }

        return (
            <tr>
                <td>
                    {translate(
                        `printForm.project_info.project_approved.phase_${customRecord.phase_id}`,
                    )}
                    :
                </td>
                <td>{dateFormatter(customRecord.created_at)}</td>
            </tr>
        );
    }

    return null;
};

export const ProjectInformation = (props) => {
    const { customRecord, customBasePath, translate } = props;
    const record = customRecord;
    const basePath = customBasePath;

    if (!record) {
        return null;
    }

    let formattedCostResult =
        record && record.phase_id > 1
            ? String(getTotalProjectCost(record)).replace(
                  /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                  '$1,',
              ) + ' UGX'
            : String(getTotalProjectOutputsCost(record)).replace(
                  /(\d)(?=(\d\d\d)+([^\d]|$))/g,
                  '$1,',
              ) + ' UGX';

    return (
        <div className="Section2">
            <div className="content-area">
                <h4>{record.title}</h4>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>
                                {translate('resources.sectors.fields.name', {
                                    smart_count: 1,
                                })}
                                :{' '}
                            </td>
                            <td>{record.sector && record.sector.name}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate('resources.votes.fields.code', {
                                    smart_count: 1,
                                })}
                                :{' '}
                            </td>
                            <td>{record.vote && `${record.vote.code}`}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate('resources.votes.fields.name', {
                                    smart_count: 1,
                                })}
                                :{' '}
                            </td>
                            <td>{record.vote && `${record.vote.name}`}</td>
                        </tr>

                        <tr>
                            <td>
                                {translate(
                                    'resources.vote-functions.fields.code',
                                    { smart_count: 1 },
                                )}
                                :{' '}
                            </td>
                            <td>
                                {record.vote_function &&
                                    `${record.vote_function.code}`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'resources.vote-functions.fields.name',
                                    { smart_count: 1 },
                                )}
                                :{' '}
                            </td>
                            <td>
                                {record.vote_function &&
                                    `${record.vote_function.name}`}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'resources.departments.fields.name',
                                    { smart_count: 1 },
                                )}
                                :{' '}
                            </td>
                            <td>
                                {record.department && record.department.name}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'printForm.project_info.project_title',
                                )}
                                :
                            </td>
                            <td>{record.title}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate('printForm.project_info.project_no')}
                                :
                            </td>
                            <td>{record.project_no}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'printForm.project_info.project_duration',
                                )}
                                :
                            </td>
                            <td>
                                {getFiscalYears(
                                    record.start_date,
                                    record.end_date,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'printForm.project_info.project_cost',
                                )}
                                :
                            </td>
                            <td>{formattedCostResult}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate(
                                    'printForm.project_info.current_investment_ratio',
                                )}
                                :
                            </td>
                            <td>{`${record.current_investment_ratio}`}</td>
                        </tr>
                        <tr>
                            <td>
                                {translate('printForm.project_info.officer')}:
                            </td>
                            <td>
                                {record.responsible_officers &&
                                    record.responsible_officers.map((item) => (
                                        <Fragment>
                                            <p>
                                                {translate(
                                                    'printForm.project_info.responsible_officers.title',
                                                )}
                                                :{' '}
                                                {item.title
                                                    ? item.title
                                                    : '-'}
                                            </p>
                                            <p>
                                                {translate(
                                                    'printForm.project_info.responsible_officers.name',
                                                )}
                                                :{' '}
                                                {item.name
                                                    ? item.name
                                                    : '-'}
                                            </p>
                                            <p>
                                                {translate(
                                                    'printForm.project_info.responsible_officers.mobile_phone',
                                                )}
                                                :{' '}
                                                {item.mobile_phone
                                                    ? item.mobile_phone
                                                    : '-'}
                                            </p>
                                            <p>
                                                {translate(
                                                    'printForm.project_info.responsible_officers.phone',
                                                )}
                                                :{' '}
                                                {item.phone
                                                    ? item.phone
                                                    : '-'}
                                            </p>
                                            <p>
                                                {translate(
                                                    'printForm.project_info.responsible_officers.email',
                                                )}
                                                :{' '}
                                                {item.email
                                                    ? item.email
                                                    : '-'}
                                            </p>
                                        </Fragment>
                                    ))}
                            </td>
                        </tr>
                        {record &&
                            record.phase_id > 1 &&
                            renderSubmissionDates(props)}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
