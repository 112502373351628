import {
    Datagrid,
    Filter,
    FunctionField,
    List,
    translate,
    ReferenceField,
    ReferenceInput,
    Responsive,
    SelectInput,
    SimpleList,
    TextField,
    TextInput,
} from 'react-admin';
import lodash from 'lodash';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { dateFormatter } from '../Projects/helpers';

function ReportDC({ record, translate, ...props }) {
    return (
        <List
            {...props}
            basePath="/projects"
            resource="projects"
            bulkActionButtons={false}
            actions={false}
            perPage={100}
            filter={{ current_step: 16, expand: 'timeline' }} //TODO add last step workflow
        >
            {/* <CustomProjectsList /> */}
            <Datagrid rowClick={'show'}>
                <TextField source="project_no" />

                <FunctionField
                    source="department"
                    label={translate(
                        `resources.projects.fields.sector_id`,
                    )}
                    render={(record) =>
                        record &&
                        record.department &&
                        record.department.sector &&
                        record.department.sector.name
                    }
                />
                <FunctionField
                    source="department"
                    label={translate(`resources.projects.fields.vote_id`)}
                    render={(record) =>
                        record &&
                        record.department &&
                        record.department.sector &&
                        record.department.vote.name
                    }
                />
                <TextField source="title" />
                <TextField source="phase" />
                <FunctionField
                    source="workflow"
                    label={translate(`resources.projects.fields.status`)}
                    render={(record) =>
                        'Waiting for DC decision'
                    }
                />

                <FunctionField
                    source="created_on"
                    label={translate(
                        `resources.projects.fields.created_at`,
                    )}
                    render={(record) =>
                        record
                            ? record.timeline &&
                              dateFormatter(record.timeline.created_at, false)
                            : null
                    }
                />
            </Datagrid>
        </List>
    );
}

export default translate(ReportDC);