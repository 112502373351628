import React, { Fragment, Component } from 'react';
import {
    ArrayInput,
    BooleanInput,
    FormDataConsumer,
    SimpleFormIterator,
    TextInput,
    translate,
    SelectInput,
    number,
    DisabledInput,
    maxValue,
    required,
    showNotification,
    Labeled,
    ArrayField,
    SingleFieldList,
} from 'react-admin';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import StepIcon from '@material-ui/core/StepIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from 'reactstrap';

import BuildingBlocks from './BuildingBlocks';
import AnalyticalModules from './AnalyticalModules';
import CustomInput from '../../../../components/CustomInput';
import CustomTextArea from '../../../../components/customTextArea';
import CustomFormIterator from '../../../../components/customFormIterator';

import './styles.scss';
import lodash from 'lodash';
import { getFormValues, getFormSyncErrors  } from 'redux-form';
// import { updateSyncErrors } from 'redux-form/lib/actions';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import { commasFormatter, commasParser, validateModulesFill,validateModulesFill_Form, validateEvaluationsFill, validateSummaryFill, validateBestSelectionFill, validateTopOptions } from '../../helpers';
import { costSumFormatter } from '../../helpers'

const { updateSyncErrors, setSubmitFailed } = require('redux-form/lib/actions').default;

class OptionsAppraisalForm extends Component {
    state = {
        shortlistedOptions: [],
        activeStep: 0,
        tempStep: 0,
    };

    getTopScoredOptions = isBestId => {
        const { formValues } = this.props;

        if (!formValues) return null;

        const optionsScore = formValues.options_appraisals && formValues.options_appraisals.map(
            (option, idx) => {
                return {
                    id: idx,
                    name: option.title,
                    score:
                        (option.demand_module
                            ? option.demand_module.score
                            : 0) +
                        (option.technical_module
                            ? option.technical_module.score
                            : 0) +
                        (option.env_module ? option.env_module.score : 0) +
                        (option.hr_module ? option.hr_module.score : 0) +
                        (option.legal_module ? option.legal_module.score : 0),
                };
            },
        );
        const shortlistedOptions = lodash
            .sortBy(optionsScore, 'score')
            .reverse()
            .slice(0, optionsScore.length > 3 ? 3 : optionsScore.length) //top scored 3 selection

        formValues.options_appraisals.forEach((item, idx) => {
            if (item.is_best) {
                formValues.is_best = idx;
            }
            item.is_shortlisted = 0;
            item.is_best = 0;
        });

        if (shortlistedOptions) {
            shortlistedOptions.forEach((item, idx) => {
                formValues.options_appraisals[item.id].is_shortlisted =
                    idx < 3 && item.score > 0 ? 1 : 0;
                formValues.options_appraisals[item.id].score = isNaN(item.score)
                    ? 0
                    : item.score;

                if (
                    typeof isBestId !== 'undefined' ||
                    (formValues && typeof formValues.is_best !== 'undefined')
                ) {
                    const bestOptionIndex =
                        isBestId || (formValues && formValues.is_best);
                    if (formValues.options_appraisals[bestOptionIndex]) {
                        formValues.options_appraisals[
                            bestOptionIndex
                        ].is_best = 1;
                    }
                }
            });
        }
        return shortlistedOptions.filter(item => item.score > 0);
    };

    componentDidMount() {
        const { formValues } = this.props;

        if (!formValues) return null;

        const isBest = lodash.findIndex(
            formValues.options_appraisals,
            item => item.is_best,
        );
        if (formValues && typeof isBest !== 'undefined') {
            formValues.is_best = isBest;
        }
    }

    componentWillReceiveProps(nextProps) {
        const { formValues } = this.props;
        if (
            nextProps.formValues &&
            formValues &&
            nextProps.formValues.is_best !== formValues.is_best
        ) {
            this.getTopScoredOptions(nextProps.formValues.is_best);
        } else {
            this.getTopScoredOptions();
        }
        // if (nextProps.isSaving === false) {
        //     this.setState({ activeStep: this.state.tempStep });
        // }
    }

    handleStep = step => () => {
        const { formValues, formErrors, translate, record } = this.props;
        let summaryValidation, topOptions, topOptionsValidation, modulesValidation, evaluationsValidation;
        
        if (step >= 0) {
            summaryValidation = validateSummaryFill(formValues, translate);

            if (summaryValidation.length !== 0) {
                this.props.showNotification(`${translate('validation.empty_fields')}: ${summaryValidation.join(', ')}`,
                    'warning',
                );
                return;
            }
        }
        if (step > 1) {
            topOptions = formValues.options_appraisals.filter(
                item => item.is_shortlisted,
            );
            modulesValidation = validateModulesFill(formValues, translate);
            if (modulesValidation.length !== 0) {
                this.props.showNotification(`${translate('validation.empty_fields')}: ${modulesValidation.join(', ')}`,
                    'warning',
                );
                return;
            }
        }
        if (step > 2) {
            topOptions = formValues.options_appraisals.filter(
                item => item.is_shortlisted,
            );

            evaluationsValidation = validateEvaluationsFill(formValues, translate);
            if (evaluationsValidation.length !== 0) {
                this.props.showNotification(`${translate('validation.empty_fields')}: ${evaluationsValidation.join(', ')}`,
                    'warning',
                );
                return;
            }
        }
        if (step === 3) {
            topOptionsValidation = validateTopOptions(formValues, translate);

            if (topOptionsValidation) {
                lodash.keys(topOptionsValidation).forEach((key) => {
                    if (topOptionsValidation[key].length > 1) {
                        const sameOptions = topOptionsValidation[key].map((option) => option.title).join(', ')
                        this.props.showNotification(`${sameOptions} has the same score`,
                            'warning',
                        );
                    }
                })
            }
        }

        // this.setState({ tempStep: step });
        this.props.save(formValues, false);
        this.props.onChangeStep(step);
    };

    renderSummaryOptionsInfo = () => {
        const { translate, record } = this.props;

        return (
            <div>
                <h4>{translate('resources.options_appraisals.fields.summary.title')}</h4>
                <Table bordered>
                    <thead>
                    <tr className="filled-row">
                            <th></th>
                            <th colSpan="3">{translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.summary')}</th>
                            <th colSpan="3">{translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.summary')}</th>
                        </tr>
                        <tr className="filled-row">
                            <th>{translate('resources.options_appraisals.fields.title')}</th>
                            <th>{translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.fnpv')}</th>
                            <th>{translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.irr')}</th>
                            <th>CEA</th>
                            <th>{translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.enpv')}</th>
                            <th>{translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.err')}</th>
                            <th>CEA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.formValues && this.props.formValues.options_appraisals &&
                            this.props.formValues.options_appraisals
                                .filter((option) => option.is_shortlisted)
                                .map((option, idx) =>
                                    <tr>
                                        <td>{option.title}</td>
                                        <td>{option.financial_evaluation && costSumFormatter(option.financial_evaluation.fnpv)}</td>
                                        <td>{option.financial_evaluation && option.financial_evaluation.irr || '-'}</td>
                                        <td style={{ textTransform: 'uppercase' }}>{option.financial_evaluation && option.financial_evaluation.appraisal_methodology}</td>
                                        <td>{option.economic_evaluation && costSumFormatter(option.economic_evaluation.enpv)}</td>
                                        <td>{option.economic_evaluation && option.economic_evaluation.err || '-'}</td>
                                        <td style={{ textTransform: 'uppercase' }}>{option.economic_evaluation && option.economic_evaluation.appraisal_methodology}</td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    renderStepContent = activeStep => {
        const { translate, record } = this.props;
        const optionsCount =
            this.props.formValues &&
            this.props.formValues.options_appraisals &&
            this.props.formValues.options_appraisals.length;

        switch (activeStep) {
            case 0:
                return (
                    <ArrayInput
                        source="options_appraisals"
                        label={null}
                        className="iterator"
                    >
                        <SimpleFormIterator
                            disableAdd={Number(record.phase_id) !== 3}
                            disableRemove={Number(record.phase_id) !== 3}
                        >
                            <FormDataConsumer>
                                {({
                                    getSource,
                                    scopedFormData,
                                    formData,
                                    ...rest
                                }) => {
                                    return (
                                        <Fragment>
                                            <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.title')}>
                                                <TextInput
                                                    source={getSource('title')}
                                                    label={translate(
                                                        'resources.options_appraisals.fields.title',
                                                    )}
                                                />
                                            </CustomInput>
                                            {this.props.record.phase_id ===
                                            4 ? null : (
                                                <Fragment>
                                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.description')}>
                                                        <Labeled label={translate(
                                                                'resources.options_appraisals.fields.description',
                                                            )} />

                                                        <CustomTextArea source={getSource(
                                                                'description',
                                                            )} {...this.props} />

                                                    </CustomInput>
                                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.score')}>
                                                        <DisabledInput
                                                            source={getSource(
                                                                'score',
                                                            )}
                                                            format={
                                                                this
                                                                    .getFormattedScore
                                                            }
                                                            label={translate(
                                                                'resources.options_appraisals.fields.score',
                                                            )}
                                                        />
                                                    </CustomInput>
                                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.cost')}>
                                                        <TextInput
                                                            source={getSource(
                                                                'cost',
                                                            )}
                                                            label={translate(
                                                                'resources.options_appraisals.fields.cost',
                                                            )}
                                                            validate={[number()]}
                                                            format={ commasFormatter }
                                                            parse={ commasParser }
                                                        />
                                                    </CustomInput>
                                                    <CustomInput
                                                        tooltipText={translate('tooltips.resources.options_appraisals.fields.funding_modality.title')}
                                                        fullWidth
                                                    >
                                                        <SelectInput
                                                            options={{
                                                                fullWidth:
                                                                    'true',
                                                            }}
                                                            label={translate(
                                                                `resources.options_appraisals.fields.funding_modality.title${
                                                                    this.props
                                                                        .record
                                                                        .phase_id >=
                                                                    3
                                                                        ? '_pfs'
                                                                        : ''
                                                                }`,
                                                            )}
                                                            source={getSource(
                                                                'funding_modality',
                                                            )}
                                                            choices={[
                                                                {
                                                                    id:
                                                                        'procurement',
                                                                    name: translate(
                                                                        'resources.options_appraisals.fields.funding_modality.procurement',
                                                                    ),
                                                                },
                                                                {
                                                                    id:
                                                                        'partnership',
                                                                    name: translate(
                                                                        'resources.options_appraisals.fields.funding_modality.partnership',
                                                                    ),
                                                                },
                                                            ]}
                                                        />
                                                    </CustomInput>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                );
            case 1:
                return (
                    <ArrayInput
                        source="options_appraisals"
                        label={null}
                        className="iterator"
                    >
                        <SimpleFormIterator
                            disableAdd={
                                activeStep !== 0 ||
                                Number(record.phase_id) !== 3
                            }
                            disableRemove={
                                activeStep > 1 ||
                                Number(record.phase_id) !== 3
                            }
                        >
                            <FormDataConsumer>
                                {({
                                    getSource,
                                    scopedFormData,
                                    formData,
                                    ...rest
                                }) => {
                                    return (
                                        <Fragment>
                                            <h4>
                                                <b>
                                                    {scopedFormData &&
                                                        scopedFormData.title}
                                                </b>
                                            </h4>
                                            <br />
                                            <BuildingBlocks
                                                getSource={getSource}
                                                record={this.props.record}
                                                formData={formData}
                                                scopedFormData={scopedFormData}
                                                {...this.props}
                                            />
                                        </Fragment>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                );
            case 2:
                return (
                    <ArrayInput
                        source="options_appraisals"
                        label={null}
                        className="iterator"
                    >
                        {/* <CustomFormIterator */}
                        <SimpleFormIterator
                            disableAdd={
                                activeStep !== 0 ||
                                Number(record.phase_id) !== 3
                            }
                            disableRemove={
                                activeStep > 1 ||
                                Number(record.phase_id) !== 3
                            }
                        >
                            <FormDataConsumer>
                                {({
                                    getSource,
                                    scopedFormData,
                                    formData,
                                    ...rest
                                }) => {
                                    return scopedFormData &&
                                        scopedFormData.is_shortlisted ? (
                                        <Fragment>
                                            <h4>
                                                <b>
                                                    {scopedFormData &&
                                                        scopedFormData.title}
                                                </b>
                                            </h4>
                                            <br />
                                            <AnalyticalModules
                                                getSource={getSource}
                                                record={this.props.record}
                                                formData={formData}
                                                scopedFormData={scopedFormData}
                                                {...this.props}
                                            />
                                        </Fragment>
                                    ) : null;
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                );
            case 3:
                const { formValues } = this.props;
                let bestOptionIndex = -1;

                if (formValues && typeof formValues.is_best !== 'undefined') {
                    bestOptionIndex = formValues && formValues.is_best;
                    if (
                        formValues &&
                        formValues.options_appraisals[bestOptionIndex]
                    ) {
                        formValues.options_appraisals[
                            bestOptionIndex
                        ].is_best = 1;
                    }
                }
                return (
                    <Fragment>
                        {
                            this.renderSummaryOptionsInfo()
                        }
                        <SelectInput
                            options={{ fullWidth: 'true' }}
                            label={translate(
                                'resources.options_appraisals.fields.is_best',
                            )}
                            source={'is_best'}
                            choices={this.getTopScoredOptions() || []}
                        />
                        {bestOptionIndex !== -1 && (
                            <Fragment>
                                <CustomInput
                                    tooltipText={translate('tooltips.resources.options_appraisals.fields.justification')}
                                    textArea
                                >
                                    <Labeled
                                        label={translate(
                                            'resources.options_appraisals.fields.justification',
                                        )}
                                    />
                                    <CustomTextArea  source={`options_appraisals[${bestOptionIndex}].justification`} {...this.props} />
                                </CustomInput>
                                <CustomInput
                                    tooltipText={translate('tooltips.resources.options_appraisals.fields.modality_justification')}
                                    textArea
                                >
                                    <Labeled
                                        label={translate(
                                            `resources.options_appraisals.fields.modality_justification${
                                                this.props.record.phase_id >= 3
                                                    ? '_pfs'
                                                    : ''
                                            }`,
                                        )}
                                    />
                                    <CustomTextArea  source={`options_appraisals[${bestOptionIndex}].modality_justification`} {...this.props} />
                                </CustomInput>
                            </Fragment>
                        )}
                    </Fragment>
                );
            default:
                return null;
        }
    };

    getSteps() {
        const { translate } = this.props;

        return [
            translate(
                'resources.options_appraisals.fields.stepper.description',
            ),
            translate(
                'resources.options_appraisals.fields.stepper.building_blocks',
            ),
            translate(
                'resources.options_appraisals.fields.stepper.analytical_modules',
            ),
            translate(
                'resources.options_appraisals.fields.stepper.best_option',
            ),
        ];
    }

    renderSingleform() {
        return (
            <ArrayInput
                source="options_appraisals"
                label={null}
                className="iterator"
            >
                <SimpleFormIterator disableAdd disableRemove>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData, formData, ...rest }) => {
                            return (
                                <Fragment>
                                    <h5>
                                        <b>
                                            {scopedFormData &&
                                                scopedFormData.title}
                                        </b>
                                    </h5>

                                    <BuildingBlocks
                                        getSource={getSource}
                                        record={this.props.record}
                                        formData={formData}
                                        scopedFormData={scopedFormData}
                                        disabled={formData && formData.phase_id > 4}
                                        {...this.props}
                                    />
                                    <AnalyticalModules
                                        getSource={getSource}
                                        record={this.props.record}
                                        formData={formData}
                                        scopedFormData={scopedFormData}
                                        disabled={formData && formData.phase_id > 4}
                                        {...this.props}
                                    />
                                </Fragment>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        );
    }

    renderStepper() {
        const { translate, record } = this.props;
        const { optionsStep } = this.props;
        const steps = this.getSteps();

        return (
            <div style={{ position: 'relative', minHeight: '275px' }}>
                <h4>{translate('resources.options_appraisals.name')}</h4>
                <div className="row" style={{ position: 'relative' }}>
                    <div className="col-sm-3">
                        <Stepper
                            orientation="vertical"
                            activeStep={optionsStep}
                            style={{ position: 'fixed' }}
                        >
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};
                                if (
                                    this.props.isSaving &&
                                    this.state.tempStep === index
                                ) {
                                    labelProps.icon = (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    );
                                }
                                return (
                                    <Step
                                        key={label}
                                        {...props}
                                        onClick={this.handleStep(index)}
                                    >
                                        <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className="col-sm-9">
                        {this.renderStepContent(optionsStep)}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { translate, record } = this.props;

        if (record.phase_id >= 4) {
            return this.renderSingleform();
        }
        return this.renderStepper();
    }
}

const mapStateToProps = state => ({
    formErrors: getFormSyncErrors('record-form')(state),
    formValues: getFormValues('record-form')(state),
    isSaving: state.admin.saving,
});
const mapDispatchToProps = {
    showNotification,
    updateSyncErrors,
};
export default translate(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(OptionsAppraisalForm),
);
