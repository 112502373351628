import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    SimpleList,
    TextField,
    TextInput,
    number
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';

import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';

export const OrganizationCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText="code">
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText="name">
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const OrganizationEdit = props => (
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <CustomInput tooltipText="code">
                <TextInput source="code" validate={[required(), number()]} />
            </CustomInput>
            <CustomInput tooltipText="name">
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const OrganizationShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const OrganizationShow = ({ translate, ...props }) => {
    return (
        <Card>
             <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>{translate('resources.organizations.name', { smart_count: 2 })}</strong>
            </CardHeader>
            <Show actions={<OrganizationShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="code" />
                    <TextField source="name" />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

export const OrganizationList = props => {
    return (
        <List {...props} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="name" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default OrganizationList;
