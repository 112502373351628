import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
    downloadCSV,
} from 'react-admin';
import lodash from 'lodash';

export const exporter = (translate) => (records, fetchRelatedRecords) => {
    const exportedRecords = lodash.cloneDeep(records);
    const data = exportedRecords.map((item) => {
        item.department = item.department ? `${item.department.code} - ${item.department.name}` : item.department;
        item.sector = item.sector ? `${item.sector.code} - ${item.sector.name}`: item.sector
        item.vote = item.vote ? `${item.vote.code} - ${item.vote.name}` : item.vote;
        item.donor = item.donor ? `${item.donor.code} - ${item.donor.name}` : item.donor;
        item.sectors = item.sectors && item.sectors.length > 0 
            ? item.sectors.map((item) => `${item.code} - ${item.name}`)
            : item.sectors;  
        item.role = translate(`resources.roles.${item.role}`)
        
        return item;
    })
    
    const csv = convertToCSV({
        data,
        fields: ['id', 'created_at', 'updated_at', 'username', 'fullname', 'role',
            'email', 'phone', 'department', 'sector', 'sectors', 'vote', 'donor'],
    });

    downloadCSV(csv, 'users');
};