import React, { Fragment } from 'react';
import {
    FormDataConsumer,
    TextInput,
    translate,
    email,
    number,
    maxLength,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';

import CustomInput from '../../../components/CustomInput';

const ResponsibleOfficerForm = (props) => {
    return (
        <ArrayInput
            source="responsible_officers"
            label={props.translate(
                'resources.project-details.fields.responsible_officers.header',
            )}
        >
            <SimpleFormIterator style={{width: "100%" }}>
                <FormDataConsumer>
                    {({ getSource, scopedFormData, formData, ...rest }) => {
                        return (
                            <Fragment>
                                <CustomInput
                                    tooltipText={props.translate(
                                        'tooltips.resources.project-details.fields.responsible_officers.title',
                                    )}
                                    fullWidth
                                >
                                    <TextInput
                                        source={getSource("title")}
                                        label={props.translate(
                                            'resources.project-details.fields.responsible_officers.title',
                                        )}
                                    />
                                </CustomInput>
                                <CustomInput
                                    tooltipText={props.translate(
                                        'tooltips.resources.project-details.fields.responsible_officers.name',
                                    )}
                                >
                                    <TextInput
                                        source={getSource("name")}
                                        label={props.translate(
                                            'resources.project-details.fields.responsible_officers.name',
                                        )}
                                    />
                                </CustomInput>
                                <CustomInput
                                    tooltipText={props.translate(
                                        'tooltips.resources.project-details.fields.responsible_officers.phone',
                                    )}
                                >
                                    <TextInput
                                        source={getSource("phone")}
                                        validate={[number(), maxLength(13)]}
                                        label={props.translate(
                                            'resources.project-details.fields.responsible_officers.phone',
                                        )}
                                    />
                                </CustomInput>
                                <CustomInput
                                    tooltipText={props.translate(
                                        'tooltips.resources.project-details.fields.responsible_officers.mobile_phone',
                                    )}
                                >
                                    <TextInput
                                        source={getSource("mobile_phone")}
                                        validate={[number(), maxLength(13)]}
                                        label={props.translate(
                                            'resources.project-details.fields.responsible_officers.mobile_phone',
                                        )}
                                    />
                                </CustomInput>
                                <CustomInput
                                    tooltipText={props.translate(
                                        'tooltips.resources.project-details.fields.responsible_officers.email',
                                    )}
                                >
                                    <TextInput
                                        source={getSource("email")}
                                        label={props.translate(
                                            'resources.project-details.fields.responsible_officers.email',
                                        )}
                                        validate={email()}
                                    />
                                </CustomInput>
                            </Fragment>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default translate(ResponsibleOfficerForm);
