import React, { Component, Suspense } from 'react';
import { AppHeader } from '@coreui/react';
import { Responsive, userLogout } from 'react-admin';
import { connect } from 'react-redux';

const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class AdminAppBar extends Component {
    loading = () => (
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );

    signOut = () => {
        this.props.logout();
    }

    render() {
        return (
            <AppHeader fixed>
                <Suspense fallback={this.loading()}>
                    <DefaultHeader onLogout={e => this.signOut(e)} />
                </Suspense>
            </AppHeader>
        );
    }
}

export default connect(undefined, { logout: userLogout })(AdminAppBar);
