import { ACTION_TYPES, USER_ROLES } from '../../../constants/common';
import React, {Component, Fragment} from 'react';
import { change, isSubmitting, submit } from 'redux-form';
import { showNotification, translate } from 'react-admin';

import CustomActionButton from './CustomActionButton';
import SubmitButton from './SubmitButton';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import lodash from 'lodash';
import { push } from 'react-router-redux';

class WorkflowActions extends Component {
    state = {
        data: {
            workflow: {
                actions: []
            }
        },
        isFetching: false,
        isSubmitted: false
    }

    componentDidMount() {
        this.setState({ data: this.props.record })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.record })
    }

    handleRefresh = (requestParams) => {
        this.setState({ isFetching: true });

        dataProvider('UPDATE', 'projects', requestParams)
            .then(response => {
                this.setState({ isSubmitted: true });

                this.props.showNotification(
                    this.props.translate('workflow.messages.status_change'),
                );
                if (response && response.data) {
                    window.location.href = `#/projects/${response.data.id}/show/${response.data.phase_id}`;
                    if (response.data.phase_id !== this.state.data.phase_id || 
                        response.data.current_step !== this.state.data.current_step ||
                        response.data.status !== this.state.data.status) {
                            window.location.reload();
                    }
                }
            })
            .catch(err => {
                this.props.showNotification(
                    this.props.translate('workflow.messages.status_not_change'),
                    'warning',
                );
                this.setState({ isFetching: false });
            });
    }

    renderButtons() {
        const { data, isFetching, isSubmitted } = this.state;
        const { actions } = data.workflow;

        return actions.map((itemAction) =>            
            <CustomActionButton 
                action={itemAction} 
                data={this.props.record}
                onRefresh={this.handleRefresh}
                currentStep={data.workflow.step}
                isFetching={isFetching}
                isSubmitted={isSubmitted}
                filesValidation={itemAction === ACTION_TYPES.approve && data.phase_id === 1 && (data.workflow.source_role === USER_ROLES.PSU || 
                    data.workflow.source_role === USER_ROLES.VAU)}
                {...this.props}
            />
        )
    }

    render(){
        const { data } = this.state;
        const { actions } = data.workflow;

        return actions && actions.length !== 0
            ? this.renderButtons()
            : null
    }
}
const mapStateToProps = state => ({
    isLoading: state.admin.loading
});
const mapDispatchToProps = {
    push,
    showNotification,
    change,
    submit
};

export default translate(connect(mapStateToProps, mapDispatchToProps)(WorkflowActions))