import {
    ACTION_TYPES,
    API_URL,
    PROJECT_STATUS,
    USER_ROLES,
} from '../../../constants/common';
import {
    Button,
    CREATE,
    LongTextInput,
    REDUX_FORM_NAME,
    SaveButton,
    SimpleForm,
    TextInput,
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    translate,
} from 'react-admin';
// in src/comments/ApproveButton.js
import React, { Component, Fragment } from 'react';
import { change, isSubmitting, submit } from 'redux-form';

import ArrowForward from '@material-ui/icons/ArrowForward';
import Assignment from '@material-ui/icons/Assignment';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomFileLoader from '../../../components/customFileLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Refresh from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import lodash from 'lodash';
import { validateFieldsByPhase } from '../validation';
import { validateOptionsBeforeSave } from '../helpers';

class CustomActionButton extends Component {
    state = {
        isFetching: false,
        showDialog: false,
        targetUser: null,
        decision: '',
        getValidation: false,
        fileUploaded: false,
        additionalFileUploaded: false,
        fileSelected: false,
        additionalFileSelected: false,
        showConfirmationPopup: false,
    };

    componentDidMount() {
        if (this.props.data) {
            dataProvider('GET_ONE', 'project-details', {
                id: this.props.data.project_detail_id,
            }).then((response) => {
                this.setState({ data: response.data });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { fileUploaded, additionalFileUploaded, showDialog } = this.state;
        const {
            action,
            data,
            isFetching,
            isSubmitted,
            filesValidation,
        } = this.props;

        if (filesValidation) {
            if (fileUploaded && additionalFileUploaded) {
                if (!isFetching && !isSubmitted && showDialog) {
                    this.handleWorkFlowStep(action, data);
                }
            }
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.data) {
            dataProvider('GET_ONE', 'project-details', {
                id: nextProps.data.project_detail_id,
            }).then((response) => {
                this.setState({ data: response.data });
            });
        }
    }

    getRequiredFilesForPhase = (file_types, phase_id) =>
        file_types
            .filter(
                (item) =>
                    item.phase_ids.includes(phase_id) &&
                    item.is_required === 'yes',
            )
            .map((item) => item.id);

    validateFiles = (record) => {
        const { data } = this.state;
        const fileValidationError = [];

        if (lodash.isEmpty(data)) {
            return fileValidationError;
        }

        const requiredFiles = this.getRequiredFilesForPhase(
            data.file_types,
            data.phase_id,
        );

        if (requiredFiles && requiredFiles.length !== 0) {
            if (!data.files || data.files.length === 0) {
                fileValidationError.push(
                    this.props.translate('messages.no_files'),
                );
                return fileValidationError;
            }
        }

        const files = data.files.map((item) => item.file_type_id);
        const result = requiredFiles.filter((item) => !files.includes(item));
        const notLoadedFiles =
            result.length !== 0 &&
            result
                .map((id) =>
                    data.file_types
                        .filter((item) => item.id === id)
                        .map((item) => item.name),
                )
                .join(', ');

        if (result.length !== 0) {
            fileValidationError.push(
                `${this.props.translate(
                    'messages.no_file_type',
                )} "${notLoadedFiles}" - ${this.props.translate(
                    'messages.no_file_action',
                )}`,
            );
        }

        return fileValidationError;
    };

    validateProjectDetails = () => {
        const { data } = this.state;
        const detailsValidationError = [];

        if (lodash.isEmpty(data)) {
            return detailsValidationError;
        }

        if (data.outcomes && data.outcomes.length > 2) {
            detailsValidationError.push(
                this.props.translate('messages.max_outcomes'),
            );
        }

        if (
            data.outputs &&
            data.outputs.filter((item) => item.outcome_ids.length > 2)
                .length !== 0
        ) {
            detailsValidationError.push(
                this.props.translate('messages.max_outcomes_per_output'),
            );
        }

        if (data.phase_id >= 2) {
            if (
                data.outputs &&
                data.outputs.filter((item) => item.activities.length === 0)
                    .length !== 0
            ) {
                detailsValidationError.push(
                    this.props.translate('messages.min_activity_per_output'),
                );
            }
        }

        if (data.phase_id === 3) {
            if (
                data.options_appraisals &&
                data.options_appraisals.filter((item) => item.is_shortlisted)
                    .length > 3
            ) {
                detailsValidationError.push(
                    this.props.translate('messages.max_shortlisted_options'),
                );
            }
            if (
                data.options_appraisals &&
                data.options_appraisals.filter((item) => item.is_shortlisted)
                    .length === 0
            ) {
                detailsValidationError.push(
                    this.props.translate('messages.min_selected_option'),
                );
            }
            if (
                data.options_appraisals &&
                data.options_appraisals.filter((item) => item.is_best).length >
                    1
            ) {
                detailsValidationError.push(
                    this.props.translate('messages.max_best_option'),
                );
            }
            if (
                data.options_appraisals &&
                data.options_appraisals.filter((item) => item.is_best)
                    .length === 0
            ) {
                detailsValidationError.push(
                    this.props.translate('messages.min_best_option'),
                );
            }
        }

        return detailsValidationError;
    };

    getValidationMessages = () => {
        const { data } = this.state;
        const { translate } = this.props;
        const emptyFields = validateFieldsByPhase(data);

        if (emptyFields && emptyFields.length !== 0) {
            let resultString = '';
            const result = lodash.groupBy(emptyFields, (item) => item.step);

            for (const key in result) {
                if (result.hasOwnProperty(key)) {
                    const element = result[key];
                    resultString += `${
                        key || translate('validation.empty_fields')
                    } - ${element
                        .map((item) => `${translate(item.translation)}`)
                        .join(', ')}; `;
                }
            }

            return `${translate('validation.empty_fields')}:  ${resultString}`;
        }

        return [];
    };

    handleShowDialog = () => {
        this.setState({ showDialog: true });
    };

    handleHideDialog = () => {
        this.setState({ showDialog: false });
    };

    handleShowConfirmDialog = () => {
        this.setState({ showConfirmationPopup: true });
    };

    handleHideConfirmDialog = () => {
        this.setState({ showConfirmationPopup: false });
    };

    handleWorkFlowStep = (action, params) => {
        const requestParams = {
            data: {
                action: action,
                reason: this.state.decision,
            },
            id: params.id,
        };

        if (action === ACTION_TYPES.assign) {
            requestParams.data.target_user = this.state.targetUser;
        }

        this.setState({ showDialog: false }, () => {
            this.props.onRefresh(requestParams);
        });
    };

    handleSubmitAction = () => {
        const {
            filesValidation,
            record,
            showNotification,
            action,
            data,
        } = this.props;

        if (!filesValidation) {
            if (record && record.status !== PROJECT_STATUS.STATUS_DRAFT) {
                this.setState({ isFetching: true });
                this.handleWorkFlowStep(action, data);
            } else {
                if (this.getSubmissionValidation()) {
                    this.setState({ isFetching: true });
                    this.handleWorkFlowStep(action, data);
                }
            }
        } else {
            this.setState({ isFetching: true });
        }
    };

    renderFileCheckMessage = () => (
        <p style={{ fontStyle: 'italic' }}>
            {' '}
            {this.props.translate('workflow.workflow_file_upload')}{' '}
        </p>
    );

    renderIcon() {
        switch (this.props.action) {
            case ACTION_TYPES.submit:
                return this.getSubmissionValidation() ? (
                    <ArrowForward />
                ) : (
                    <Cancel />
                );
            case ACTION_TYPES.approve:
                return <Check />;
            case ACTION_TYPES.reject:
                return <Cancel />;
            case ACTION_TYPES.revise:
                return <Refresh />;
            case ACTION_TYPES.assign:
                return <Assignment />;
            default:
                return null;
        }
    }

    getSubmissionValidation = () => {
        const {
            push,
            record,
            showNotification,
            action,
            data,
            translate,
        } = this.props;
        let optionsErrors = [],
            result = [],
            optionsErrorsMsg;
        const filesError = this.validateFiles(record);
        const detailsError = this.validateProjectDetails(record);
        const fieldsError = this.getValidationMessages(record);

        if (!record) {
            return false;
        }

        if (record.phase_id >= 3) {
            optionsErrorsMsg = validateOptionsBeforeSave(
                this.state.data,
                showNotification,
                translate,
            );
            if (lodash.isString(optionsErrorsMsg)) {
                optionsErrors.push(optionsErrorsMsg);
            }
        }

        result = lodash.concat(
            filesError,
            detailsError,
            fieldsError,
            optionsErrors,
        );

        return result.length === 0;
    };

    handleSubmitActionClick = () => {
        const {
            push,
            record,
            showNotification,
            action,
            data,
            translate,
        } = this.props;
        let optionsErrors = [],
            result = [],
            optionsErrorsMsg;
        const filesError = this.validateFiles(record);
        const detailsError = this.validateProjectDetails(record);
        const fieldsError = this.getValidationMessages(record);

        if (record.phase_id >= 3) {
            optionsErrorsMsg = validateOptionsBeforeSave(
                this.state.data,
                showNotification,
                translate,
            );
            if (lodash.isString(optionsErrorsMsg)) {
                optionsErrors.push(optionsErrorsMsg);
            }
        }

        result = lodash.concat(
            filesError,
            detailsError,
            fieldsError,
            optionsErrors,
        );

        if (result.length === 0) {
            this.setState({ showDialog: true });
            return;
        }

        result.forEach((msg) => {
            if (msg) {
                showNotification(msg, 'warning');
            }
        });
    };

    renderButton() {
        const popupsActions = [
            ACTION_TYPES.submit,
            ACTION_TYPES.approve,
            ACTION_TYPES.assign,
            ACTION_TYPES.reject,
            ACTION_TYPES.revise,
        ];
        let actionClick = popupsActions.includes(this.props.action)
            ? this.handleShowDialog
            : this.handleSubmitAction;

        if (
            this.props.action === ACTION_TYPES.submit &&
            this.props.currentStep === 1
        ) {
            actionClick = this.handleSubmitActionClick;
        }
        const buttonStyle = {
            color:
                this.props.action === ACTION_TYPES.submit
                    ? this.getSubmissionValidation()
                        ? 'green'
                        : 'red'
                    : '',
        };

        const icon = this.renderIcon();
        const text = this.state.isFetching ? (
            <Fragment>
                <CircularProgress size={25} thickness={2} />
                <span style={{ marginLeft: '10px' }}>
                    {this.props.translate(`buttons.${this.props.action}`)}
                </span>
            </Fragment>
        ) : (
            this.props.translate(`buttons.${this.props.action}`)
        );

        return (
            <Button
                style={buttonStyle}
                label={text}
                onClick={actionClick}
                disabled={this.props.isFetching}
            >
                {icon}
            </Button>
        );
    }

    handleSelectUser = (event) => {
        this.setState({ targetUser: event.target.value });
    };

    handleSelectFile = (event) => {
        this.setState({ selectedFile: event.target.value });
    };

    handleChangeDecision = (event) => {
        this.setState({ decision: event.target.value });
    };

    renderHelpMessage = (action) => {
        switch (action) {
            case ACTION_TYPES.submit:
                return `Submit means you are submitting the project for approval. You will not be able to take any action on the project after the submission. Please confirm! (Standard User and PAP Standard User, PAP Principal)`;
            case ACTION_TYPES.revise:
                return `Revise means you are returning the project for revision. Are you sure you want to revise?!  (Department Head, Planning Head, Accounting Officer, Sector Head, Commissioner PAP, PAP Principal)`;
            case ACTION_TYPES.approve:
                return `Approve means forwarding the project to the next in tier.  Are you sure you want to approve? (Department Head, Planning Head, Accounting Officer, Sector Head, Commissioner PAP, PAP Principal)`;
            case ACTION_TYPES.reject:
                return `This action means that the project will be deleted from the system and archived. No further action will be undertaken on it once rejected. Are you sure you want to reject the project? (Department Head, Planning Head, Accounting Officer, Sector Head, Commissioner PAP.)`;
            default:
                return null;
        }
    };

    renderDialogContent = (action) => {
        const { workflow } = this.props.data;
        let title =
            action === ACTION_TYPES.assign
                ? this.props.translate('workflow.assign')
                : this.props.translate('workflow.decision');

        if (
            this.props.action === ACTION_TYPES.submit &&
            this.props.currentStep === 1
        ) {
            title = this.props.translate('workflow.comments');
        }

        const content =
            action === ACTION_TYPES.assign ? (
                <Select
                    autoWidth={true}
                    value={this.state.targetUser}
                    onChange={this.handleSelectUser}
                    style={{ width: '100%' }}
                    label={this.props.translate('workflow.assign_user')}
                >
                    {workflow.target_users.map((user) => (
                        <MenuItem
                            value={user.id}
                        >{`${user.fullname} (${user.email})`}</MenuItem>
                    ))}
                </Select>
            ) : null;

        return (
            <Fragment>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div>
                        {content}
                        <textarea
                            aria-invalid="false"
                            type="text"
                            value={this.state.decision}
                            onChange={this.handleChangeDecision}
                            style={{
                                width: '100%',
                                height: '70px',
                                marginTop: '15px',
                            }}
                        />
                        <br />
                        {this.renderPrimaryFileLoader()}
                        {this.props.filesValidation &&
                            this.renderAdditionalFileLoader()}
                    </div>
                </DialogContent>
            </Fragment>
        );
    };

    renderPrimaryFileLoader = () => {
        const { workflow } = this.props.data;

        const entityType =
            workflow && workflow.source_role === USER_ROLES.VAU
                ? 'project_detail'
                : 'timeline';
        const fileTypeId =
            workflow && workflow.source_role === USER_ROLES.VAU
                ? this.props.record && this.props.record.project_detail_id
                : 0;
        const fileSubtitle = !this.props.filesValidation
            ? null
            : workflow && workflow.source_role === USER_ROLES.PSU
            ? this.props.translate('workflow.workflow_file_upload')
            : workflow.source_role === USER_ROLES.VAU
            ? this.props.translate('workflow.va_file_subtitle')
            : null;

        return (
            <Fragment>
                <CustomFileLoader
                    entityType={entityType}
                    fileTypeId={fileTypeId}
                    formData={this.props.data}
                    entityId={
                        this.props.record && this.props.record.project_detail_id
                    }
                    relatedField={workflow && String(workflow.step)}
                    onFileUpload={this.handleUploadFile}
                    onFileSelect={this.handleSelectFile}
                    isSubmitted={this.state.isFetching}
                    filesValidation={this.props.filesValidation}
                />
                <p style={{ fontStyle: 'italic' }}>{fileSubtitle}</p>
            </Fragment>
        );
    };

    renderAdditionalFileLoader = () => {
        const { workflow } = this.props.data;
        const relatedField = (workflow && String(workflow.step) + '_1') || 0;
        const entityType =
            workflow && workflow.source_role === USER_ROLES.VAU
                ? 'project_detail'
                : 'timeline';
        const fileTypeId =
            workflow && workflow.source_role === USER_ROLES.VAU
                ? this.props.record && this.props.record.project_detail_id
                : 0;
        const fileSubtitle = !this.props.filesValidation
            ? null
            : workflow && workflow.source_role === USER_ROLES.PSU
            ? this.props.translate('workflow.workflow_additional_file_upload')
            : workflow.source_role === USER_ROLES.VAU
            ? this.props.translate('workflow.va_additionalFile_subtitle')
            : null;

        return (
            <Fragment>
                <CustomFileLoader
                    entityType={entityType}
                    fileTypeId={fileTypeId}
                    formData={this.props.data}
                    entityId={
                        this.props.record && this.props.record.project_detail_id
                    }
                    relatedField={relatedField}
                    isSubmitted={this.state.isFetching}
                    onFileSelect={this.handleSelectAdditionalFile}
                    onFileUpload={this.handleUploadAdditionalFile}
                    filesValidation={this.props.filesValidation}
                />
                <p style={{ fontStyle: 'italic' }}>{fileSubtitle}</p>
            </Fragment>
        );
    };

    handleSelectFile = () => {
        this.setState({ fileSelected: true });
    };

    handleSelectAdditionalFile = () => {
        this.setState({ additionalFileSelected: true });
    };

    handleUploadFile = () => {
        this.setState({ fileUploaded: true });
    };

    handleUploadAdditionalFile = () => {
        this.setState({ additionalFileUploaded: true });
    };

    checkSaveButton = () => {
        const { record, action, filesValidation } = this.props;
        const { isFetching, fileSelected, additionalFileSelected } = this.state;

        if (isFetching) {
            return true;
        }

        if (
            filesValidation &&
            record &&
            record.workflow &&
            record.workflow.source_role === USER_ROLES.PSU &&
            (!fileSelected || !additionalFileSelected)
        ) {
            return true;
        }

        if (
            filesValidation &&
            record &&
            record.workflow &&
            record.workflow.source_role === USER_ROLES.VAU &&
            (!fileSelected || !additionalFileSelected)
        ) {
            return true;
        }

        if (action === ACTION_TYPES.assign && !this.state.targetUser) {
            return true;
        }

        return false;
    };

    render() {
        const { push, record, showNotification, action, config } = this.props;
        const { showDialog, isFetching, fileUploaded } = this.state;
        return (
            <Fragment>
                {this.renderButton()}
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleHideDialog}
                    aria-label={this.props.translate('workflow.assign')}
                >
                    {this.renderDialogContent(action)}
                    <DialogActions>
                        <Fragment>
                            <SaveButton
                                disabled={this.checkSaveButton()}
                                saving={isFetching}
                                onClick={this.handleShowConfirmDialog}
                            />
                            <Dialog
                                open={this.state.showConfirmationPopup}
                                onClose={this.handleHideConfirmDialog}
                                aria-label={this.props.translate(
                                    'messages.project_reject_confirmation',
                                )}
                            >
                                <DialogTitle>
                                    {this.renderHelpMessage(action)}
                                </DialogTitle>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleSubmitAction}
                                        label="ra.action.confirm"
                                        key="button"
                                    >
                                        <Check />
                                    </Button>
                                    <Button
                                        label="ra.action.cancel"
                                        onClick={this.handleHideConfirmDialog}
                                    >
                                        <Cancel />
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

CustomActionButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isSubmitting: isSubmitting('post-quick-create')(state),
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
};

export default translate(
    connect(mapStateToProps, mapDispatchToProps)(CustomActionButton),
);
