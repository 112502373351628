import React, { Fragment } from 'react';
import {
    ArrayInput,
    FormDataConsumer,
    SimpleFormIterator,
    Labeled,
    TextInput,
    SimpleForm,
    translate,
} from 'react-admin';

import ManageIndicatorsButton from './ManageIndicatorsButton';
import CustomInput from '../../../../components/CustomInput';
import { IndicatorList } from '../../ProjectDetails';

export const validation = (values) => {
    return values && values.outcomes && values.outcomes.length >= 2;
};

const OutcomesForm = (props) => {
    function handleShowIndicators(visible) {
        props.onShowPopup('outcomeIndicators', visible);
    }

    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <Fragment>
                        <ArrayInput source="outcomes" label={false}>
                            <SimpleFormIterator
                                disableAdd={validation(formData)}
                            >
                                <FormDataConsumer>
                                    {({
                                        getSource,
                                        scopedFormData,
                                        formData,
                                        ...rest
                                    }) => (
                                        <Fragment>
                                            <div style={{ minWidth: '500px' }}>
                                                <div>
                                                    <CustomInput
                                                        tooltipText={props.translate(
                                                            'tooltips.resources.outcomes.fields.title',
                                                        )}
                                                        fullWidth
                                                    >
                                                        <TextInput
                                                            source={getSource(
                                                                'title',
                                                            )}
                                                            label={props.translate(
                                                                'resources.outcomes.fields.title',
                                                            )}
                                                        />
                                                    </CustomInput>
                                                </div>
                                                <br />
                                                {formData.phase_id ===
                                                1 ? null : (
                                                    <div>
                                                        {scopedFormData && (
                                                            <ManageIndicatorsButton
                                                                onShow={
                                                                    handleShowIndicators
                                                                }
                                                                show={
                                                                    props.popups
                                                                        .outcomeIndicators
                                                                }
                                                                source={getSource(
                                                                    'indicators',
                                                                )}
                                                                title={props.translate(
                                                                    'resources.outputs.actions.edit',
                                                                )}
                                                                targets={
                                                                    scopedFormData.targets
                                                                }
                                                                {...props}
                                                            />
                                                        )}
                                                        {scopedFormData && (
                                                            <IndicatorList
                                                                indicators={
                                                                    scopedFormData.indicators
                                                                }
                                                                baseline={
                                                                    scopedFormData.baseline
                                                                }
                                                                targets={
                                                                    scopedFormData.targets
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <br />
                                        </Fragment>
                                    )}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(OutcomesForm);
