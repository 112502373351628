import React, {Component} from 'react';
import Timeline from './Timeline';

class CustomTimeline extends Component {
  render() {
    if (!this.props.events || this.props.events.length === 0){
        return <h3>{this.props.translate('timeline.no_data')}</h3>
    }
    return <Timeline events={this.props.events || []} translate={this.props.translate}/>;
  }
}

export default CustomTimeline;
