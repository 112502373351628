import './styles.scss';

import {
    ArrayInput,
    BooleanInput,
    FileField,
    FileInput,
    FormDataConsumer,
    Labeled,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    maxValue,
    minValue,
    number,
    required,
    translate
} from 'react-admin';
import React, { Component, Fragment }  from 'react';
import { commasFormatter, commasParser } from '../../helpers';

import CustomInput from '../../../../components/CustomInput';
import CustomTextArea from '../../../../components/customTextArea';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

const validateStock = [number()];

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

class Alternatives extends Component {
    state = {
        activeTab: 0,
    }

    option_modules = [
        {
            name: this.props.translate('resources.options_appraisals.fields.analytical_modules.modules.financial'),
            id: 'financial'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.analytical_modules.modules.economic'),
            id: 'economic'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.analytical_modules.modules.distributional'),
            id: 'distributional'
        },
        {
            name: this.props.translate('resources.options_appraisals.fields.analytical_modules.modules.risk'),
            id: 'risk'
        },
    ]

    handleChange = (event, newValue) => {
        this.setState({ activeTab: newValue })
        // this.props.save(this.props.formValues , false);
    }



    renderCriterias = (module) => {
        const { getSource, translate } = this.props;

        return (
            <ArrayInput 
                source={getSource(`${module}.criterias`)} 
                label={translate('resources.options_appraisals.fields.analytical_modules.criterias.title')}
            >
                <SimpleFormIterator disableAdd={this.props.disabled} disableRemove={this.props.disabled}>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData, formData, ...rest }) => {
                            return (
                                <div>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.criterias.criteria_title')} fullWidth>
                                        <TextInput 
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                            source={getSource("title")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.criterias.criteria_title')} 
                                        />
                                    </CustomInput>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.criterias.criteria_value')} fullWidth>
                                        <TextInput 
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                            source={getSource("criteria_value")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.criterias.criteria_value')} 
                                        />
                                    </CustomInput>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.criterias.measure_unit')} fullWidth>
                                        <TextInput 
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                            source={getSource("measure_unit")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.criterias.measure_unit')} 
                                        />
                                    </CustomInput>
                                </div>
                            )}}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        )
    }

    renderStakeholders = () => {
        const { getSource, translate } = this.props;
        
        return (
            <Fragment>
                <ArrayInput 
                    source={getSource(`stakeholder_evaluation`)} 
                    label={false}
                >
                    <SimpleFormIterator disableAdd={this.props.disabled} disableRemove={this.props.disabled}>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData, formData, ...rest }) => {
                                return (
                                    <div>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.title')} fullWidth>
                                            <TextInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                source={getSource("title")} 
                                                label={translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.title')} 
                                            />
                                        </CustomInput>
                                        <div>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.impact_sign.title')} fullWidth>
                                            <SelectInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                className="boolean-selector"
                                                label={translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.impact_sign.title')} 
                                                source={getSource("impact_sign")}   
                                                choices={[
                                                    { id: 'positive', name: translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.impact_sign.positive') },
                                                    { id: 'negative', name: translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.impact_sign.negative') },
                                                ]} 
                                            />
                                        </CustomInput>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.beneficiary.title')} fullWidth>
                                            <SelectInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                className="boolean-selector"
                                                label={translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.beneficiary.title')} 
                                                label={"Relation"} 
                                                source={getSource("beneficiary")}   
                                                choices={[
                                                    { id: 'direct', name: translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.beneficiary.direct') },
                                                    { id: 'indirect', name: translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.beneficiary.indirect') },
                                                ]} 
                                            />
                                        </CustomInput>
                                        </div>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.description')} fullWidth>
                                            <Labeled label={translate('resources.options_appraisals.fields.analytical_modules.stakeholder_evaluation.description')} />
                                            <CustomTextArea source={getSource("description")} {...this.props} />
                                        </CustomInput>
                                    </div>
                                )}}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Fragment>
        )
    }

    renderFinancialEvaluation = () => {
        const { getSource, scopedFormData, formData, translate } = this.props;

        if (scopedFormData && scopedFormData.financial_evaluation && 
            scopedFormData.financial_evaluation.appraisal_methodology &&
            scopedFormData.financial_evaluation.appraisal_methodology === 'cea'){
                scopedFormData.financial_evaluation.fnpv = "0";
                scopedFormData.financial_evaluation.irr = "0";
        }
        if (scopedFormData && scopedFormData.financial_evaluation && 
            scopedFormData.financial_evaluation.appraisal_methodology &&
            scopedFormData.financial_evaluation.appraisal_methodology === 'cba'){
                scopedFormData.financial_evaluation.criterias = [];
        }

        return (
            <Fragment>
                <div>
                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.financial_evaluation.appraisal_methodology')} fullWidth>
                        <SelectInput 
                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                            className="boolean-selector"
                            label={translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.title')} 
                            source={getSource("financial_evaluation.appraisal_methodology")}  
                            choices={[
                                { id: 'cba', name: translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.cba') },
                                { id: 'cea', name: translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.cea') },
                            ]} />
                    </CustomInput>
                    {
                        scopedFormData && scopedFormData.financial_evaluation && 
                        scopedFormData.financial_evaluation.appraisal_methodology &&
                        scopedFormData.financial_evaluation.appraisal_methodology === 'cba'
                            ?   <Fragment>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.financial_evaluation.fnpv')} fullWidth>
                                        <TextInput 
                                            source={getSource("financial_evaluation.fnpv")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.fnpv')} 
                                            validate={number()}
                                            format={ commasFormatter }
                                            parse={ commasParser }
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                        />
                                    </CustomInput>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.financial_evaluation.irr')} fullWidth>
                                        <TextInput 
                                            source={getSource("financial_evaluation.irr")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.irr')} 
                                            validate={[ number(), minValue(-100), maxValue(100) ]}
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                        />
                                    </CustomInput>
                                </Fragment>
                            : null
                    }
                    {
                        scopedFormData && scopedFormData.financial_evaluation && 
                        scopedFormData.financial_evaluation.appraisal_methodology &&
                        scopedFormData.financial_evaluation.appraisal_methodology === 'cea'
                            ?   this.renderCriterias("financial_evaluation")
                            :   null
                    }
                </div>
              
                <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.financial_evaluation.summary')} textArea>
                    <Labeled label={translate('resources.options_appraisals.fields.analytical_modules.financial_evaluation.summary')} />
                    <CustomTextArea source={getSource("financial_evaluation.summary")} {...this.props} />
                </CustomInput>
            </Fragment>
        )
    }

    renderEconomicalEvaluation = () => {
        const { getSource, scopedFormData, formData, translate } = this.props;

        if (scopedFormData && scopedFormData.economic_evaluation && 
            scopedFormData.economic_evaluation.appraisal_methodology &&
            scopedFormData.economic_evaluation.appraisal_methodology === 'cea'){
                scopedFormData.economic_evaluation.enpv = "0";
                scopedFormData.economic_evaluation.err = "0";
        }
        if (scopedFormData && scopedFormData.economic_evaluation && 
            scopedFormData.economic_evaluation.appraisal_methodology &&
            scopedFormData.economic_evaluation.appraisal_methodology === 'cba'){
                scopedFormData.economic_evaluation.criterias = [];
        }

        return (
            <Fragment>
                <div>
                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.economic_evaluation.appraisal_methodology')} fullWidth>
                        <SelectInput 
                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                            className="boolean-selector"
                            label={translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.title')} 
                            source={getSource("economic_evaluation.appraisal_methodology")}  
                            choices={[
                                { id: 'cba', name: translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.cba') },
                                { id: 'cea', name: translate('resources.options_appraisals.fields.analytical_modules.appraisal_methodology.cea') },
                            ]} />
                    </CustomInput>
                    {
                        scopedFormData && scopedFormData.economic_evaluation && 
                        scopedFormData.economic_evaluation.appraisal_methodology &&
                        scopedFormData.economic_evaluation.appraisal_methodology === 'cba'
                            ?   <Fragment>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.economic_evaluation.enpv')} fullWidth>
                                        <TextInput 
                                            source={getSource("economic_evaluation.enpv")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.enpv')} 
                                            validate={ number() }
                                            format={ commasFormatter }
                                            parse={ commasParser }
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                        />
                                    </CustomInput>
                                    <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.economic_evaluation.err')} fullWidth>
                                        <TextInput 
                                            source={getSource("economic_evaluation.err")} 
                                            label={translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.err')} 
                                            options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                            validate={[ number(), minValue(-100), maxValue(100) ]}
                                        />
                                    </CustomInput>
                                </Fragment>
                            : null
                    }
                    {
                        scopedFormData && scopedFormData.economic_evaluation && 
                        scopedFormData.economic_evaluation.appraisal_methodology &&
                        scopedFormData.economic_evaluation.appraisal_methodology === 'cea' 
                            ?   this.renderCriterias("economic_evaluation")
                            :   null
                    }
                </div>
              
                <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.economic_evaluation.summary')} textArea>
                    <Labeled label={translate('resources.options_appraisals.fields.analytical_modules.economic_evaluation.summary')} />
                    <CustomTextArea source={getSource("economic_evaluation.summary")} {...this.props} />
                </CustomInput>
            </Fragment>
        )
    }

    renderRisks = () => {
        const { getSource, scopedFormData, formData, translate } = this.props;

        return(
            <Fragment>
                <ArrayInput 
                    source={getSource(`risk_evaluation`)} 
                    label={false}
                >
                    <SimpleFormIterator disableAdd={this.props.disabled} disableRemove={this.props.disabled}>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData, formData, ...rest }) => {
                                return (
                                    <div>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.risk_evaluation.description')} fullWidth>
                                            <TextInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                source={getSource("description")} 
                                                label={translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.description')} 
                                            />
                                        </CustomInput>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.risk_evaluation.occurrence')} fullWidth>
                                            <SelectInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                className="boolean-selector"
                                                label={translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.occurrence')}
                                                source={getSource("occurrence")}
                                                choices={[
                                                    { id: 'low', name: translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.low') },
                                                    { id: 'medium', name: translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.medium')},
                                                    { id: 'high', name:  translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.high') },
                                                    { id: 'very_high', name:  translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.very_high') },
                                                ]} 
                                            />
                                        </CustomInput>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.risk_evaluation.impact')} fullWidth>
                                            <SelectInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                className="boolean-selector"
                                                label={translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.impact')}
                                                source={getSource("impact")}
                                                choices={[
                                                    { id: 'low', name: translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.low') },
                                                    { id: 'medium', name: translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.medium')},
                                                    { id: 'high', name:  translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.high') },
                                                    { id: 'very_high', name:  translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.levels.very_high') },
                                                ]} 
                                            />
                                        </CustomInput>
                                        <CustomInput tooltipText={translate('tooltips.resources.options_appraisals.fields.analytical_modules.risk_evaluation.mitigation_plan')} fullWidth>
                                            <TextInput 
                                                options={{ fullWidth: 'true', disabled: this.props.disabled }}
                                                source={getSource("mitigation_plan")} 
                                                label={translate('resources.options_appraisals.fields.analytical_modules.risk_evaluation.mitigation_plan') } 
                                            />
                                        </CustomInput>
                                    </div>
                                )}}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Fragment>
        )
    }

    renderTabContent(value) {
        const { getSource, scopedFormData, formData } = this.props;

        switch (value) {
            case 0:
                return this.renderFinancialEvaluation()
            case 1:
                return this.renderEconomicalEvaluation()
            case 2:
                return this.renderStakeholders();
            case 3:
                return this.renderRisks();
            default:
                return null;
        }
    }

    renderTabs () {
        const { record } = this.props;

        return this.option_modules
            .map((module) => 
                <Tab label={module.name} />
            )
    }

    render() {
        const { activeTab } = this.state;
        const { getSource, record, scopedFormData, translate } = this.props;
        
        return (
            <Fragment>
                <h5>{translate('resources.options_appraisals.fields.analytical_modules.title')}</h5>
                <Tabs
                    value={activeTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={null}
                >
                    { this.renderTabs() }
                </Tabs>
                {activeTab === 0 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 1 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 2 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
                {activeTab === 3 && <TabContainer>{this.renderTabContent(activeTab)}</TabContainer>}
            </Fragment>
        )
    }
}

export default translate(Alternatives);
