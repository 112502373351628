import React, { Fragment }  from 'react';
import { FormDataConsumer, TextInput, SelectInput, Labeled, translate } from 'react-admin';

import CustomInput from '../../../components/CustomInput';
import CustomTextArea from '../../../components/customTextArea';

const FinancialAnalysisForm = props => {
    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <Fragment>
                        <CustomInput tooltipText="IRR %" fullWidth>
                            <TextInput source="irr" label={props.translate('resources.project-details.fields.irr')} />
                        </CustomInput>
                        <CustomInput tooltipText="FNPV (UGX bln.)" fullWidth>
                            <TextInput source="fnpv" label={props.translate('resources.project-details.fields.fnpv')} />
                        </CustomInput>
                        <CustomInput tooltipText="ERR %" fullWidth>
                            <TextInput source="err" label={props.translate('resources.project-details.fields.err')} />
                        </CustomInput>
                        <CustomInput tooltipText="ENPV (UGX bln.)" fullWidth>
                            <TextInput source="enpv" label={props.translate('resources.project-details.fields.enpv')} />
                        </CustomInput>
                        <CustomInput tooltipText="Financing Modality" fullWidth>
                            <SelectInput
                                source="financing_modality"
                                label={props.translate('resources.project-details.fields.financing_modality')}
                                choices={[
                                    {
                                        id: 'traditional procurement', //TODO: check Ids
                                        name: 'Tradional Procurement',
                                    },
                                    {
                                        id: 'public private partnership',
                                        name: 'Public Private Partnership',
                                    },
                                ]}
                                options={{ fullWidth: 'true' }} 
                            />
                        </CustomInput>
                        <CustomInput tooltipText="Distribution Module" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.distributional_assessment')} />
                            <CustomTextArea  source="distributional_assessment" {...props} />
                        </CustomInput>
                        <CustomInput tooltipText="Environmental Impact Assessment" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.env_impact_assessment')} />
                            <CustomTextArea  source="env_impact_assessment" {...props} />
                        </CustomInput>
                        
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(FinancialAnalysisForm);
