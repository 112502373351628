import React from 'react';
import HTML2React from 'html2react'
import lodash from 'lodash';
import { romanize } from '../../../../helpers/formatters';

const ProjectJustification = props => {
    const { customRecord, translate, subCounter } = props;
        const { options_appraisals } = customRecord;

        if (!options_appraisals) {
            return null;
        }

        const bestOption = lodash.find(options_appraisals, (option) => option.is_best);
        
        if (!bestOption) {
            return (
                <div className="Section2">
                    <div className="content-area">
                        <h4>{romanize(subCounter || 8)}.  {translate('printForm.options.justification.title')}</h4>
                        <h5>{translate('printForm.options.justification.best_options_empty')}</h5>
                    </div>
                </div>
            )
        }

        return(
        <div className="Section2">
            <div className="content-area">
                <h4>{romanize(subCounter || 8)}.  {translate('printForm.options.justification.title')}</h4>
                <h5>{translate('printForm.options.justification.alternative')} <b>{bestOption.title}</b> {translate('printForm.options.justification.alternative_text')}</h5>
                <br/>
                <div>
                    <h4>{romanize((subCounter || 8) + ".1.")} {translate('printForm.options.justification.description')}</h4>
                    {HTML2React(bestOption.description)}
                </div>
                <div>
                    <h4>{romanize((subCounter || 8) + ".2.")} {translate('printForm.options.justification.details')}</h4>
                    {HTML2React(bestOption.justification)}
                </div>
                <div>
                    <h4>{romanize((subCounter || 8) + ".3.")} {translate('printForm.options.justification.modality')}</h4>
                    {HTML2React(bestOption.modality_justification)}
                </div>
            </div>
        </div>
        )
};

export default ProjectJustification;

