import React, { Fragment } from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    FormDataConsumer,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    number,
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';
import { USER_ROLES, DEFAULT_SORTING } from '../../constants/common';
import lodash from 'lodash';
import CustomInput from '../../components/CustomInput';
import CustomToolbar from '../../components/CustomToolbar';

export const DepartmentCreate = ({ translate, permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (
                    <Fragment>  
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.code')}>
                            <TextInput
                                source="code"
                                validate={[required(), number()]}
                                label={translate(
                                    'resources.departments.fields.code',
                                )}
                            />
                        </CustomInput>
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.name')}>
                            <TextInput
                                source="name"
                                validate={required()}
                                label={translate(
                                    'resources.departments.fields.name',
                                )}
                            />
                        </CustomInput>
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.sector_id')}>
                            <ReferenceInput
                                sort={DEFAULT_SORTING}
                                perPage={-1}
                                label="Sector"
                                source="sector_id"
                                reference="sectors"
                                validate={required()}
                            >
                                <SelectInput
                                    optionText="name"
                                    options={{ fullWidth: 'true' }}
                                />
                            </ReferenceInput>
                        </CustomInput>

                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.vote_id')}>
                            <ReferenceInput
                                sort={DEFAULT_SORTING}
                                perPage={-1}
                                label="Votes"
                                source="vote_id"
                                reference="votes"
                                validate={required()}
                                filter={{
                                    sector_id: formData.sector_id || null,
                                }}
                            >
                                <SelectInput
                                    optionText="name"
                                    options={{ fullWidth: 'true' }}
                                />
                            </ReferenceInput>
                        </CustomInput>
                    </Fragment>
                    )
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
    

export const DepartmentEdit = ({ translate, permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm   toolbar={<CustomToolbar />}>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (
                        <Fragment>
                        <DisabledInput source="id" />
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.code')}>
                            <TextInput
                                source="code"
                                validate={[required(), number()]}
                            />
                        </CustomInput>
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.name')}>
                            <TextInput source="name" validate={required()} />
                        </CustomInput>
                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.sector_id')}>
                            <ReferenceInput
                                sort={DEFAULT_SORTING}
                                perPage={-1}
                                label="Sector"
                                source="sector_id"
                                reference="sectors"
                                validate={required()}
                            >
                                <SelectInput
                                    optionText="name"
                                    options={{ fullWidth: 'true' }}
                                />
                            </ReferenceInput>
                        </CustomInput>

                        <CustomInput tooltipText={translate('tooltips.resources.departments.fields.vote_id')}>
                            <ReferenceInput
                                sort={DEFAULT_SORTING}
                                perPage={-1}
                                label="Votes"
                                source="vote_id"
                                reference="votes"
                                validate={required()}
                                filter={{
                                    sector_id: formData.sector_id || null,
                                }}
                            >
                                <SelectInput
                                    optionText="name"
                                    options={{ fullWidth: 'true' }}
                                />
                            </ReferenceInput>
                        </CustomInput>
                    </Fragment>

                    )
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

const DepartmentShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <EditButton basePath={basePath} record={data} />
        {/* <DeleteButton basePath={basePath} record={data} resource={resource} /> */}
    </CardActions>
);

export const DepartmentShow = ({ translate, ...props }) => {
    return (
        <Card>
            <CardHeader>
                <i className="fa fa-align-justify" />
                <strong>
                    {translate('resources.departments.name', {
                        smart_count: 2,
                    })}
                </strong>
            </CardHeader>
            <Show actions={<DepartmentShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="code" />
                    <TextField source="name" />
                    <ReferenceField
                        source="vote_id"
                        reference="votes"
                        linkType="show"
                    >
                        <FunctionField
                            label="Name"
                            render={record => `${record.code} - ${record.name}`}
                        />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

const DepartmentsFilter = props => (
    <Filter {...props}>
        <TextInput label="Code" source="code" />
        <TextInput label="Name" source="name" />
        <ReferenceInput 
            sort={DEFAULT_SORTING}
            perPage={-1} 
            source="vote_id" 
            reference="votes" 
            allowEmpty
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const DepartmentList = ({ translate, ...props }) => {
    return (
        <List {...props} filters={<DepartmentsFilter />} bulkActions={false}>
            <Datagrid>
                <TextField source="id" />
                <TextField
                    source="code"
                    label={translate('resources.departments.fields.code')}
                />
                <TextField
                    source="name"
                    label={translate('resources.departments.fields.name')}
                />
                <ReferenceField
                    source="vote_id"
                    reference="votes"
                    linkType="show"
                >
                    <FunctionField
                        label="Name"
                        render={record => `${record.code} - ${record.name}`}
                    />
                </ReferenceField>
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default DepartmentList;
