import React from 'react';
import HTML2React from 'html2react'
import { Table } from 'reactstrap';
import { getFiscalYearsRangeForIntervals, getFiscalYearValue, romanize } from '../../../helpers/formatters';

const getOutcomesForOutput = (record, item) => {
    const filtered = record.outcomes
        .filter((outcome) => item.outcome_ids.includes(outcome.id));
    
    return filtered && filtered.length !== 0
        ?   filtered.map((outcome) => outcome.title)
            .join(', ')
        :   '-'
}


export const ResultMatrix = props => {
        const { customRecord, customBasePath, translate } = props;
        const counter = props.counter || 3;
        const record = customRecord;
        const basePath = customBasePath;
        let fiscalYearsFromProps;

        if ( record.outcome_targets ) {
            const startDate = record.investment_years[0];
            const endDate = record.investment_years[record.investment_years.length - 1];
            fiscalYearsFromProps = record.investment_years && 
                getFiscalYearsRangeForIntervals(startDate, endDate);
        }

        return (
        <div className="landscapeSection">
            <div className="content-area">
            <h4>{romanize(counter)}.   {translate('printForm.project_framework.title_logical')}</h4>
            <Table responsive bordered>
            <thead>
                <tr className="filled-row">
                    <th rowspan="2">{translate('printForm.project_framework.goal')}</th>
                    <th rowspan="2">{translate('printForm.result_matrix.indicator_title')}</th>
                    <th colspan={record.outcome_targets.length + 1}>{translate('printForm.result_matrix.indicator')}</th>
                    <th rowspan="2">{translate('printForm.result_matrix.means')}</th>
                    <th rowspan="2">{translate('printForm.result_matrix.assumption')}</th>
                    <th rowspan="2">{translate('printForm.result_matrix.risks')}</th>
                </tr>
                <tr>
                    <th>{`${translate('printForm.result_matrix.baseline')} ${getFiscalYearValue(record.outcome_base).name} (SMART)`}</th>
                    {
                        fiscalYearsFromProps.map((year) =>
                            <th><strong>{`${translate('printForm.result_matrix.target')} ${year.name} (SMART)`}</strong></th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    record && record.goal_indicators && record.goal_indicators.length !== 0 ?
                        record.goal_indicators.map((indicator, idx) =>
                            <tr>
                                {
                                    idx === 0
                                        ?   <td rowSpan={record.goal_indicators.length}>{`${translate('printForm.result_matrix.project_goal')}: ${record.goal}`}</td>
                                        :   null
                                }
                                <td>{`Indicator ${idx+1}: ${indicator.title}`}</td>    
                                <td>{indicator.baseline}</td>
                                {record.outcome_targets.map((year) =>
                                    <td>{indicator[year]}</td>
                                )}
                                <td>{indicator.verification_means}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )
                        : <tr><td colSpan={record.outcome_targets.length + 2 + 5}>{`${translate('printForm.result_matrix.project_goal')}: ${record.goal}`}</td></tr>
                }

                <tr className="filled-row">
                    <td colSpan={record.outcome_targets.length + 2 + 4}>{translate('printForm.project_framework.outcome',  { smart_count: 2 })}</td>
                </tr>
                
                {
                    record && record.outcomes &&
                        record.outcomes.map((item, idxOutcome) =>
                        item.indicators && item.indicators.length !== 0
                            ?   item.indicators.map((indicator, idx) =>
                                    <tr>
                                        {
                                            idx === 0
                                                ?   <td rowSpan={item.indicators.length}>
                                                        <p>{`${translate('printForm.project_framework.outcome',  { smart_count: 1 })} ${idxOutcome + 1}: ${item.title}`}</p>
                                                        <div style={{ fontSize: '12px', fontStyle: 'italic'}}>{HTML2React(item.description)}</div> 
                                                    </td>
                                                :   null
                                        }
                                        <td>{`Indicator ${idx+1}: ${indicator.title}`}</td>    
                                        <td>{indicator.baseline}</td>
                                        {record.outcome_targets.map((year) =>
                                            <td>{indicator[year]}</td>
                                        )}
                                        <td>{indicator.verification_means}</td>
                                        <td>{indicator.assumptions}</td>
                                        <td>{indicator.risk_factors}</td>
                                    </tr>
                            )
                            :   <tr>
                                    <td colSpan={record.outcome_targets.length + 2 + 5}>
                                        <p>{`${translate('printForm.project_framework.outcome',  { smart_count: 1 })} ${idxOutcome + 1}: ${item.title}`}</p>
                                        <div style={{ fontSize: '12px', fontStyle: 'italic'}}>{HTML2React(item.description)}</div> 
                                    </td>
                                </tr>
                        )
                }

                <tr className="filled-row">
                    <td colSpan={record.outcome_targets.length + 2 + 4}>{translate('printForm.project_framework.output',  { smart_count: 2 })}</td>
                </tr>
                
                {
                    record && record.outputs &&
                        record.outputs.map((item, outputIdx) =>
                        item.indicators && item.indicators.length !== 0
                            ?   item.indicators.map((indicator, idx) =>
                                    <tr>
                                        {
                                            idx === 0
                                                ?   <td rowSpan={item.indicators.length}>
                                                        <p>{`${translate('printForm.project_framework.output',  { smart_count: 1 })} ${outputIdx + 1}: ${item.title}`}</p>
                                                        <div style={{ fontSize: '12px', fontStyle: 'italic'}}>{HTML2React(item.description)}</div> 
                                                    </td>
                                                :   null
                                        }
                                        <td>{`Indicator ${idx+1}: ${indicator.title}`}</td>    
                                        <td>{indicator.baseline}</td>
                                        {record.outcome_targets.map((year) =>
                                            <td>{indicator[year]}</td>
                                        )}
                                        <td>{indicator.verification_means}</td>
                                        <td>{indicator.assumptions}</td>
                                        <td>{indicator.risk_factors}</td>
                                    </tr>
                                )
                            : <tr>
                                <td colSpan={record.outcome_targets.length + 2 + 5}>
                                    <p>{`${translate('printForm.project_framework.output',  { smart_count: 1 })} ${outputIdx + 1}: ${item.title}`}</p>
                                    <div style={{ fontSize: '12px', fontStyle: 'italic'}}>{HTML2React(item.description)}</div> 
                                </td>
                            </tr>
                        )
                }   
            </tbody>
            </Table>
            <div className="row">
                <div className="col-sm-6">
                    <Table responsive >
                        <thead>
                            <tr className="filled-row"><th>{translate('printForm.result_matrix.activities_title')}</th></tr>
                        </thead>
                        <tbody>
                            {
                                record && record.outputs &&
                                record.outputs.map((item, outputIdx) =>
                                    [
                                        <tr className="filled-row">
                                            <td>
                                                <p style={{ marginBottom: '5px' }}><strong>{item.title}</strong></p>
                                                <p style={{ fontSize: '12px', fontStyle: 'italic', marginBottom: '5px'}}>
                                                    ({ getOutcomesForOutput(record, item) })
                                                </p>
                                            </td>
                                        </tr>,
                                        item.activities && item.activities.length > 0 &&
                                        <tr>
                                            <td>
                                                <ul>
                                                    {
                                                        item.activities.map((activity, idxAct) =>
                                                            <li>
                                                                <p>{`${outputIdx + 1}.${idxAct + 1 }`} {activity.title}</p>
                                                                <p style={{ fontSize: '12px', fontStyle: 'italic'}}>{HTML2React(activity.description)}</p>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </td>
                                        </tr>,
                                    ]
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            </div>
        </div>
        )
};
