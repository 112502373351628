// in src/dataProvider
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    fetchUtils,
} from 'react-admin';

import { API_URL } from '../constants/common';
import { TOKEN } from '../constants/auth';
import { stringify } from 'query-string';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const sortSign = order === 'DESC' ? '-' : '';
            let query = {
                sort: `${sortSign}${field}`,
                page: page,
                'per-page': perPage,
                ...params.filter,
            };

            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case GET_ONE:
            return { url: `${API_URL}/${resource}/${params.id}` };
        case GET_MANY: {
            //запрос референсе поля
            const query = {
                ids: JSON.stringify(params.ids),
                'per-page': -1,
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const sortSign = order === 'DESC' ? '-' : '';
            let query = {
                sort: `${sortSign}${field}`,
                page: page,
                'per-page': perPage,
                [params.target]: params.id,
                ...params.filter,
            };

            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${resource}`,
                options: { method: 'POST', body: JSON.stringify(params.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'DELETE' },
            };
        case DELETE_MANY:
            return {
                url: `${API_URL}/${resource}/${params.ids}`,
                options: { method: 'DELETE' },
            };
        case 'CUSTOM':
            return {
                url: `${API_URL}/${resource}/${params.type}`,
                options: { method: 'POST', body: JSON.stringify(params.query) },
            }
        case 'RESET':
            return {
                url: `${API_URL}/${resource}/${params.id}/reset`,
                options: { method: 'GET', body: JSON.stringify(params.query) },
            }
        case 'EXPORT':
            return {
                url: `${API_URL}/${resource}/export`,
                options: { method: 'POST', body: JSON.stringify(params.query) },
            }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
    response,
    type,
    resource,
    params,
) => {
    const { headers, json } = response;

    switch (type) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                // total: parseInt(headers.get('content-range').split('/').pop(), 10),
                total: parseInt(headers.get('x-pagination-total-count')),
            };
        case GET_MANY_REFERENCE:
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('x-pagination-total-count')),
            };
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        case DELETE:
            return { data: { ...params.previousData } };
        case DELETE_MANY:
            return { data: [...params.ids] };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const token = sessionStorage.getItem(TOKEN);
    let { url, options } = convertDataProviderRequestToHTTP(
        type,
        resource,
        params,
    );

    if (!options) {
        options = {};
    }

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    options.headers.set('Authorization', `Bearer ${token}`);

    if (type === DELETE_MANY) {
        return Promise.all(
            params.ids.map(id =>
                fetchJson(`${API_URL}/${resource}/${id}`, { ...options }),
            ),
        ).then(response => {
            return convertHTTPResponseToDataProvider(
                response,
                type,
                resource,
                params,
            );
        });
    }

    return fetchJson(url, options).then(response => {
        return convertHTTPResponseToDataProvider(
            response,
            type,
            resource,
            params,
        );
    });
};
