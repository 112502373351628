import React from 'react';
import HTML2React from 'html2react'
import { 
    FinancialEvaluation, 
    EconomicalEvaluation,
    ProjectJustification,
    StakeHolders, 
    RiskManagement, 
    OptionStrategic,
    DetailedAnalysysForOptions 
} from './options'

export const OptionsAnalysis = props => {
    const { customRecord, translate, counter } = props;

    return (
        <div>
            <OptionStrategic subCounter={Number(counter)}  {...props}/>
            <DetailedAnalysysForOptions {...props}/>
            <FinancialEvaluation subCounter={Number(counter) + 1} {...props}/>
            <EconomicalEvaluation subCounter={Number(counter) + 2} {...props}/>
            <StakeHolders subCounter={Number(counter) + 3} {...props}/>
            <RiskManagement subCounter={Number(counter) + 4} {...props}/>
            <ProjectJustification subCounter={Number(counter) + 5} {...props}/>
        </div>
    )
};
