import React from 'react';
import HTML2React from 'html2react'
import { Table } from 'reactstrap';
import { costSumFormatter } from '../../helpers';
import { romanize } from '../../../../helpers/formatters';

const EconomicalEvaluation = props => {
    const { customRecord, translate, subCounter } = props;
    const { options_appraisals } = customRecord;
    
    return(
        <div className="Section2">
        <div className="content-area">
            <h4>{romanize(subCounter || 5)}.   {translate('printForm.options.economical_evaluation.title')}</h4>
            <div>
                <p>{translate('printForm.options.economical_evaluation.summary')}</p>
                <Table bordered>
                    <thead>
                        <tr className="filled-row">
                            <th></th>
                            {
                                options_appraisals &&
                                options_appraisals
                                    .filter((item) => item.is_shortlisted || item.is_best)
                                    .map((option, idx) =>
                                    <th>{option.title}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{translate('printForm.options.economical_evaluation.enpv')}</td>
                            {
                                options_appraisals &&
                                options_appraisals
                                    .filter((item) => item.is_shortlisted || item.is_best)
                                    .map((option, idx) =>
                                    <td>{option.economic_evaluation && costSumFormatter(option.economic_evaluation.enpv)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td>{translate('printForm.options.economical_evaluation.err')}</td>
                            {
                                options_appraisals &&
                                options_appraisals
                                    .filter((item) => item.is_shortlisted || item.is_best)
                                    .map((option, idx) =>
                                        <td>{option.economic_evaluation && option.economic_evaluation.err || '-'}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td>{translate('printForm.options.cba_cea')}</td>
                            {
                                options_appraisals &&
                                options_appraisals
                                    .filter((item) => item.is_shortlisted || item.is_best)
                                    .map((option, idx) => {
                                        if (!option.economic_evaluation) {
                                            return <td>{"-"}</td>
                                        }
                                        return option.economic_evaluation && 
                                            option.economic_evaluation.appraisal_methodology === 'cba'
                                                ?   <td>{translate('printForm.options.economical_evaluation.cba')}</td>
                                                :    <td><p>{translate('printForm.options.economical_evaluation.cea')}</p>
                                                        {
                                                            option.economic_evaluation &&
                                                            option.economic_evaluation.criterias &&
                                                            option.economic_evaluation.criterias.length !== 0 &&
                                                                option.economic_evaluation.criterias.map((item) =>
                                                                        <div className="inner-table">
                                                                            <div className="row">
                                                                                <div className="cell_title">{translate('printForm.options.criterias.criteria_title')}:</div>
                                                                                <div className="cell">{item.title}</div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="cell_title">{translate('printForm.options.criterias.criteria_value')}:</div>
                                                                                <div className="cell">{item.criteria_value}</div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="cell_title">{translate('printForm.options.criterias.measure_unit')}:</div>
                                                                                <div className="cell">{item.measure_unit}</div>
                                                                            </div>
                                                                        </div>
                                                                )
                                                        }
                                                    </td>
                                    }                                            
                                )
                            }
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div>
                {
                    options_appraisals &&
                    options_appraisals
                        .filter((item) => item.is_shortlisted || item.is_best)
                        .map((option, idx) =>
                        <div>
                            <p><strong>{`${translate('printForm.options.detailed_analysis')} ${option.title}`}</strong></p>
                            {
                                option.economic_evaluation
                                    ?   HTML2React(option.economic_evaluation.summary)
                                    :   translate('printForm.options.detailed_analysis_empty')
                            }
                        </div>
                    )
                }
            </div>
        </div>
        </div>
    )
};

export default EconomicalEvaluation;