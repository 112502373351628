import React from 'react';
import HTML2React from 'html2react'
import lodash from 'lodash';
import {costSumFormatter} from '../helpers';
import { Table } from 'reactstrap';
import { getFiscalYearsRangeForIntervals, romanize } from '../../../helpers/formatters';

export const ActivitiesInvestments = props => {
    const { customRecord, customBasePath, translate } = props;
        const record = customRecord;
        let totalAllOutputs = {};
        let totalAll = {};
        let allCosts = 0;
        const counter = props.counter || 4;
        let fiscalYearsFromProps;

        if (!record) {
            return null;
        }

        if ( record.investment_years ) {
            const startDate = record.investment_years[0];
            const endDate = record.investment_years[record.investment_years.length - 1];
            fiscalYearsFromProps = record.investment_years && 
                getFiscalYearsRangeForIntervals(startDate, endDate);
        }

        return(
            <div className="landscapeSection">
            <div className="content-area">
                <h4>{romanize(counter)}.  {translate('printForm.investments.title')}</h4>
                <Table responsive bordered>
                    <thead>
                        <tr className="filled-row">
                            <th rowSpan="2"></th>
                            <th>{translate('printForm.investments.code')}</th>
                            <th>{translate('printForm.investments.name')}</th>
                            <th colSpan={record.investment_years.length}>Period</th>
                            <th rowSpan="2">{translate('printForm.investments.total')}</th>
                            <th rowSpan="2">{translate('printForm.investments.funds')}</th>
                        </tr>
                        <tr className="filled-row">
                            <th colSpan="2">{translate('printForm.investments.invest_title')}</th>
                            {
                                fiscalYearsFromProps.map(year =>
                                    <th>{year.name}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            record.outputs.map((output, outputIdx) => {
                                const outputsData = [];
                                const outputTotal = {};
                                const activs = {};
                                let totalOutput = 0;
                                
                                const activities = output.activities.map((activity, activityIdx) => {
                                    let activityTotal = 0;
                                    return (
                                        [
                                            <tr>
                                                <td>{romanize(`${outputIdx+1}.${activityIdx+1}`)}</td>
                                                <td colSpan="2">
                                                    {`${translate('printForm.project_framework.activity', { smart_count: 1 })} ${activityIdx + 1}: ${activity.title}`}
                                                </td>
                                                {                                              
                                                    record.investment_years.map((year) => {
                                                        if (!outputTotal[year]){
                                                            outputTotal[year] = 0;
                                                        }
                                                        let investmentSum = lodash.sumBy(activity.investments, (item) => 
                                                            typeof item[year] !== 'undefined' 
                                                                ? parseFloat(item[year]) : 0)
                                                        activityTotal += investmentSum ? investmentSum : 0;
                                                        outputTotal[year] += investmentSum;
                                                        return (
                                                            <td>{costSumFormatter(investmentSum)}</td>
                                                        )
                                                    })
                                                }
                                                <td>{isNaN(activityTotal) ? '-' : costSumFormatter(activityTotal)}</td>
                                                <td></td>
                                            </tr>,
                                            activity.investments && activity.investments.map((item, idx) => {
                                                let fundTotal = 0;
                                                return (
                                                    <tr>
                                                        <td>{romanize(`${outputIdx+1}.${activityIdx+1}.${idx+1}`)}</td>
                                                        <td>{item.cost_classification && item.cost_classification.code}</td>
                                                        <td>{item.cost_classification && item.cost_classification.name}</td>
                                                        {
                                                            record.investment_years.map((year) => {
                                                                const yearsData = typeof item[year] !== 'undefined' 
                                                                    ? parseFloat(item[year]) 
                                                                    : 0
                                                                    fundTotal += yearsData ? yearsData : 0;
                                                                return(
                                                                    <td>{costSumFormatter(yearsData)}</td>
                                                                )
                                                            })
                                                        }
                                                        <td>{costSumFormatter(fundTotal)}</td>
                                                        <td>{item.fund_source_details && `${item.fund_source_details.code}-${item.fund_source_details.name}`}</td>
                                                    </tr>
                                                )
                                            })
                                        ]
                                    )
                                })

                                outputsData.push(
                                    <tr className="filled-row">
                                        <td>{romanize(outputIdx+1)}</td>
                                        <td colSpan="2">
                                            {`${translate('printForm.project_framework.output', { smart_count: 1 })} ${outputIdx + 1}: ${output.title}`}
                                        </td>
                                        {
                                            record.investment_years.map((year) => {
                                                if (!totalAll[year]){
                                                    totalAll[year] = 0;
                                                }
                                                totalOutput += outputTotal[year] ? outputTotal[year] : 0;
                                                totalAll[year] += outputTotal[year] ? outputTotal[year] : 0;
                                                return(
                                                    <td>{isNaN(outputTotal[year]) ? '-' : costSumFormatter(outputTotal[year])}</td>
                                                )
                                            })
                                        }
                                        <td>{isNaN(totalOutput) ? '-' : costSumFormatter(totalOutput)}</td>
                                        <td></td>

                                    </tr>
                                )
                                outputsData.push(activities);

                                return outputsData;
                            })
                        }
                        <tr className="filled-row">
                            <td colSpan="3">{translate('printForm.investments.total_costs')}</td>
                            {
                                record.investment_years.map((year) => {
                                    allCosts += totalAll[year]
                                        ? totalAll[year]
                                        : 0;
                                    return(
                                        <td>{isNaN(totalAll[year]) ? '-' : costSumFormatter(totalAll[year])}</td>
                                    )
                                })
                            }
                            <td>{isNaN(allCosts) ? '-' : costSumFormatter(allCosts)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            </div>
        )
};
