import React from 'react';
import HTML2React from 'html2react'
import { FinancialEvaluation, EconomicalEvaluation, StakeHolders, RiskManagement } from './options'
import { romanize } from '../../../helpers/formatters';

export const OptionsAppraisal = props => {
    const { customRecord, translate } = props;
    const counter = Number(props.counter)
    const { options_appraisals } = customRecord;
    const option_modules = [
        {
            name: translate('resources.options_appraisals.fields.building_blocks.modules.demand_module'),
            id: 'demand_module'
        },
        {
            name: translate('resources.options_appraisals.fields.building_blocks.modules.technical_module'),
            id: 'technical_module'
        },
        {
            name: translate('resources.options_appraisals.fields.building_blocks.modules.env_module'),
            id: 'env_module'
        },
        {
            name: translate('resources.options_appraisals.fields.building_blocks.modules.hr_module'),
            id: 'hr_module'
        },
        {
            name: translate('resources.options_appraisals.fields.building_blocks.modules.legal_module'),
            id: 'legal_module'
        },
    ]

    if (!customRecord || !options_appraisals) {
        return null;
    }

    return (
        <div className="Section2">
        <div className="content-area">
            {
                options_appraisals.map((option) => 
                    option_modules.map((modul, idx) => 
                        <div className="content-area">
                            <h4>{romanize(counter + idx)}.    {modul.name}</h4>
                            {
                                option[modul.id] &&
                                <div>
                                    <p className="content-area_subtitle">{translate('printForm.options.description')}:</p>
                                    {HTML2React(option[modul.id].description)}
                                </div>
                            }
                            
                        </div>
                    )
                    
                )
            }
            <FinancialEvaluation subCounter={Number(counter) + option_modules.length} {...props}/>
            <EconomicalEvaluation subCounter={Number(counter) + option_modules.length + 1 } {...props}/>
            <StakeHolders subCounter={Number(counter) + option_modules.length + 2 } {...props}/>
            <RiskManagement subCounter={Number(counter) + option_modules.length + 3 } {...props}/>
        </div>
        </div>
    )
};
