import React from 'react';
import { Table } from 'reactstrap';
import { costSumFormatter } from '../../../Projects/helpers';
import { romanize } from '../../../../helpers/formatters';
import HTML2React from 'html2react'

const OptionStrategic = props => {
    const { customRecord, translate, subCounter } = props;

    return(
        <div className="Section2">
        <div className="content-area">
            <h4>{romanize(subCounter)}.  {translate('printForm.options.title')}</h4>
            <div>
                {
                    customRecord &&
                    !customRecord.options_appraisals || (
                        customRecord.options_appraisals &&
                        customRecord.options_appraisals.length === 0
                    )
                    ? null
                    :
                    <div>
                        <Table responsive bordered>
                        <thead>
                            <tr>
                                <th rowSpan="2">{translate('printForm.options.name')}</th>
                                <th rowSpan="2">{translate('printForm.options.cost')}</th>
                                <th rowSpan="2">{translate('printForm.options.funding_modality')}</th>
                                <th rowSpan="2">{translate('printForm.options.score')}</th>
                                <th colSpan="5">{translate('printForm.options.modular_scores')}</th>
                            </tr>
                            <tr>
                                <th>{translate('printForm.options.demand_module')}</th>
                                <th>{translate('printForm.options.tech_module')}</th>
                                <th>{translate('printForm.options.env_module')}</th>
                                <th>{translate('printForm.options.hr_module')}</th>
                                <th>{translate('printForm.options.legal_module')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customRecord &&
                                customRecord.options_appraisals &&
                                customRecord.options_appraisals.map((item) =>
                                    <tr>    
                                        <td>
                                            <p>{item.title}</p>
                                            <span style={{ fontStyle: 'italic'}}>{HTML2React(item.description)}</span>
                                        </td>
                                        <td>{costSumFormatter(item.cost)}</td>
                                        <td>{translate(`resources.options_appraisals.fields.funding_modality.${item.funding_modality}`)}</td>
                                        <td>{item.score}</td>
                                        <td>{item.demand_module && item.demand_module.score}</td>
                                        <td>{item.technical_module && item.technical_module.score}</td>
                                        <td>{item.env_module && item.env_module.score}</td>
                                        <td>{item.hr_module && item.hr_module.score}</td>
                                        <td>{item.legal_module && item.legal_module.score}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                }
            </div>
        </div>
        </div>
    )
};

export default OptionStrategic;
