import React, { Fragment } from 'react';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import './styles.scss';

const CustomInput = (props) => {
    const wrapperStyle = classNames({
        "input-wrapper": true,
        "input-wrapper_fillWidth": props.fullWidth
    });
    const iconStyle = classNames({
        "input-wrapper__icon": true,
        "input-wrapper__icon_topRight": props.textArea
    });
    const contentStyle = classNames({
        "input-wrapper__content": true,
        "input-wrapper__content_boolean": props.bool,
        "input-wrapper__content_disabled": props.disabled
    });
    return (
        <div className={wrapperStyle}>
            <div className={contentStyle}>
                {props.children}
            </div>
            <Tooltip title={props.tooltipText} placement="right">
                <HelpOutline className={iconStyle} />
            </Tooltip>
        </div>
    )
}

export default CustomInput;
