import React, { Fragment } from 'react';
import { FormDataConsumer, Labeled, translate } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import CustomInput from '../../../components/CustomInput';

const ModulesForm = props => {
    return (
        <FormDataConsumer>
            {({ getSource, scopedFormData, formData, ...rest }) => {
                return (
                    <Fragment>
                        <CustomInput tooltipText="Technical Design" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.technical_design')} />
                            <RichTextInput source="technical_design" />
                        </CustomInput>
                        <CustomInput tooltipText="Production Plan" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.production_plan')} />
                            <RichTextInput source="production_plan" />
                        </CustomInput>
                        <CustomInput tooltipText="Human Resources and Administration Requirements" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.hr_requirements')} />
                            <RichTextInput source="hr_requirements" />
                        </CustomInput>
                        <CustomInput tooltipText="Institutional and Legal Assessments" textArea>
                            <Labeled label={props.translate('resources.project-details.fields.legal_assessment')} />
                            <RichTextInput source="legal_assessment" />
                        </CustomInput>
                        
                        {formData && formData.phase_id === 5 && (
                            <Fragment>
                                <CustomInput tooltipText="Financial/Private Evaluation" textArea>
                                    <Labeled label={props.translate('resources.project-details.fields.financial_evaluation')} />
                                    <RichTextInput source="financial_evaluation" />
                                </CustomInput>
                                <CustomInput tooltipText="Economic/Social Evaluation" textArea>
                                    <Labeled label={props.translate('resources.project-details.fields.economic_evaluation')} />
                                    <RichTextInput source="economic_evaluation" />
                                </CustomInput>
                                <CustomInput tooltipText="Risk Evaluation" textArea>
                                    <Labeled label={props.translate('resources.project-details.fields.risk_evaluation')} />
                                    <RichTextInput source="risk_evaluation" />
                                </CustomInput>
                            </Fragment>
                        )}
                        {formData && formData.phase_id === 5 && (
                            <Fragment>
                                <CustomInput tooltipText="Execution and Management Plan" textArea>
                                    <Labeled label={props.translate('resources.project-details.fields.exec_management_plan')} />
                                    <RichTextInput source="exec_management_plan" />
                                </CustomInput>
                                <CustomInput tooltipText="Sustainability Plan" textArea>
                                    <Labeled label={props.translate('resources.project-details.fields.sustainability_assessment')} />
                                    <RichTextInput source="sustainability_assessment" />
                                </CustomInput>
                            </Fragment>
                        )}
                    </Fragment>
                );
            }}
        </FormDataConsumer>
    );
};

export default translate(ModulesForm);
