import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Button,
    DateInput,
    DisabledInput,
    FormDataConsumer,
    FunctionField,
    Labeled,
    LongTextInput,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    minValue,
    number,
    required,
    showNotification,
    translate
} from 'react-admin';
import React, { Component, Fragment } from 'react';
import { change, isSubmitting, submit } from 'redux-form';
import { getFormSyncErrors, getFormValues } from 'redux-form';

import CustomInput from '../../../components/CustomInput';
import CustomMap from '../../../components/customMap';
import CustomTextArea from '../../../components/customTextArea';
import { GoalIndicatorList } from '../ProjectDetails';
import ManageIndicatorsButton from './ResultMatrixForms/ManageIndicatorsButton';
import Map from '@material-ui/icons/Map';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import dataProvider from '../../../providers/dataProvider';
import { getFiscalYears } from '../../../helpers/formatters';
import moment from 'moment';
import { DEFAULT_SORTING, LOCATIONS } from '../../../constants/common';

class SummaryForm extends Component {
    state = {
        showMap: false
    }

    handleShowMap = () => {
        this.setState({ showMap: !this.state.showMap });
    }

    checkTitleDublication = () => {
        dataProvider('CUSTOM', 'projects', { type: 'validate-title', query: {title: this.props.formValues.title }})
            .then((response) => {
                if (response && !response.data) {
                    this.props.showNotification(this.props.translate('messages.duplicate_project_title'), 'warning');
                }
            })
    }

    render(){
        const { permissions } = this.props;
        const userConfig = permissions && JSON.parse(permissions.config);
        const user_id = permissions && JSON.parse(permissions.user_id);
        
        return (
            <FormDataConsumer>
                {({ getSource, scopedFormData, formData, ...rest }) => {
                    if (formData && userConfig && user_id) {
                        formData.sector_id = userConfig.sector_id;
                        formData.vote_id = userConfig.vote_id;
                        formData.department_id = userConfig.department_id;
                    }
    
                    if (formData) {
                        formData.is_dlp =
                            typeof formData.is_dlp !== 'undefined'
                                ? formData.is_dlp
                                : formData.dl_period &&
                                  Number(formData.dl_period) !== 0
                                ? true
                                : false;
    
                        formData.is_omp =
                            typeof formData.is_omp !== 'undefined'
                                ? formData.is_omp
                                : formData.om_period &&
                                  Number(formData.om_period) !== 0
                                ? true
                                : false;
    
                        formData.om_period = !formData.is_omp
                            ? 0
                            : formData.om_period || 1;
                        formData.dl_period = !formData.is_dlp
                            ? 0
                            : formData.dl_period || 1;
                        if (formData.duration < 0) {
                            formData.duration = 0;
                        }
                        if (!formData.duration) {
                            formData.duration = moment(formData.end_date, 'YYYY-MM-DD HH:mm:ss')
                                .diff(moment(formData.start_date, 'YYYY-MM-DD HH:mm:ss'), 'years') + 1;
                        }
                        
                        if (formData.duration) {
                            formData.end_date = moment(formData.start_date, 'YYYY-MM-DD HH:mm:ss')
                                .add('years', formData.duration - 1)
                                .format('YYYY-MM-DD 00:00:00')
                        }
    
                        
                    }
    
                    return (
                        <Fragment>
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.sectors.fields.name')}>
                                <ReferenceInput
                                    source="sector_id"
                                    reference="sectors"
                                    perPage={-1}
                                    sort={DEFAULT_SORTING}
                                    label={this.props.translate(
                                        'resources.sectors.name',
                                        { smart_count: 1 },
                                    )}
                                >
                                    <SelectInput
                                        optionText="name"
                                        options={{ fullWidth: 'true', disabled: 'true' }}
                                    />
                                </ReferenceInput>
                            </CustomInput>
    
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.votes.fields.name')}>
                                <ReferenceInput
                                    source="vote_id"
                                    reference="votes"
                                    perPage={-1}
                                    sort={DEFAULT_SORTING}
                                    label={this.props.translate('resources.votes.name', {
                                        smart_count: 1,
                                    })}
                                >
                                    <SelectInput
                                        optionText="name"
                                        options={{ fullWidth: 'true', disabled: 'true' }}
                                    />
                                </ReferenceInput>
                            </CustomInput>  

                            <CustomInput tooltipText={this.props.translate('tooltips.resources.vote-functions.fields.name')}>
                                <ReferenceInput
                                    sort={DEFAULT_SORTING}
                                    perPage={-1}
                                    source="vote_function_id"
                                    reference="vote-functions"
                                    label={this.props.translate(
                                        'resources.vote-functions.name',
                                        { smart_count: 1 },
                                    )}
                                    filter={{
                                        vote_id:
                                            (formData && formData.vote_id) || null,
                                    }}
                                    validate={required()}
                                    filterToQuery={searchText => ({ "name": searchText })}
                                >
                                    <SelectInput
                                        optionText="name"
                                        options={{ fullWidth: 'true' }}
                                    />
                                </ReferenceInput>
                            </CustomInput>

                            <CustomInput tooltipText={this.props.translate('tooltips.resources.departments.fields.name')}>
                                <ReferenceInput
                                    source="department_id"
                                    reference="departments"
                                    sort={DEFAULT_SORTING}
                                    perPage={-1}
                                    label={this.props.translate(
                                        'resources.departments.name',
                                        { smart_count: 1 },
                                    )}
                                >
                                    <SelectInput
                                        optionText="name"
                                        options={{ fullWidth: 'true', disabled: 'true' }}
                                    />
                                </ReferenceInput>
                            </CustomInput>
    
                            <ArrayInput
                                source="implementing_agencies"
                                label={this.props.translate(
                                    'resources.project-details.fields.implementing_agencies',
                                )}
                            >
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({
                                            formData,
                                            scopedFormData,
                                            getSource,
                                            ...rest
                                        }) => (
                                            <CustomInput
                                                tooltipText={this.props.translate('tooltips.resources.sectors.fields.name')}
                                                fullWidth
                                            >
                                                <ReferenceInput
                                                    sort={DEFAULT_SORTING}
                                                    perPage={-1}
                                                    label={this.props.translate(
                                                        'resources.sectors.name',
                                                        { smart_count: 1 },
                                                    )}
                                                    source={getSource('sector_id')}
                                                    reference="sectors"
                                                >
                                                    <SelectInput
                                                        optionText="name"
                                                        options={{
                                                            fullWidth: 'true',
                                                        }}
                                                    />
                                                </ReferenceInput>
                                            </CustomInput>
                                        )}
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {({
                                            formData,
                                            scopedFormData,
                                            getSource,
                                            ...rest
                                        }) => (
                                            <CustomInput
                                                tooltipText={this.props.translate('tooltips.resources.votes.fields.name')}
                                                fullWidth
                                            >
                                                <ReferenceInput
                                                    perPage={-1}
                                                    sort={DEFAULT_SORTING}
                                                    source={getSource('vote_id')}
                                                    reference="votes"
                                                    filter={{
                                                        sector_id:
                                                            (scopedFormData &&
                                                                scopedFormData.sector_id) ||
                                                            null,
                                                    }}
                                                    label={this.props.translate(
                                                        'resources.votes.name',
                                                        { smart_count: 1 },
                                                    )}
                                                >
                                                    <SelectInput
                                                        optionText="name"
                                                        options={{
                                                            fullWidth: 'true',
                                                            disabled: this.props.isLoading
                                                        }}
                                                    />
                                                </ReferenceInput>
                                            </CustomInput>
                                        )}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
    
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.title')}>
                                <LongTextInput
                                    source="title"
                                    label={this.props.translate(
                                        'resources.project-details.fields.title',
                                    )}
                                    validate={required()}
                                    onBlur={this.checkTitleDublication}
                                />
                            </CustomInput>
    
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.summary')} textArea>
                                <Labeled
                                    label={this.props.translate(
                                        'resources.project-details.fields.summary',
                                    )}
                                />
                                <CustomTextArea  source="summary" validate={required()} {...this.props} />
                            </CustomInput>
    
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.start_date')} >
                                <SelectInput 
                                    options={{ fullWidth: 'true'}}
                                    label={this.props.translate(
                                        'resources.project-details.fields.start_date',
                                    )}
                                    source="start_date"
                                    choices={getFiscalYears()}
                                    validate={required()}
                                />
                            </CustomInput>
                            
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.duration')}>
                                <NumberInput source="duration" step={1} validate={[ minValue(0)]}/>
                            </CustomInput>
                            
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.end_date')}>
                            <SelectInput 
                                    options={{ fullWidth: 'true', disabled: 'true'}}
                                    label={this.props.translate(
                                        'resources.project-details.fields.end_date',
                                    )}
                                    source="end_date"
                                    choices={getFiscalYears()}
                                    validate={required()}
                                />
                            </CustomInput>

                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.location')}>
                            <SelectInput 
                                    options={{ fullWidth: 'true' }}
                                    label={this.props.translate(
                                        'resources.project-details.fields.location',
                                    )}
                                    source="location_id"
                                    choices={LOCATIONS}
                                    validate={required()}
                                />
                            </CustomInput>
    
                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.location')} textArea>
                                <Labeled
                                    label={this.props.translate(
                                        'resources.project-details.fields.location',
                                    )}
                                />
                                <CustomTextArea source="location" validate={required()} {...this.props} />
                                <TextInput
                                    style={{ display: 'none' }}
                                    options={{ disabled: true }}
                                    source="geo_location"
                                    label={this.props.translate(
                                        'resources.project-details.fields.geo_location',
                                    )}
                                />
                                <Button label="Show Map" onClick={this.handleShowMap} />
                                {
                                    this.state.showMap &&
                                        <CustomMap 
                                            {...this.props} 
                                        />
                                }
                            </CustomInput>
                           
                            {formData && formData.phase_id > 3 && (
                                <Fragment>
                                    <CustomInput
                                        tooltipText={this.props.translate('tooltips.resources.project-details.fields.is_dlp')}
                                        bool
                                    >
                                        <BooleanInput
                                            source="is_dlp"
                                            label={this.props.translate(
                                                'resources.project-details.fields.is_dlp',
                                            )}
                                        />
                                    </CustomInput>
                                    {formData && formData.is_dlp ? (
                                        <Fragment>
                                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.dl_period')}>
                                                {/* <TextInput
                                                    source="dl_period"
                                                    label={this.props.translate(
                                                        'resources.project-details.fields.dl_period',
                                                    )}
                                                    validate={number()}
                                                /> */}
                                                <NumberInput 
                                                    source="dl_period" 
                                                    label={this.props.translate(
                                                        'resources.project-details.fields.dl_period',
                                                    )}
                                                    step={1} 
                                                    validate={[number(), minValue(1)]}
                                                />
                                            </CustomInput>
                                        </Fragment>
                                    ) : null}
    
                                    <CustomInput
                                        tooltipText={this.props.translate('tooltips.resources.project-details.fields.is_omp')}
                                        bool
                                    >
                                        <BooleanInput
                                            source="is_omp"
                                            label={this.props.translate(
                                                'resources.project-details.fields.is_omp',
                                            )}
                                        />
                                    </CustomInput>
                                    {formData && formData.is_omp ? (
                                        <Fragment>
                                            <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.om_period')}>
                                                {/* <TextInput
                                                    source="om_period"
                                                    label={this.props.translate(
                                                        'resources.project-details.fields.om_period',
                                                    )}
                                                    validate={number()}
                                                /> */}
                                                <NumberInput 
                                                    source="om_period" 
                                                    label={this.props.translate(
                                                        'resources.project-details.fields.om_period',
                                                    )}
                                                    step={1} 
                                                    validate={[number(), minValue(1)]}
                                                />
                                            </CustomInput>
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            )}
                            {
                                formData && formData.phase_id > 2 &&
                                    <CustomInput tooltipText={this.props.translate('tooltips.resources.project-details.fields.exec_management_plan')} textArea>
                                        <Labeled label={this.props.translate(
                                                        `resources.project-details.fields.exec_management_plan${formData.phase_id >= 3 ? '_pfs' : '' }`,
                                                    )} />
                                        <CustomTextArea  source="exec_management_plan" validate={required()} {...this.props} />
                                    </CustomInput>
                            }
                        </Fragment>
                    );
                }}
            </FormDataConsumer>
        );
    }
}

const mapStateToProps = state => ({
    formValues: getFormValues('record-form')(state),
    formErrors: getFormSyncErrors('record-form')(state),
});

const mapDispatchToProps = {
    change,
    isSubmitting,
    showNotification,
    submit,
};

export default translate(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SummaryForm),
);
