// export const API_URL = 'http://api-ibp.torusline.com'; //production
// export const API_URL = 'http://api-ibp-dev.torusline.com'; //development
// export const API_URL = 'http://api.ibp.dev'; //development
// export const API_URL = 'http://ibp-main/api/web'; //local ibp

export const API_URL =
    process.env.REACT_APP_API_URL || 'http://api-ibp-dev.torusline.com';

export const NUMBER_FORMATS = {
    ROMANS: 'Romans',
    ARABIC: 'Arabic',
};

export const DONORS_FUNDS_IDS = [5, 6, 7];

export const CURRENT_NUMBER_FORMAT = NUMBER_FORMATS.ARABIC;

export const GOOGLE_MAP_API_KEY = 'AIzaSyCjZaIrsFvoMMNtQ7fBb0Qtb-oQ7WSD7c0';

export const DEFAULT_SORTING = { field: 'name', order: 'ASC' };

export const PROJECT_STEPS = {
    SUMMARY: 'Summary',
    RESPONSIBLE_OFFICER: 'Responsible Officer',
    PROJECT_BACKGROUND: 'Project Background',
    PROJECT_FRAMEWORK: 'Project Framework',
    DEMAND_ANALYSIS: 'Demand Analysis',
    OPTIONS_ANALYSIS: 'Options Analysis',
    PROJECT_APPRAISAL: 'Project Appraisal',
    FIN_ANALYSIS: 'Fin&Econ Analysis',
    COST: 'Costs',
    RESULT_MATRIX: 'Result Matrix',
    MODULES: 'Modules',
    ADDITIONAL_INFO: 'Additional information',
    OM_COSTS: 'O&M Cost',
    INTRODUCTION: 'Introduction',
};

export const PROJECT_FORMS = {
    SUMMARY: 'SummaryForm',
    RESPONSIBLE_OFFICER: 'ResponsibleOfficerForm',
    DEMAND_ANALYSIS: 'DemandAnalysisForm',
    PROJECT_BACKGROUND: 'ProjectBackgroundForm',
    PROJECT_FRAMEWORK: 'ProjectFrameworkForm',
    OPTIONS_APPRAISAL: 'OptionsAppraisalForm',
    FINANCIAL_ANALYSIS: 'FinancialAnalysisForm',
    RESULT_MATRIX: 'ResultMatrixForm',
    MODULES: 'ModulesForm',
    ADDITIONAL_INFO: 'AdditionalInfoForm',
    OM_COSTS: 'OMCostsForm',
    INTRODUCTION: 'IntroductionForm',
};

export const ACTION_TYPES = {
    submit: 'submit',
    approve: 'approve',
    reject: 'reject',
    revise: 'revise',
    assign: 'assign',
};

export const USER_ROLES = {
    ADMIN: 'admin',
    DAU: 'department_admin_user',
    PCU: 'pap_commissioner_user',
    PHU: 'pap_head_user',
    PSU: 'pap_standard_user',
    SU: 'standard_user',
    VAU: 'vote_admin_user',
    AO: 'account_officer',
    DCU: 'dc_member_user',
    SHU: 'sector_head_user',
    DOU: 'desk_officer_user',
    DU: 'donor_user',
    PAC: 'pap_assist_commissioner_user',
    PSE: 'pap_senior_economist_user',
    SECU: 'secretariat_user',
};

export const PROJECT_PHASES = {
    '1': 'Project Concept',
    '2': 'Project Profile',
    '3': 'Pre-feasibility',
    '4': 'Feasibility',
    '5': 'Project Proposal',
};

export const PROJECT_PHASES_COLORS = {
    '1': '#ffc107',
    '2': '#20a8d8',
    '3': '#6f42c1',
    '4': '#20c997',
    '5': '#4dbd74',
};

export const PROJECT_STATUS = {
    STATUS_REJECTED: 'rejected',
    STATUS_APPROVED: 'approved',
    STATUS_ASSIGNED: 'assigned',
    STATUS_REVISED: 'revised',
    STATUS_DRAFT: 'draft',
    STATUS_SUBMITTED: 'submitted',
    STATUS_IN_PIPELINE: 'in pipeline',
    STATUS_IS_COMPLETED: 'completed',
};

export const PAP_DEPARTMENT_USERS = [
    USER_ROLES.PCU,
    USER_ROLES.PHU,
    USER_ROLES.PSU,
    USER_ROLES.ADMIN,
    USER_ROLES.DCU, // TODO: need confirmation
    USER_ROLES.PAC,
    USER_ROLES.PSE,
];

export const EXPORT_TYPES = {
    XLS: 'csv',
    WORD: 'doc',
    PDF: 'pdf',
};

export const NOTIFICATIONS_REFRESH_TIME = 60000 * 5; // 5 minutes

export const VALIDATION_FIELDS = [
    {
        field: 'sector_id',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.sector_id',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'vote_id',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.vote_id',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'vote_function_id',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.vote_function_id',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'implementing_agencies',
        phases: [],
        translation: 'validation.implementing_agencies',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'title',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.title',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'summary',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.summary',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'start_date',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.start_date',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'end_date',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.end_date',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'location',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.location',
        step: PROJECT_STEPS.SUMMARY,
    },
    {
        field: 'exec_management_plan',
        phases: [3, 4, 5],
        translation: 'validation.exec_management_plan',
        step: PROJECT_STEPS.SUMMARY,
    },
    //officer
    {
        field: 'responsible_officers',
        innerFields: [
            'title',
            'name',
            'phone',
            'mobile_phone',
            'email',
        ],
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.officer_title',
        step: PROJECT_STEPS.RESPONSIBLE_OFFICER,
    },
    //back
    {
        field: 'situation_analysis',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.situation_analysis',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'problem_statement',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.problem_statement',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'problem_cause',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.problem_cause',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'problem_effects',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.problem_effects',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'justification',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.justification',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'stakeholders',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.stakeholders',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'ndp_type',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.ndp_type',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'plan_details',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.plan_details',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    {
        field: 'government_coordination',
        innerFields: ['name', 'details'],
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.government_coordination',
        step: PROJECT_STEPS.PROJECT_BACKGROUND,
    },
    //matrix
    {
        field: 'goal',
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.goal',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'goal.indicators',
        innerFields: ['title', 'baseline', 'verification_means'],
        phases: [2, 3, 4, 5],
        translation: 'validation.goal_indicators',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outcomes',
        innerFields: ['title'],
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.outcomes',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outcomes.indicators',
        innerFields: [
            'title',
            'baseline',
            'verification_means',
            'assumptions',
            'risk_factors',
        ],
        phases: [2, 3, 4, 5],
        translation: 'validation.outcomes_indicators',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outputs',
        innerFields: ['title', 'outcome_ids', 'vote_id', 'description'],
        phases: [1, 2, 3, 4, 5],
        translation: 'validation.outputs',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outputs.indicators',
        innerFields: [
            'title',
            'baseline',
            'verification_means',
            'assumptions',
            'risk_factors',
        ],
        phases: [2, 3, 4, 5],
        translation: 'validation.outputs_indicators',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outputs.investments',
        innerFields: [
            'fund_id',
            'fund_source_id',
            'cost_category_id',
            'cost_classification_id',
        ],
        phases: [1],
        translation: 'validation.outputs_investments',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'activities',
        innerFields: [
            'title',
            'start_date',
            'end_date',
            'output_ids',
            'description',
        ],
        phases: [2, 3, 4, 5],
        translation: 'validation.activities',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'activities.investments',
        innerFields: [
            'fund_id',
            'fund_source_id',
            'cost_category_id',
            'cost_classification_id',
        ],
        phases: [2, 3, 4, 5],
        translation: 'validation.activities_investments',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    {
        field: 'outcome_base',
        phases: [2, 3, 4, 5],
        translation: 'validation.outcome_base',
        step: PROJECT_STEPS.RESULT_MATRIX,
    },
    //introduction
    {
        field: 'rational_study',
        phases: [3, 4, 5],
        translation: 'validation.rational_study',
        step: PROJECT_STEPS.INTRODUCTION,
    },
    {
        field: 'methodology',
        phases: [3, 4, 5],
        translation: 'validation.methodology',
        step: PROJECT_STEPS.INTRODUCTION,
    },
    {
        field: 'organization_study',
        phases: [3, 4, 5],
        translation: 'validation.organization_study',
        step: PROJECT_STEPS.INTRODUCTION,
    },
    //om costs and defect period
    {
        field: 'om_costs',
        innerFields: [
            'om_fund_id',
            'om_fund_source_id',
            'om_cost_category_id',
            'om_cost_classification_id',
        ],
        phases: [4, 5],
        translation: 'validation.om_costs',
        step: PROJECT_STEPS.OM_COSTS,
    },
];

export const LOCATIONS = [
    { id: 1, name: 'Abim District' },
    { id: 2, name: 'Adjumani District' },
    { id: 3, name: 'Agago District' },
    { id: 4, name: 'Alebtong District' },
    { id: 5, name: 'Amolatar District' },
    { id: 6, name: 'Amudat District' },
    { id: 7, name: 'Amuria District' },
    { id: 8, name: 'Amuru District' },
    { id: 9, name: 'Apac District' },
    { id: 10, name: 'Apac Municipal Council' },
    { id: 11, name: 'Arua District' },
    { id: 12, name: 'Arua Municipal Council' },
    { id: 13, name: 'Budaka District' },
    { id: 14, name: 'Bududa District' },
    { id: 15, name: 'Bugiri District' },
    { id: 16, name: 'Bugiri Municipal Council' },
    { id: 17, name: 'Bugweri (Iganga)' },
    { id: 18, name: 'Buhweju District' },
    { id: 19, name: 'Buikwe District' },
    { id: 20, name: 'Bukedea District' },
    { id: 21, name: 'Bukomansimbi District' },
    { id: 22, name: 'Bukwo District' },
    { id: 23, name: 'Bulambuli District' },
    { id: 24, name: 'Buliisa District' },
    { id: 25, name: 'Bundibugyo District' },
    { id: 26, name: 'Bunyangabu District' },
    { id: 27, name: 'Bushenyi District' },
    { id: 28, name: 'Bushenyi-Ishaka Municipal Council' },
    { id: 29, name: 'Busia District' },
    { id: 30, name: 'Busia Municipal Council' },
    { id: 31, name: 'Butaleja District' },
    { id: 32, name: 'Butambala District' },
    { id: 33, name: 'Butebo District' },
    { id: 34, name: 'Buvuma District' },
    { id: 35, name: 'Buyende District' },
    { id: 36, name: 'Dokolo District' },
    { id: 37, name: 'Entebbe Municipal Council' },
    { id: 38, name: 'Fort Portal Municipal' },
    { id: 39, name: 'Gomba District' },
    { id: 40, name: 'Gulu District' },
    { id: 41, name: 'Gulu Municipal Council' },
    { id: 42, name: 'Hoima District' },
    { id: 43, name: 'Hoima Municipal Council' },
    { id: 44, name: 'Ibanda District' },
    { id: 45, name: 'Ibanda Municipal Council' },
    { id: 46, name: 'Iganga District' },
    { id: 47, name: 'Iganga Municipal Council' },
    { id: 48, name: 'Isingiro District' },
    { id: 49, name: 'Jinja District' },
    { id: 50, name: 'Jinja Municipal Council' },
    { id: 51, name: 'Kaabong District' },
    { id: 52, name: 'Kabale District' },
    { id: 53, name: 'Kabale Municipal Council' },
    { id: 54, name: 'Kabarole District' },
    { id: 55, name: 'Kaberamaido District' },
    { id: 56, name: 'Kagadi District' },
    { id: 57, name: 'Kakumiro District' },
    { id: 58, name: 'Kalaki (Kaberamaido)' },
    { id: 59, name: 'Kalangala District' },
    { id: 60, name: 'Kaliro District' },
    { id: 61, name: 'Kalungu District' },
    { id: 62, name: 'Kamuli District' },
    { id: 63, name: 'Kamuli Municipal Council' },
    { id: 64, name: 'Kamwenge District' },
    { id: 65, name: 'Kanungu District' },
    { id: 66, name: 'Kapchorwa District' },
    { id: 67, name: 'Kapchorwa Municipal Council' },
    { id: 68, name: 'Kapelebyong (Amuria)' },
    { id: 69, name: 'Karenga (Kaabong)' },
    { id: 70, name: 'Kasanda (Mubende)' },
    { id: 71, name: 'Kasese District' },
    { id: 72, name: 'Kasese Municipal Council' },
    { id: 73, name: 'Katakwi District' },
    { id: 74, name: 'Kayunga District' },
    { id: 75, name: 'Kazo (Kiruhura)' },
    { id: 76, name: 'Kibale District' },
    { id: 77, name: 'Kiboga District' },
    { id: 78, name: 'Kibuku District' },
    { id: 79, name: 'Kikuube (Hoima)' },
    { id: 80, name: 'Kira Municipal Council' },
    { id: 81, name: 'Kiruhura District' },
    { id: 82, name: 'Kiryandongo District' },
    { id: 83, name: 'Kisoro District' },
    { id: 84, name: 'Kisoro Municipal Council' },
    { id: 85, name: 'Kitagwenda (Kamwenge)' },
    { id: 86, name: 'Kitgum District' },
    { id: 87, name: 'Kitgum Municipal Council' },
    { id: 88, name: 'Koboko District' },
    { id: 89, name: 'Koboko Municipal Council' },
    { id: 90, name: 'Kole District' },
    { id: 91, name: 'Kotido District' },
    { id: 92, name: 'Kotido Municipal Council' },
    { id: 93, name: 'Kumi District' },
    { id: 94, name: 'Kumi Municipal Council' },
    { id: 95, name: 'Kwania (Apac)' },
    { id: 96, name: 'Kween District' },
    { id: 97, name: 'Kyankwanzi District' },
    { id: 98, name: 'Kyegegwa District' },
    { id: 99, name: 'Kyenjonjo District' },
    { id: 100, name: 'Kyotera District' },
    { id: 101, name: 'Lamwo District' },
    { id: 102, name: 'Lira District' },
    { id: 103, name: 'Lira Municipal Council' },
    { id: 104, name: 'Lugazi Municipal Council' },
    { id: 105, name: 'Luuka District' },
    { id: 106, name: 'Luwero District' },
    { id: 107, name: 'Lwengo District' },
    { id: 108, name: 'Lyantonde District' },
    { id: 109, name: 'Madi-Okollo (Arua)' },
    { id: 110, name: 'Makindye-Ssabagabo Municipal Council' },
    { id: 111, name: 'Manafwa District' },
    { id: 112, name: 'Maracha District' },
    { id: 113, name: 'Masaka District' },
    { id: 114, name: 'Masaka Municipal Council' },
    { id: 115, name: 'Masindi District' },
    { id: 116, name: 'Masindi Municipal Council' },
    { id: 117, name: 'Mayuge District' },
    { id: 118, name: 'Mbale District' },
    { id: 119, name: 'Mbale Municipal Council' },
    { id: 120, name: 'Mbarara District' },
    { id: 121, name: 'Mbarara Municipal Council' },
    { id: 122, name: 'Mitooma District' },
    { id: 123, name: 'Mityana District' },
    { id: 124, name: 'Mityana Municipal Council' },
    { id: 125, name: 'Moroto District' },
    { id: 126, name: 'Moroto Municipal Council' },
    { id: 127, name: 'Moyo District' },
    { id: 128, name: 'Mpigi District' },
    { id: 129, name: 'Mubende District' },
    { id: 130, name: 'Mubende Municipal Council' },
    { id: 131, name: 'Mukono District' },
    { id: 132, name: 'Mukono Municipal Council' },
    { id: 133, name: 'Nabilatuk (Nakapiripirit)' },
    { id: 134, name: 'Nakapiripirit District' },
    { id: 135, name: 'Nakaseke District' },
    { id: 136, name: 'Nakasongola District' },
    { id: 137, name: 'Namayingo District' },
    { id: 138, name: 'Namisindwa District' },
    { id: 139, name: 'Namutumba District' },
    { id: 140, name: 'Nansana Municipal Council' },
    { id: 141, name: 'Napak District' },
    { id: 142, name: 'Nebbi District' },
    { id: 143, name: 'Nebbi Municipal Council' },
    { id: 144, name: 'Ngora District' },
    { id: 145, name: 'Njeru Municipal Council' },
    { id: 146, name: 'Ntoroko District' },
    { id: 147, name: 'Ntungamo District' },
    { id: 148, name: 'Ntungamo Municipal Council' },
    { id: 149, name: 'Nwoya District' },
    { id: 150, name: 'Obongi (Moyo)' },
    { id: 151, name: 'Omoro District' },
    { id: 152, name: 'Otuke District' },
    { id: 153, name: 'Oyam District' },
    { id: 154, name: 'Pader District' },
    { id: 155, name: 'Pakwach District' },
    { id: 156, name: 'Pallisa District' },
    { id: 157, name: 'Rakai District' },
    { id: 158, name: 'Rubanda District' },
    { id: 159, name: 'Rubirizi District' },
    { id: 160, name: 'Rukiga District' },
    { id: 161, name: 'Rukungiri District' },
    { id: 162, name: 'Rukungiri Municipal Council' },
    { id: 163, name: 'Rwampara (Mbarara)' },
    { id: 164, name: 'Sembabule District' },
    { id: 165, name: 'Serere District' },
    { id: 166, name: 'Sheema District' },
    { id: 167, name: 'Sheema Municipal Council' },
    { id: 168, name: 'Sironko District' },
    { id: 169, name: 'Soroti District' },
    { id: 170, name: 'Soroti Municipal Council' },
    { id: 171, name: 'Tororo District' },
    { id: 172, name: 'Tororo Municipal Council' },
    { id: 173, name: 'Wakiso District' },
    { id: 174, name: 'Yumbe District' },
    { id: 175, name: 'Zombo District' },
];
