import React from 'react';
import lodash from 'lodash';
import { Table } from 'reactstrap';
import { getInvestmentYears, costSumFormatter } from '../helpers';
import { romanize } from '../../../helpers/formatters';

export const ProposedFunding = props => {
    const { customRecord, customBasePath, translate, counter } = props;
    const years = getInvestmentYears(customRecord.activities);

    const funds = customRecord.outputs.map(output =>
        output.activities.map(activity =>
            activity.investments.map(investment => {
                let invTotal = 0;

                years.forEach(year => {
                    invTotal += investment[year]
                        ? parseFloat(investment[year])
                        : 0;
                });
                if (investment.fund_source_details) {
                    return {
                        fund_id: investment.fund_source_id,
                        fund_label: `${investment.fund_source_details.code} - ${
                            investment.fund_source_details.name
                        }`,
                        total: invTotal,
                    };
                }
                return {};
            }),
        ),
    );

    const concatedFunds = [].concat.apply([], funds);
    const groupResults = lodash.groupBy(
        lodash.flatten(concatedFunds),
        'fund_label',
    );

    return (
        <div className="Section2">
        <div className="content-area">
            <h4>
                {romanize(counter)}. {translate('printForm.funding.title')}
            </h4>
            <Table bordered responsive style={{ width: '55%' }}>
                <thead>
                    <tr className="filled-row">
                        <th>{translate('printForm.funding.fund')}</th>
                        <th>{translate('printForm.funding.ugx')}</th>
                    </tr>
                </thead>
                <tbody>
                    {lodash.keys(groupResults).map(fund => (
                        <tr>
                            <td>{fund}</td>
                            <td>
                                {costSumFormatter(
                                    lodash.sumBy(groupResults[fund], item =>
                                        parseFloat(item.total),
                                    ),
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
        </div>
    );
};
