import {
    FileField,
    FileInput,
    FormDataConsumer,
    SaveButton,
    SimpleForm,
    Toolbar,
    showNotification,
} from 'react-admin';
import { PROJECT_FORMS, PROJECT_STEPS } from '../../../constants/common';
import React, { Component, Fragment } from 'react';
import { change, isSubmitting, submit } from 'redux-form';
import {
    fileValidation,
    projectValidation,
    validateFieldsByPhase,
} from '../validation';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import {
    stepsConfig,
    stepsContentByPhase,
} from '../../../constants/projectSteps';

import AdditionalInfoForm from './AdditionalInfoForm';
import Button from '@material-ui/core/Button';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import DemandAnalysisForm from './DemandAnalysisForm';
import FinancialAnalysisForm from './FinancialAnalysisForm';
import IntroductionForm from './IntroductionForm';
import ModulesForm from './ModulesForm';
import OMCostsForm from './OMCostsForm';
// import OptionsAppraisalForm from './OptionsAppraisalForm/__index';
import OptionsAppraisalForm from './OptionsAppraisalForm';
import ProjectBackgroundForm from './ProjectBackgroundForm';
import ProjectFrameworkForm from './ProjectFrameworkForm';
import { Redirect } from 'react-router';
import ResponsibleOfficerForm from './ResponsibleOfficerForm';
import ResultMatrixForm from './ResultMatrixForm';
import Step from '@material-ui/core/Step';
import StepIcon from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import SummaryForm from './SummaryForm';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { validateOptionsBeforeSave } from '../helpers';

class ProjectForm extends Component {
    state = {
        activeStep: 0,
        matrixStep: 0,
        optionsStep: 0,
        popups: {
            goalIndicators: false,
            outcomeIndicators: false,
            outputIndicators: false,
            outputInvestments: false,
            activityInvestments: false,
        },
    };

    handleShowPopup = (type, visibility) => {
        this.setState({
            popups: {
                ...this.state.popups,
                [type]: visibility,
            },
        });
    };

    getValidationStatusError = (label) => {
        const { record, formValues } = this.props;
        const emptyFieldsForPhase = validateFieldsByPhase(record, label);
        let errorCounter = false;

        if (record && label === PROJECT_STEPS.ADDITIONAL_INFO) {
            errorCounter = fileValidation(record);
        }

        if (
            record &&
            label === PROJECT_STEPS.OPTIONS_ANALYSIS &&
            record.phase_id === 3
        ) {
            if (
                formValues &&
                formValues.options_appraisals &&
                formValues.options_appraisals.filter((item) => item.is_best)
                    .length === 0
            ) {
                errorCounter = true;
            }
        }

        if (
            record &&
            label === PROJECT_STEPS.PROJECT_APPRAISAL &&
            record.phase_id === 4
        ) {
            const optionsErrorsMsg = validateOptionsBeforeSave(
                formValues,
                this.props.showNotification,
                this.props.translate,
            );
            if (
                lodash.isString(optionsErrorsMsg) &&
                optionsErrorsMsg.length !== 0
            ) {
                errorCounter = true;
            }
        }

        return (
            (emptyFieldsForPhase && emptyFieldsForPhase.length !== 0) ||
            errorCounter
        );
    };

    getSteps(phase_id) {
        const selectedSteps = [];

        stepsConfig.forEach((step) => {
            if (step.visibility.includes(phase_id)) {
                if (
                    phase_id >= 4 &&
                    step.label === PROJECT_STEPS.OPTIONS_ANALYSIS
                ) {
                    selectedSteps.push(PROJECT_STEPS.PROJECT_APPRAISAL);
                } else {
                    selectedSteps.push(step.label);
                }
            }
        });
        return selectedSteps;
    }

    handleChangeMatrixStep = (currentMatrixStep) => {
        this.setState({ matrixStep: currentMatrixStep });
    };

    handleChangeOptionStep = (currentOptionStep) => {
        this.setState({ optionsStep: currentOptionStep });
    };

    getStepContent(step, phase_id) {
        const componentName = stepsContentByPhase[phase_id][step];
        switch (componentName) {
            case PROJECT_FORMS.SUMMARY:
                return <SummaryForm {...this.props} />;
            case PROJECT_FORMS.RESPONSIBLE_OFFICER:
                return <ResponsibleOfficerForm />;
            case PROJECT_FORMS.PROJECT_BACKGROUND:
                return <ProjectBackgroundForm />;
            case PROJECT_FORMS.PROJECT_FRAMEWORK:
                return <ProjectFrameworkForm />;
            case PROJECT_FORMS.DEMAND_ANALYSIS:
                return <DemandAnalysisForm />;
            case PROJECT_FORMS.INTRODUCTION:
                return <IntroductionForm />;
            case PROJECT_FORMS.OPTIONS_APPRAISAL:
                return (
                    <OptionsAppraisalForm
                        {...this.props}
                        {...this.state}
                        onChangeStep={this.handleChangeOptionStep}
                    />
                );
            case PROJECT_FORMS.OM_COSTS:
                return <OMCostsForm />;
            case PROJECT_FORMS.FINANCIAL_ANALYSIS:
                return <FinancialAnalysisForm />;
            case PROJECT_FORMS.RESULT_MATRIX:
                return (
                    <ResultMatrixForm
                        {...this.props}
                        {...this.state}
                        onChangeStep={this.handleChangeMatrixStep}
                        onShowPopup={this.handleShowPopup}
                    />
                );
            case PROJECT_FORMS.MODULES:
                return <ModulesForm />;
            case PROJECT_FORMS.ADDITIONAL_INFO:
                return <AdditionalInfoForm />;
            default:
                return null;
        }
    }

    getErrorMessage = () => {
        let message = 'Validation error! All required fields must be filled';
        return message;
    };

    handleSave = () => {
        this.props.submit('record-form');
        this.props.save(this.props.formValues, this.props.redirect);
    };

    handleSubmit = (values) => {
        // console.log(values);
    };

    handleNext = () => {
        const { record, formValues, formErrors, translate } = this.props;
        const { activeStep } = this.state;
        const phase_id = record.phase_id || 0;
        const steps = this.getSteps(phase_id);
        const currentStep = activeStep + 1;

        this.props.submit('record-form');

        if (!lodash.isEmpty(formValues.title) && lodash.isEmpty(formErrors)) {
            if (phase_id === 0 && currentStep === steps.length) {
                this.props.save(formValues, this.props.redirect);
            } else {
                this.props.save(formValues, false);
            }
            this.setActiveStep(currentStep);
        } else {
            this.props.showNotification(
                translate('messages.options_required'),
                'warning',
            );
        }
    };

    handleBack = () => {
        const { record } = this.props;
        const { activeStep } = this.state;
        const phase_id = record.phase_id || 0;
        const steps = this.getSteps(phase_id);

        this.props.submit('record-form');
        this.props.save(this.props.formValues, false);
        this.setActiveStep(this.state.activeStep - 1);
    };

    handleStep = (step) => () => {
        const {
            record,
            formValues,
            formErrors,
            translate,
            showNotification,
        } = this.props;
        const { activeStep } = this.state;
        const phase_id = record.phase_id || 0;
        const steps = this.getSteps(phase_id);
        const currentStep = activeStep + 1;

        this.props.submit('record-form');

        if (!lodash.isEmpty(formValues.title) && lodash.isEmpty(formErrors)) {
            if (phase_id === 0 && currentStep === steps.length) {
                this.props.save(formValues, this.props.redirect);
            } else {
                this.props.save(formValues, false);
            }
            this.setActiveStep(step);
        } else {
            this.props.showNotification(
                translate('messages.options_required'),
                'warning',
            );
        }
    };

    renderStepContent = (activeStep, steps, phase_id) => {
        const { translate } = this.props;

        if (activeStep === steps.length) {
            return (
                <Typography>
                    {translate('messages.all_steps_completed')}
                </Typography>
            );
        }

        return (
            <div>
                <Typography>
                    <SimpleForm
                        onSubmit={this.handleSubmit}
                        toolbar={null}
                        {...this.props}
                    >
                        {this.getStepContent(activeStep, phase_id)}
                    </SimpleForm>
                </Typography>
                <p>
                    <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                    >
                        {translate('buttons.back')}
                    </Button>
                    {steps.length === 1 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                        >
                            {translate('buttons.create')}
                        </Button>
                    ) : activeStep === steps.length - 1 ? null : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleNext}
                        >
                            {translate('buttons.next')}
                        </Button>
                    )}
                    {steps.length !== 1 && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.handleSave}
                        >
                            {translate('buttons.save_exit')}
                        </Button>
                    )}
                </p>
            </div>
        );
    };

    setActiveStep = (step) => {
        this.setState({ activeStep: step });
    };

    render() {
        const { record, translate } = this.props;
        const { activeStep } = this.state;
        const phase_id = record.phase_id || 0;
        const steps = this.getSteps(phase_id);

        return (
            <div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const props = {};
                        const labelProps = {};

                        return (
                            <Step
                                key={label}
                                {...props}
                                onClick={this.handleStep(index)}
                            >
                                <StepLabel
                                    {...labelProps}
                                    error={this.getValidationStatusError(label)}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>{this.renderStepContent(activeStep, steps, phase_id)}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    formValues: getFormValues('record-form')(state),
    formErrors: getFormSyncErrors('record-form')(state),
});
const mapDispatchToProps = {
    change,
    submit,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
