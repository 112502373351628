import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    BooleanField,
    FunctionField,
    DateField
} from 'react-admin';
import { Card, CardHeader } from 'reactstrap';
import HTML2React from 'html2react'
import CustomInput from '../../components/CustomInput';
import dataProvider from '../../providers/dataProvider';

export const NotificationsCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <CustomInput tooltipText="name">
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Create>
);

export const NotificationsEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id" />
            <CustomInput tooltipText="name">
                <TextInput source="name" validate={required()} />
            </CustomInput>
        </SimpleForm>
    </Edit>
);

const NotificationsShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <DeleteButton basePath={basePath} record={data} resource={resource} />
    </CardActions>
);

const NotificationsBulkActions = ({ basePath, data, resource }) => (
    <CardActions>
        <DeleteButton basePath={basePath} record={data} resource={resource} />
    </CardActions>
);

export const NotificationsShow = props => {
    dataProvider('GET_MANY', 'notifications', { ids: [] });
    
    return (
        <Card>
            <Show actions={<NotificationsShowActions />} {...props}>
                <SimpleShowLayout>
                    <TextField source="title_html"/>
                    <DateField source="created_at" showTime />
                    <TextField source="sender" />
                    <FunctionField
                        label="Message"
                        render={record => HTML2React(record.body_html) }
                    />
                </SimpleShowLayout>
            </Show>
        </Card>
    );
};

const rowStyle = (record, index) => ({
    backgroundColor: record.is_unread ? '#efe' : 'white',
});

export const NotificationsList = props => {
    return (
        <List {...props} bulkActions={false} actions={false}>
            <Datagrid rowStyle={rowStyle} rowClick="expand" expand={<NotificationsShow {...props}/>}>
                <DateField source="created_at" showTime />
                <TextField source="sender" />
                <TextField source="title_html"/>
            </Datagrid>
        </List>
    );
};

export default NotificationsList;
